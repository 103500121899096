<template>
  <transition name="help-notification" mode="in-out">
    <div
      @click="$emit('close')"
      :class="['help-notification', { rtl: isRTL() }]"
    >
      <div class="help-notification__content">
        <header class="help-notification__header">
          {{ $languages[getAppLanguage].help.helpAndFeedback }}
        </header>
        <a
          :href="`https://${isArabicLink()}fluently.so/how-does-it-work`"
          target="_blank"
          class="help-notification__item"
        >
          {{ $languages[getAppLanguage].help.howDoesItWork }}
          <i class="icon-arrow-up"></i>
        </a>
        <a
          :href="`https://${isArabicLink()}fluently.so/share-feedback`"
          target="_blank"
          class="help-notification__item"
        >
          {{ $languages[getAppLanguage].help.shareYourFeedback }}
          <i class="icon-arrow-up"></i>
        </a>
        <a
          :href="`https://${isArabicLink()}fluently.so/newsletter`"
          target="_blank"
          class="help-notification__item"
        >
          {{ $languages[getAppLanguage].help.getProductUpdates }}
          <i class="icon-arrow-up"></i>
        </a>
        <a
          :href="`https://${isArabicLink()}fluently.so/send-a-message`"
          target="_blank"
          class="help-notification__item"
        >
          {{ $languages[getAppLanguage].help.sendUsAMessage }}
          <i class="icon-arrow-up"></i>
        </a>
        <a
          :href="`https://${isArabicLink()}fluently.so/request-a-feature`"
          target="_blank"
          class="help-notification__item"
        >
          {{ $languages[getAppLanguage].help.requestAFeature }}
          <i class="icon-arrow-up"></i>
        </a>
        <a
          :href="`https://${isArabicLink()}fluently.so/report-a-bug`"
          target="_blank"
          class="help-notification__item"
        >
          {{ $languages[getAppLanguage].help.reportABug }}
          <i class="icon-arrow-up"></i>
        </a>
        <a
          :href="`https://${isArabicLink()}fluently.so/languages`"
          target="_blank"
          class="help-notification__item"
        >
          {{ $languages[getAppLanguage].help.supportedLanguages }}
          <i class="icon-arrow-up"></i>
        </a>

        <div class="help-notification__item help-notification__item--info">
          {{ $companyName }} V {{ getAppVersion.version }}
          {{ isProductionMode ? '' : 'dev' }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Helpers
import { isProductionMode } from '@/helpers/environment'

export default {
  name: 'HelpNotification',

  computed: {
    ...mapGetters(['getAppVersion', 'getAppLanguage'])
  },

  data: () => ({
    isProductionMode
  }),

  methods: {
    isRTL() {
      return this.getAppLanguage === 'ar'
    },

    isArabicLink() {
      return this.isRTL() ? 'ar.' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.help-notification {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  transition: all var(--transition-default);
  cursor: default;

  &.rtl {
    .help-notification__item {
      .icon-arrow-up {
        margin-left: initial;
        margin-right: var(--size-unit);
      }
    }
  }
}

.help-notification__content {
  position: fixed;
  bottom: calc(#{$editor-footer-height} + var(--size-unit));
  right: var(--size-unit-3);
  padding: var(--size-unit) 0;
  border-radius: var(--size-unit-half);
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
  transition: transform var(--transition-default),
    opacity var(--transition-default);
  z-index: 9999;
}

.help-notification__header {
  display: flex;
  align-items: center;
  padding: var(--size-unit) var(--size-unit-2);
  font-size: var(--font-size-s);
  font-weight: 100;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.08em;
  color: var(--color-brand-lightest);
}

.help-notification__item {
  display: flex;
  align-items: center;
  padding: var(--size-unit) var(--size-unit-2);
  font-size: var(--font-size-s);
  line-height: 1;
  text-decoration: none;
  color: var(--color-brand-lightest);
  cursor: pointer;

  &:hover {
    background-color: var(--color-main);
    color: var(--color-light);
  }

  span {
    margin-right: calc(var(--size-unit) + var(--size-unit-half));
  }

  &--info {
    cursor: initial;
    font-size: var(--font-size-s);
    opacity: var(--opacity-disabled);
    pointer-events: none;
  }

  .icon-arrow-up {
    transform: rotate(35deg);
    margin-left: var(--size-unit);
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="notification" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the notification transition by editing
 * these styles.
 */
.help-notification-enter .help-notification__content {
  transform: scale(1.1);
  opacity: 0;
}

.help-notification-leave-active .help-notification__content {
  transform: scale(1.1);
  opacity: 0;
}
</style>
