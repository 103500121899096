// Store data
import { mapActions, mapGetters } from 'vuex'

// Data files
import { translationLanguages } from '@/data/languages'
import {
  supportedDownloadFileTypes,
  supportedFileTypeProviders
} from '@/data/supportedFileTypes'

// Components
import NotificationModal from '@/components/NotificationModal'
import Dropdown from '@/components/Dropdown'
import Button from '@/components/Button'

// Services
// @ TODO - Add back when ready
// import convertScribeDocumentTo from '@/services/documentConverter'

const documentHeader = {
  components: {
    NotificationModal,
    Dropdown,
    Button
  },

  data: () => ({
    detectedLanguage: null,

    isSourceLanguageInManualMode: false,

    translationSourceLanguage: {
      code: '',
      prefix: '',
      language: ''
    },

    translationTargetLanguage: {
      code: '',
      prefix: '',
      language: ''
    },

    translationLanguages,
    supportedDownloadFileTypes,
    supportedFileTypeProviders,

    showPublishDropdown: false,
    showCopiedPublicUrlNotice: false,

    showSupportedLanguagesNotification: false
  }),

  computed: {
    ...mapGetters([
      'getMobileMenu',
      'getDetectedLanguage',
      'getCurrentDocument',
      'getOpenDocuments',
      'getIsUserEditingTranslation',
      'getAppLanguage'
    ])
  },

  watch: {
    getOpenDocuments() {
      return this.getOpenDocuments
    },

    getCurrentDocument() {
      if (this.getCurrentDocument.translationSourceLanguage) {
        this.translationSourceLanguage = this.getCurrentDocument.translationSourceLanguage
      }

      if (this.getCurrentDocument.translationLanguage) {
        this.translationTargetLanguage = this.getCurrentDocument.translationLanguage
      }

      // Used for the Source Trnaslation label on dropdown
      if (!this.getCurrentDocument.text) {
        this.$eventBus.$emit(
          'document-translation-source-language-manual-mode-updated',
          false
        ) // Used for the backend
        this.emitTranslationSourceLanguageUpdated({
          code: '',
          prefix: '',
          language: ''
        })
      }

      if (
        !this.getCurrentDocument.translationSourceLanguageManualMode &&
        this.parseLanguageForDetectedTranslationLanguage()
      ) {
        this.emitTranslationSourceLanguageUpdated(
          this.parseLanguageForDetectedTranslationLanguage()
        )
      }

      return this.getCurrentDocument
    },

    getDetectedLanguage() {
      return this.getDetectedLanguage
    }
  },

  methods: {
    ...mapActions([
      'setMobileMenu',
      'setCurrentDocument',
      'translateTitleAction',
      'translateTextAction'
    ]),

    /**
     *
     */
    togglePublishingDocument(publish) {
      this.$eventBus.$emit('document-publishing-clicked', publish)

      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(`${publish ? 'published' : 'unpublished'} a document`)
    },

    /**
     *
     */
    togglePublishDropdown() {
      this.showPublishDropdown = !this.showPublishDropdown
    },

    /**
     *
     */
    hidePublishDropdown() {
      this.showPublishDropdown = false
    },

    /**
     *
     */
    copyPublicURL(text) {
      const textarea = document.createElement('textarea')
      const timeToHideMessage = 1200

      this.showCopiedPublicUrlNotice = !this.showCopiedPublicUrlNotice

      setTimeout(() => {
        this.showCopiedPublicUrlNotice = !this.showCopiedPublicUrlNotice
      }, timeToHideMessage)

      textarea.value = text
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`copied public URL`)
    },

    /**
     *
     */
    getPublicUrl(publicUid) {
      return `${window.location.origin}/live/${publicUid}`
    },

    /**
     *
     */
    labelForSourceTranslationDropdown() {
      if (
        this.getCurrentDocument.translationSourceLanguageManualMode &&
        this.translationSourceLanguage &&
        this.getCurrentDocument.text
      )
        return this.$languages[this.getAppLanguage].document.header
          .translateFrom
      return this.getLanguageTitleFromDetectedLanguage() &&
        this.getCurrentDocument.text &&
        this.getCurrentDocument.translationSourceLanguageManualMode
        ? this.$languages[this.getAppLanguage].document.header.translateFrom
        : this.$languages[this.getAppLanguage].document.header.languageDetected
    },

    /**
     * This is used to parse the languages before passing them through the dropdown, to make the dropdown more dumb
     */
    extractedLanguagesFromTranslationLanguages() {
      const languages = []

      for (const language of translationLanguages) {
        languages.push(
          `${language.prefix} ${language.language} ${
            language.variation ? language.variation : ''
          }`
        )
      }

      return languages
    },

    /**
     * This is used to compose back the languages after passing them through the dropdown search, to later make the update to the app
     */
    composeLanguageFromLanguageString(languageString) {
      for (const language of translationLanguages) {
        if (
          languageString ===
          `${language.prefix} ${language.language} ${language.variation}`
        )
          return language
      }
    },

    /**
     *
     */
    parseLanguageForDetectedTranslationLanguage() {
      for (const language of translationLanguages) {
        if (language.language.toLowerCase() === this.getDetectedLanguage) {
          return language
        }
      }
    },

    /**
     *
     */
    getLanguageFlagFromDetectedLanguage() {
      for (const language of translationLanguages) {
        if (language.language.toLowerCase() === this.getDetectedLanguage) {
          return language.prefix
        }
      }
    },

    /**
     *
     */
    getLanguageTitleFromDetectedLanguage() {
      return this.parseLanguageForDetectedTranslationLanguage()
        ? this.parseLanguageForDetectedTranslationLanguage().language
        : this.$languages[this.getAppLanguage].document.header
            .detectingYourLanguage
    },

    /**
     *
     */
    processedtranslationLanguages() {
      const languages = []
      for (const language of translationLanguages) {
        if (language.language.toLowerCase() === this.getDetectedLanguage) {
          languages.push(language)
        }
      }

      return languages
    },

    /**
     *
     */
    parseAndEmitTranslationSourceLanguageUpdatedOnSearch(option) {
      this.$eventBus.$emit(
        'document-translation-source-language-updated',
        this.composeLanguageFromLanguageString(option)
      ) // Used for the backend
      this.$eventBus.$emit(
        'document-translation-source-language-manual-mode-updated',
        true
      ) // Used for the backend
    },

    /**
     *
     */
    emitTranslationSourceLanguageUpdated(language) {
      this.$eventBus.$emit(
        'document-translation-source-language-updated',
        language
      ) // Used for the backend
    },

    /**
     *s
     */
    emitTranslationTragetLanguageUpdated(option) {
      this.$eventBus.$emit(
        'document-translation-target-language-updated',
        this.composeLanguageFromLanguageString(option)
      ) // Used for the backend
    },

    /**
     *
     */
    fullScreenEditorClicked() {
      this.$emit('full-screen-editor-clicked')

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`entered full screen mode`)
    },

    /**
     *
     */
    expandEditorClicked() {
      if (this.getCurrentDocument.expanded) {
        this.$eventBus.$emit('document-expand-action-clicked', false)
      } else {
        this.$eventBus.$emit('document-expand-action-clicked', true)
        this.$eventBus.$emit('document-translation-with-loader-requested')

        if (this.$amplitudeOn)
          this.$amplitude.getInstance().logEvent(`entered full-document mode`)
      }

      this.$emit('expand-editor-clicked')
    },

    /**
     *
     */
    closeExpandedView() {
      this.$eventBus.$emit('document-expand-action-clicked', true)
      // this.primaryHeaderExpanded = false
      this.$emit('expand-editor-clicked')
    },

    /**
     *
     */
    swapLanguage() {
      const document = this.getCurrentDocument

      const currentText = document.text
      const currentTranslation = document.translation
      const currentSourceTranslationLanguage =
        document.translationSourceLanguage
      const currentTranslationLanguage = document.translationLanguage

      // Checking if quill is not blank
      if (!currentText.includes('ql-blank')) {
        document.text = currentTranslation
        document.translation = currentText
      }

      document.translationSourceLanguage = currentTranslationLanguage
      document.translationLanguage = currentSourceTranslationLanguage

      document.translationSourceLanguageManualMode = true

      this.setCurrentDocument(document)

      this.$eventBus.$emit('document-language-swapped')

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`swapped languages`)
    },

    /**
     *
     */
    showTranslationsSrvice() {
      this.closeExpandedView()

      const document = this.getCurrentDocument

      document.activeService = 'translator'
      document.expanded = false

      this.translateTitleAction({
        title: document.title,
        sourceLanguageCode:
          document.translationSourceLanguage.code ||
          this.$detectBrowserLanguage().code,
        targetLanguageCode:
          document.translationLanguage.code ||
          this.$detectBrowserLanguage().code
      })

      this.translateTextAction({
        text: document.text,
        sourceLanguageCode:
          document.translationSourceLanguage.code ||
          this.$detectBrowserLanguage().code,
        targetLanguageCode:
          document.translationLanguage.code ||
          this.$detectBrowserLanguage().code,
        event: 'document-translation-updated'
      })

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`open the translator`)

      return this.setCurrentDocument(document)
    },

    /**
     *
     */
    showMicroTranslationsSrvice() {
      this.closeExpandedView()

      this.$eventBus.$emit('document-expand-action-clicked', false)
      this.$eventBus.$emit('micro-translations-clicked')

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`open the micro-translator`)
    },

    /**
     *
     */
    toggleGrammar() {
      this.$setGrammar(!this.$getGrammar())

      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(`switched grammar ${this.$getGrammar() ? 'on' : 'off'}`)

      setTimeout(() => {
        return location.reload(true)
      })
    },

    /**
     *
     */
    getTranslationSourceLanguageCode(language) {
      this.translationSourceLanguage = {
        // Used for performing the translation
        prefix: language.prefix,
        language: language.language,
        variation: language.variation
      }

      this.$eventBus.$emit(
        'document-translation-source-language-manual-mode-updated',
        true
      ) // Used for the backend
      this.$eventBus.$emit(
        'document-translation-source-language-updated',
        language
      ) // Used for the backend
    },

    /**
     *
     */
    getTranslationTargetLanguageCode(language) {
      this.translationTargetLanguage = {
        // Used for performing the translation
        prefix: language.prefix,
        language: language.language,
        variation: language.variation
      }

      this.$eventBus.$emit(
        'document-translation-target-language-updated',
        language
      ) // Used for the backend
    }
  }
}

export default documentHeader
