<template>
  <transition name="language-selector" mode="in-out">
    <div @click="$emit('close')" class="language-selector">
      <div class="language-selector__content">
        <span
          v-for="(language, languageIndex) in $languages[getAppLanguage]
            .languages"
          :key="languageIndex"
          :class="[
            'language-selector__item',
            { active: getAppLanguage === language.languageCode }
          ]"
          @click="setAppLanguage(language.languageCode)"
        >
          <span class="language-selector__item-upper">
            ({{ language.languageCode }})
          </span>
          {{ language.language }}
          <i
            v-if="getAppLanguage === language.languageCode"
            class="icon-check"
          ></i>
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
// Store data
import { mapGetters, mapActions } from 'vuex'

// Helpers
import { isProductionMode } from '@/helpers/environment'

export default {
  name: 'LanguageSelector',

  computed: {
    ...mapGetters(['getAppLanguage'])
  },

  data: () => ({
    isProductionMode
  }),

  methods: {
    ...mapActions(['setAppLanguage'])
  }
}
</script>

<style lang="scss" scoped>
.language-selector {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  transition: all var(--transition-default);
  cursor: default;
}

.language-selector__content {
  position: fixed;
  bottom: calc(#{$editor-footer-height} + var(--size-unit));
  right: var(--size-unit-8);
  padding: var(--size-unit) 0;
  border-radius: var(--size-unit-half);
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
  transition: transform var(--transition-default),
    opacity var(--transition-default);
  z-index: 9999;
}

.language-selector__header {
  display: flex;
  align-items: center;
  padding: var(--size-unit) var(--size-unit-2);
  font-size: var(--font-size-s);
  font-weight: 100;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.08em;
  color: var(--color-brand-lightest);
}

.language-selector__item {
  display: flex;
  align-items: center;
  padding: var(--size-unit) var(--size-unit-2);
  font-size: var(--font-size-s);
  line-height: 1;
  text-decoration: none;
  text-transform: capitalize;
  color: var(--color-brand-lightest);
  cursor: pointer;

  &:hover,
  &.active {
    background-color: var(--color-main);
    color: var(--color-light);
  }

  &-upper {
    text-transform: uppercase;
    margin-right: var(--size-unit-half);
  }

  .icon-arrow-up {
    margin-left: var(--size-unit);
  }

  i {
    margin-left: var(--size-unit);
    font-size: var(--font-size-xs);
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="notification" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the notification transition by editing
 * these styles.
 */
.language-selector-enter .language-selector__content {
  transform: scale(1.1);
  opacity: 0;
}

.language-selector-leave-active .language-selector__content {
  transform: scale(1.1);
  opacity: 0;
}
</style>
