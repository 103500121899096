<template>
  <header class="document-header">
    <NotificationModal
      v-show="showSupportedLanguagesNotification"
      @close="showSupportedLanguagesNotification = false"
      :heading="
        `We support ${translationLanguages.length} languages in our translation features!`
      "
      :body="
        `<strong>Afrikaans, Albanian, Amharic, Arabic, Azerbaijani, Bengali, Bosnian, Bulgarian, Chinese (Simplified), Chinese (Traditional), Croatian, Czech, Danish, Dari, Dutch, English, Estonian, Finnish, French, French (Canadian), Georgian, German, Greek, Hausa, Hebrew, Hindi, Hungarian, Indonesian, Italian, Japanese, Korean, Latvian, Malay, Norwegian, Persian, Pashto, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Somali, Spanish, Spanish (Mexican), Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese.</strong> <hr/> If your language is not listed, <a href='mailto:support@fluently.so?subject=Please add my language'>send us an email</a> to express your interest.</small>`
      "
    />

    <div
      v-if="getOpenDocuments.length"
      :class="[
        'document-header__primary',
        {
          'document-header__primary--expanded': this.getCurrentDocument.expanded
        },
        {
          'document-header__primary--micro-translator':
            !this.getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'micro-translator'
        }
      ]"
    >
      <Dropdown
        @dropdown-searched-option-clicked="
          parseAndEmitTranslationSourceLanguageUpdatedOnSearch
        "
        :label="labelForSourceTranslationDropdown()"
        :titlePrefix="
          getCurrentDocument.translationSourceLanguageManualMode
            ? getCurrentDocument.translationSourceLanguage.prefix
            : getLanguageFlagFromDetectedLanguage()
        "
        :title="
          getCurrentDocument.translationSourceLanguageManualMode
            ? `${getCurrentDocument.translationSourceLanguage.language} ${
                getCurrentDocument.translationSourceLanguage.variation
                  ? getCurrentDocument.translationSourceLanguage.variation
                  : ''
              }`
            : getLanguageTitleFromDetectedLanguage()
        "
        dropdownWidth="240px"
        outlined
        :options="extractedLanguagesFromTranslationLanguages()"
        :showCaret="this.getCurrentDocument.text ? true : false"
      >
        <div
          slot="dropdown-options"
          class="dropdown__option"
          v-for="language in translationLanguages"
          :key="language.name"
          @click="getTranslationSourceLanguageCode(language)"
        >
          {{ language.prefix }} {{ language.language }}
          {{ language.variation ? `(${language.variation})` : '' }}
          <i
            v-if="translationSourceLanguage.code === language.code"
            class="icon-check"
          ></i>
        </div>
      </Dropdown>

      <section class="document-header__section">
        <Button
          v-if="!getCurrentDocument.publicUid"
          @button-clicked="togglePublishingDocument(true)"
          buttonMargin="0 0 0 8px"
          type="secondary"
        >
          {{ $languages[getAppLanguage].document.header.publish }}
        </Button>

        <div
          v-else
          v-click-outside:pointerdown="hidePublishDropdown"
          class="document-header__publish-dropdown-wrapper"
        >
          <Button
            @button-clicked="togglePublishDropdown"
            type="success"
            buttonMargin="0 0 0 8px"
            icon="caret-down"
          >
            {{ $languages[getAppLanguage].document.header.published }}
          </Button>

          <div
            v-if="showPublishDropdown"
            class="document-header__publish-dropdown"
          >
            <span
              @click="copyPublicURL(getPublicUrl(getCurrentDocument.publicUid))"
              class="document-header__publish-dropdown-item"
            >
              {{
                showCopiedPublicUrlNotice
                  ? $languages[getAppLanguage].document.header.copied
                  : $languages[getAppLanguage].document.header.copyLiveLink
              }}
            </span>
            <a
              :href="`/live/${getCurrentDocument.publicUid}`"
              target="_blank"
              rel="noopener noreferrer"
              class="document-header__publish-dropdown-item"
            >
              {{ $languages[getAppLanguage].document.header.goToLiveLink }}
            </a>
            <span
              @click="togglePublishingDocument(false)"
              class="document-header__publish-dropdown-item document-header__publish-dropdown-item--warning"
            >
              {{ $languages[getAppLanguage].document.header.unpublish }}
            </span>
          </div>
        </div>

        <section
          class="document-header__section document-header__section--vertical"
        >
          <Button
            v-if="getCurrentDocument.expanded && $user"
            type="secondary"
            @button-clicked="fullScreenEditorClicked"
            buttonWidth="40px"
            buttonHeight="40px"
            buttonMargin="168px 0 0 16px"
            buttonPadding="0"
            class="document-header__full-screen-button"
          >
            <i class="icon-expand"></i>
          </Button>

          <Button
            v-if="getCurrentDocument.expanded"
            @button-clicked="showTranslationsSrvice()"
            type="app"
            buttonWidth="40px"
            buttonHeight="40px"
            buttonMargin="16px 0 0 0"
            buttonPadding="0"
            :tooltip="{
              active: true,
              width: '140px',
              settings: {
                text:
                  $languages[getAppLanguage].document.header.documentTranslator,
                position: 'left',
                offset: [0, 8],
                adapt: true
              }
            }"
          >
            <i class="icon-translate-main"></i>
          </Button>

          <Button
            v-if="getCurrentDocument.expanded"
            @button-clicked="showMicroTranslationsSrvice"
            type="app"
            buttonWidth="40px"
            buttonHeight="40px"
            buttonMargin="16px 0 0 0"
            buttonPadding="0"
            :tooltip="{
              active: true,
              width: '140px',
              settings: {
                text:
                  $languages[getAppLanguage].document.header
                    .paragraphTranslator,
                position: 'left',
                offset: [0, 8],
                adapt: true
              }
            }"
          >
            <i class="icon-translate-add"></i>
          </Button>

          <Button
            v-if="getCurrentDocument.expanded"
            :type="$getGrammar() ? 'app-active' : 'app'"
            @button-clicked="toggleGrammar()"
            buttonWidth="40px"
            buttonHeight="40px"
            buttonMargin="16px 0 0 0"
            buttonPadding="0"
            :tooltip="{
              active: true,
              width: '160px',
              settings: {
                text:
                  $languages[getAppLanguage].document.header.grammarAndSynonyms,
                position: 'left',
                offset: [0, 8],
                adapt: true
              }
            }"
          >
            <i class="icon-grammar"></i>
          </Button>

          <div v-else>
            <Button
              v-if="getCurrentDocument.activeService === 'translator'"
              type="secondary"
              @button-clicked="swapLanguage"
              buttonWidth="40px"
              buttonHeight="40px"
              buttonMargin="0 0 0 16px"
              buttonPadding="0"
              :disabled="
                getCurrentDocument.translationSourceLanguage.code === '' ||
                  getCurrentDocument.translationSourceLanguage.code ===
                    getCurrentDocument.translationLanguage.code
              "
            >
              <i class="icon-swap"></i>
            </Button>
          </div>
        </section>
      </section>
    </div>

    <div
      v-if="getOpenDocuments.length"
      :class="[
        'document-header__secondary',
        {
          'document-header__secondary--auto-width':
            getCurrentDocument.activeService === 'micro-translator'
        },

        { 'document-header__secondary--hide': getCurrentDocument.expanded },
        { 'document-header__secondary--warning': getIsUserEditingTranslation }
      ]"
    >
      <section
        class="document-header__section document-header__section--full-width"
      >
        <Dropdown
          v-if="getCurrentDocument.activeService === 'translator'"
          @dropdown-searched-option-clicked="
            emitTranslationTragetLanguageUpdated
          "
          :label="$languages[getAppLanguage].document.header.translateInto"
          :titlePrefix="
            getCurrentDocument.translationLanguage
              ? getCurrentDocument.translationLanguage.prefix
              : translationTargetLanguage.prefix
          "
          :title="
            `${
              getCurrentDocument.translationLanguage
                ? getCurrentDocument.translationLanguage.language
                : translationTargetLanguage.language
            } ${
              getCurrentDocument.translationLanguage
                ? getCurrentDocument.translationLanguage.variation
                : translationTargetLanguage.variation
                ? translationTargetLanguage.variation
                : ''
            }`
          "
          dropdownMargin="0 16px 0 0"
          dropdownWidth="240px"
          :options="extractedLanguagesFromTranslationLanguages()"
          :outlined="getCurrentDocument.activeService === 'translator'"
        >
          <div
            slot="dropdown-options"
            class="dropdown__option"
            v-for="language in translationLanguages"
            :key="language.name"
            @click="getTranslationTargetLanguageCode(language)"
          >
            {{ language.prefix }} {{ language.language }}
            {{ language.variation ? `(${language.variation})` : '' }}
            <i
              v-if="translationTargetLanguage.code === language.code"
              class="icon-check"
            ></i>
          </div>
        </Dropdown>

        <!-- @ TODO - Add back when ready -->
        <!-- <Dropdown
          @dropdown-clicked="showDictionariesSrvice"
          dropdownWidth="auto"
          title="Dictionaries"
          white
          :showCaret="false"
          :outlined="activeService === 'dictionaries'"
        />-->

        <!-- @ TODO - Add back when ready -->
        <!-- <Dropdown
          @dropdown-clicked="showCompareSrvice"
          dropdownWidth="auto"
          title="Compare"
          white
          :showCaret="false"
        />-->

        <!-- @ TODO - Add back when ready -->
        <!-- <Dropdown
          @dropdown-clicked="showNotesSrvice"
          dropdownWidth="auto"
          title="Notes"
          white
          :showCaret="false"
        />-->
      </section>

      <section
        class="document-header__section document-header__section--vertical"
      >
        <Button
          @button-clicked="closeExpandedView"
          :type="getCurrentDocument.expanded ? 'app-active' : 'app-secondary'"
          class="document-header__close-button"
          buttonWidth="40px"
          buttonHeight="40px"
          buttonMargin="168px 0 0 0"
          buttonPadding="0"
          :tooltip="{
            active: true,
            width: '100px',
            settings: {
              text: $languages[getAppLanguage].document.header.fullDocument,
              position: 'left',
              offset: [0, 8],
              adapt: true
            }
          }"
        >
          <i class="icon-document"></i>
        </Button>

        <Button
          v-if="!getCurrentDocument.expanded"
          :type="
            getCurrentDocument.activeService === 'translator'
              ? 'app-active'
              : 'app-secondary'
          "
          @button-clicked="
            getCurrentDocument.activeService === 'translator'
              ? closeExpandedView()
              : showTranslationsSrvice()
          "
          buttonWidth="40px"
          buttonHeight="40px"
          buttonMargin="16px 0 0 0"
          buttonPadding="0"
          :tooltip="{
            active: true,
            width: '140px',
            settings: {
              text:
                $languages[getAppLanguage].document.header.documentTranslator,
              position: 'left',
              offset: [0, 8],
              adapt: true
            }
          }"
        >
          <i class="icon-translate-main"></i>
        </Button>

        <Button
          v-if="!getCurrentDocument.expanded"
          :type="
            getCurrentDocument.activeService === 'micro-translator'
              ? 'app-active'
              : 'app-secondary'
          "
          @button-clicked="
            getCurrentDocument.activeService === 'micro-translator'
              ? closeExpandedView()
              : showMicroTranslationsSrvice()
          "
          buttonWidth="40px"
          buttonHeight="40px"
          buttonMargin="16px 0 0 0"
          buttonPadding="0"
          :tooltip="{
            active: true,
            width: '140px',
            settings: {
              text:
                $languages[getAppLanguage].document.header.paragraphTranslator,
              position: 'left',
              offset: [0, 8],
              adapt: true
            }
          }"
        >
          <i class="icon-translate-add"></i>
        </Button>

        <Button
          v-if="!getCurrentDocument.expanded"
          :type="$getGrammar() ? 'app-active' : 'app-secondary'"
          @button-clicked="toggleGrammar()"
          buttonWidth="40px"
          buttonHeight="40px"
          buttonMargin="16px 0 0 0"
          buttonPadding="0"
          :tooltip="{
            active: true,
            width: '160px',
            settings: {
              text:
                $languages[getAppLanguage].document.header.grammarAndSynonyms,
              position: 'left',
              offset: [0, 8],
              adapt: true
            }
          }"
        >
          <i class="icon-grammar"></i>
        </Button>
      </section>
    </div>
  </header>
</template>

<script>
// Store data
import { mapGetters } from 'vuex'
import documentHeader from '@/mixins/documentHeader'

export default {
  name: 'DocumentHeader',

  mixins: [documentHeader],

  computed: {
    ...mapGetters(['getAppLanguage'])
  }
}
</script>

<style lang="scss" scoped>
.document-header {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include screen-small {
    flex-direction: row;
    height: $document-header-height;
  }

  @include screen-medium {
    display: flex;
  }

  &--left-aligned {
    justify-content: flex-start;
  }

  &-hide-small {
    display: none;

    @include screen-medium {
      display: flex;
    }
  }
}

// .document-header__primary {
//   overflow: scroll;

//   @include screen-small {
//     overflow: initial;
//   }
// }

.document-header__primary,
.document-header__secondary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: inherit;
  padding: var(--size-unit-2) 0 var(--size-unit) var(--size-unit-2);
  background-color: var(--color-theme-lightest);

  @include screen-small {
    padding: var(--size-unit) var(--size-unit-2) var(--size-unit)
      var(--size-unit-2);
  }

  @include screen-medium {
    width: 50vw;

    &--micro-translator {
      width: 100vw;
    }

    &--expanded {
      width: 100vw;
    }
  }

  @include screen-large {
    padding: 0 var(--size-unit-3);
    // The following two lines are used for avoiding a jump when warning is active
    border: 2px solid transparent;
    border-bottom: none;

    &--micro-translator {
      width: 50vw;
    }
  }
}

.document-header__secondary {
  padding: 0 var(--size-unit-2) var(--size-unit) var(--size-unit-2);

  @include screen-small {
    padding: var(--size-unit) var(--size-unit-2) var(--size-unit)
      var(--size-unit-half);
  }

  @include screen-large {
    padding: var(--size-unit-2) var(--size-unit-3);
    background-color: var(--color-theme-lighter);
  }

  &--auto-width {
    width: initial;
    padding-left: 0;

    @include screen-large {
      width: 50vw;
    }
  }

  &--hide {
    display: none;
  }

  @include screen-large {
    &--warning {
      border: 2px solid var(--color-warning);
      border-bottom: none;
    }
  }
}

.document-header__section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--full-width {
    width: 100%;
  }

  &--vertical {
    flex-direction: row-reverse;

    @include screen-medium {
      flex-direction: column;
      align-items: flex-end;
      z-index: 999;
    }
  }
}

.document-header__full-screen-button {
  display: none;
  justify-content: center;
  align-items: center;
  margin-left: var(--size-unit-2);
  cursor: pointer;

  @include screen-medium {
    margin-top: 112px;
    display: flex;
  }
}

.document-header__close-button {
  background-color: var(--color-theme-lighter);
  color: var(--color-dark);

  @include screen-large {
    background-color: var(--color-theme-lightest);
  }
}

.document-header__publish-dropdown-wrapper {
  position: relative;
}

.document-header__publish-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(#{$header-height} + var(--size-unit));
  right: 0;
  width: calc(var(--size-unit-2) * 11);
  border-radius: var(--size-unit-half);
  padding: var(--size-unit) 0;
  font-size: var(--font-size-xs);
  background-color: var(--color-brand-darkest);
  z-index: 9999;
}

.document-header__publish-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: calc(var(--size-unit-2) * 2);
  width: 100%;
  padding: 0 var(--size-unit-2);
  font-size: var(--font-size-s);
  color: var(--color-brand-lightest);
  cursor: pointer;

  &:hover {
    background-color: var(--color-main);
    color: var(--color-light);
  }

  &--warning {
    &:hover {
      background-color: var(--color-warning);
      color: var(--color-brand-darkest);
    }
  }
}
</style>
