<template>
  <img
    v-if="getDarkMode"
    class="spinner"
    src="../assets/icons/spinner-dark-mode.svg"
    alt="Spinner"
    :style="
      `
      width: ${spinnerSize};
      height: ${spinnerSize};
    `
    "
  />
  <img
    v-else
    class="spinner"
    src="../assets/icons/spinner.svg"
    alt="Spinner"
    :style="
      `
      width: ${spinnerSize};
      height: ${spinnerSize};
    `
    "
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Spinner',

  props: {
    spinnerSize: {
      type: String,
      required: false
    }
  },

  computed: {
    ...mapGetters(['getDarkMode'])
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  transform-origin: center;
  animation: spin 0.6s infinite linear;
  opacity: var(--opacity-disabled);
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
