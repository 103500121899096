<template>
  <div
    :class="[
      'editor',
      {
        'editor--expanded': isExpanded,
        'editor--full-screen': isFullScreen,
        'editor--is-grey': isGrey
      }
    ]"
  >
    <section class="editor__wrapper">
      <section class="editor__header-container">
        <header
          class="editor__header ql-formats"
          :ref="`editorToolbar-${getCurrentDocument.uid}`"
          :id="`editorToolbar-${getCurrentDocument.uid}`"
        >
          <Button
            @button-clicked="quill.history.undo()"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 8px"
            buttonWidth="32px"
            buttonHeight="32px"
            class="editor__undo icon-undo"
            :disabled="!undoActive"
          />

          <Button
            @button-clicked="quill.history.redo()"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 8px"
            buttonWidth="32px"
            buttonHeight="32px"
            class="editor__redo icon-redo"
            :disabled="!redoActive"
          />

          <Button
            @button-clicked="toggleSerifSansserif"
            type="custom"
            active
            buttonMargin="0 2px 0 0"
            buttonPadding="0 8px"
            buttonWidth="54px"
            buttonHeight="32px"
            class="editor__font-type"
            :class="getCurrentDocument.serif ? 'icon-serif' : 'icon-sans'"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 8px"
            buttonHeight="32px"
            class="ql-bold icon-bold"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 8px"
            buttonHeight="32px"
            class="ql-italic icon-italic"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 8px"
            buttonHeight="32px"
            class="ql-underline icon-underline"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 4px"
            buttonHeight="32px"
            class="ql-strike icon-strike"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-header icon-h1"
            value="1"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-header icon-h2"
            value="2"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-header icon-h3"
            value="3"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-align icon-align-left"
            value=""
          />

          <!-- <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-align icon-align-center"
            value="center"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-align icon-align-justify"
            value="justify"
          /> -->

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-align icon-align-right"
            value="right"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-direction icon-rtl"
            value="rtl"
            index="1"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-list icon-list-ordered"
            value="ordered"
          />

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-list icon-list-bullet"
            value="bullet"
          />

          <!-- <Button
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-link icon-link editor__header-link-button"
          /> -->

          <Button
            @button-clicked="save"
            type="custom"
            buttonMargin="0 2px 0 0"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="ql-clean icon-clear-formatting"
          />

          <Button
            @button-clicked="
              copyTextToClipboard(quill.container.children[0].innerText)
            "
            type="custom"
            buttonPadding="0 6px"
            buttonHeight="32px"
            class="editor__copy icon-copy"
            :confirming="copyText"
          />

          <Button
            @button-clicked="reloadPage"
            type="quaternary"
            buttonMargin="0 4px 0 0"
            buttonWidth="32px"
            buttonHeight="32px"
          >
            <i class="icon-refresh"></i>
          </Button>
        </header>
      </section>

      <Button
        v-if="isFullScreen"
        type="secondary"
        @button-clicked="collapseFullScreenEditorClicked"
        buttonWidth="40px"
        buttonHeight="40px"
        buttonMargin="0"
        buttonPadding="0"
        class="editor__header-icon"
      >
        <i class="icon-collapse"></i>
      </Button>

      <!-- Used for Public documents -->
      <section class="editor__editor-container">
        <!-- Editor -->
        <main
          @paste="fixScrollOnPaste()"
          class="editor__editor"
          :class="[{ 'editor__editor--serif': getCurrentDocument.serif }]"
          :ref="`editor-${getCurrentDocument.uid}`"
          :id="`editor-${getCurrentDocument.uid}`"
          spellcheck="true"
        ></main>
      </section>

      <footer
        :class="['editor__footer', { 'editor__footer--expanded': isExpanded }]"
      >
        <span class="editor__footer-data-small">
          {{
            $wordCounter(getCurrentDocument.text ? getCurrentDocument.text : '')
              .bytes
          }}
          {{ $languages[getAppLanguage].editor.footer.textSize }}
        </span>

        <span class="editor__footer-data-large">
          {{
            $wordCounter(getCurrentDocument.text ? getCurrentDocument.text : '')
              .bytes
          }}
          {{ $languages[getAppLanguage].editor.footer.textSize }},
          {{
            $wordCounter(getCurrentDocument.text ? getCurrentDocument.text : '')
              .characters
          }}
          {{ $languages[getAppLanguage].editor.footer.characters }},
          {{
            $wordCounter(getCurrentDocument.text ? getCurrentDocument.text : '')
              .words
          }}
          {{ $languages[getAppLanguage].editor.footer.words }},
          {{
            $readingTimeCalculator(
              getCurrentDocument.text ? getCurrentDocument.text : ''
            ).totalHumanizedDuration
          }}
        </span>
        <div class="editor__footer-save-section">
          <div>
            <Broadcaster
              v-if="getCurrentDocument.public"
              :live="userIsTyping"
              :text="
                getCurrentDocument.activeService === 'translator' &&
                !getCurrentDocument.expanded
                  ? $languages[getAppLanguage].editor.footer
                      .documentAndTranslationAreLive
                  : $languages[getAppLanguage].editor.footer.documentIsLive
              "
            />
          </div>

          <Dropdown
            dropdownMargin="0"
            dropdownHeight="32px"
            :dropdownTitleColor="saveNoticeColor"
            :showCaret="false"
            centeredContent
            noEvents
            :title="saveNotice"
            white
          />

          <Spinner v-if="saveNoticeState" />
          <i v-else class="icon-saved"></i>

          <span
            v-if="getCurrentDocument.expanded"
            @click="setAppLanguageState"
            class="editor__footer-question"
          >
            <span class="editor__footer-save-section--upper">
              ({{ $languages[getAppLanguage].languageCode }})
            </span>
            {{ $languages[getAppLanguage].language }}
            <i class="icon-caret-down"></i>
          </span>

          <LanguageSelector
            v-show="getAppLanguageState"
            @close="setAppLanguageState"
          />

          <i
            v-if="getCurrentDocument.expanded"
            @click="setHelpNotificationState"
            class="editor__footer-question icon-question"
          ></i>
        </div>

        <HelpNotification
          v-show="getHelpNotificationState"
          @close="setHelpNotificationState"
        />
      </footer>
    </section>
  </div>
</template>

<script>
// Store data
import { mapGetters, mapActions } from 'vuex'

// Libraries
import Quill from 'quill'

// Components
import Button from '@/components/Button'
import Dropdown from '@/components/Dropdown'
import Spinner from '@/components/Spinner'
import Broadcaster from '@/components/Broadcaster'
import LanguageSelector from '@/components/LanguageSelector'
import HelpNotification from '@/components/HelpNotification'

// Helpers
// import htmlCleaner from '@/services/htmlCleaner'
// import isLanguageDirectionRTL from '@/helpers/isLanguageDirectionRTL'

/* Functions */
import debounce from 'lodash.debounce'

// Data files
import { translationLanguages } from '@/data/languages'
export default {
  name: 'TextEditor',

  props: {
    isFullScreen: {
      type: Boolean,
      default: false
    },

    isExpanded: {
      type: Boolean,
      default: false
    },

    isGrey: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Button,
    Dropdown,
    Spinner,
    Broadcaster,
    LanguageSelector,
    HelpNotification
  },

  data: () => ({
    currentHTML: '',
    currentText: '',
    detectedLanguage: 'en',
    editorToolbar: null,
    editor: null,
    quill: null,
    undoActive: false,
    redoActive: false,
    // isRTL: false,
    isSerif: false,
    copyText: false,
    userIsTyping: false,
    saveNotice: '',
    saveNoticeState: false,
    saveNoticeColor: '#363636',
    placeholder: ''
    // htmlCleaner
  }),

  computed: {
    ...mapGetters([
      'getOpenDocuments',
      'getCurrentDocument',
      'getDetectedLanguage',
      'getHelpNotificationState',
      'getIsUserSubscribed',
      'getAppLanguageState',
      'getAppLanguage'
    ])
  },

  watch: {
    getCurrentDocument() {
      return this.getCurrentDocument
    },

    getOpenDocuments() {
      // This is to fix document text not showing text on first load
      if (this.isEditorEmpty()) this.setEditor()

      return this.getOpenDocuments
    },

    getIsUserSubscribed() {
      return this.getIsUserSubscribed
    },

    isFullScreen() {
      if (this.editor && this.quill) this.focusEditor()
    },

    isExpanded() {
      if (this.editor && this.quill) this.focusEditor()
    },

    getAppLanguage() {
      this.placeholder = this.$languages[this.getAppLanguage].editor.placeholder
      this.initializeEditor()
      return this.getAppLanguage
    }
  },

  mounted() {
    this.placeholder = this.$languages[this.getAppLanguage].editor.placeholder

    if (this.$user.uid) {
      this.$nextTick(() => {
        this.initializeEditor()

        // Needed to detect language swapping.
        this.$eventBus.$on('document-language-swapped', () => {
          setTimeout(() => {
            if (this.getIsUserSubscribed) this.setEditor(true)
          })
        })

        // Needed for every document tab clicks.
        this.$eventBus.$on('document-tab-clicked', () => {
          setTimeout(() => {
            this.assignEditorAndToolbar()

            if (this.getIsUserSubscribed) this.setEditor(true)
          })
        })

        this.$eventBus.$on('document-saving', () => {
          setTimeout(() => {
            if (this.getIsUserSubscribed) this.saving()
          })
        })
      })
    }
  },

  methods: {
    ...mapActions([
      'setCurrentDocument',
      'setDetectedLanguage',
      'setAppLanguageState',
      // 'translateTextAction',
      'setHelpNotificationState',
      'resetNotification'
    ]),

    // /**
    //  *
    //  */
    // identifyRTLSupport() {
    //   // Detecting RTL to align text accordingly
    //   const code = this.getCurrentDocument.translationSourceLanguage
    //     ? this.getCurrentDocument.translationSourceLanguage.code
    //     : 'en'

    //   this.isRTL = isLanguageDirectionRTL(code)

    //   if (this.isRTL) {
    //     this.quill.format('direction', 'rtl')
    //     this.quill.format('align', 'right')
    //   } else {
    //     this.quill.format('direction', '')
    //     this.quill.format('align', '')
    //   }
    // },

    /**
     *
     */
    assignEditorAndToolbar() {
      // this.editor = this.$refs.editor // Assign one editor for all documents
      this.editor = this.$refs[`editor-${this.getCurrentDocument.uid}`] // Assign one editor for all documents

      // this.editorToolbar = this.$refs.editorToolbar // Assign individual toolbars for each document
      this.editorToolbar = this.$refs[
        `editorToolbar-${this.getCurrentDocument.uid}`
      ] // Assign individual toolbars for each document
    },

    initializeQuill() {
      this.assignEditorAndToolbar()

      const editorOptions = {
        compatibilityMode: false,
        modules: {
          toolbar: this.editorToolbar,
          history: {
            maxStack: 200,
            userOnly: true
          }
        },
        placeholder: this.placeholder,
        formats: [
          'bold',
          'italic',
          'underline',
          'strike',
          'header',
          'direction',
          'align',
          'list',
          'link'
        ],
        theme: 'snow'
      }

      this.quill = new Quill(this.editor, editorOptions)
    },

    /**
     *
     */
    initializeEditor() {
      this.initializeQuill()

      // // Setting up the placeholder for links
      // const tooltip = this.quill.theme.tooltip
      // const input = tooltip.root.querySelector('input[data-link]')
      // input.dataset.link = 'www.fluently.so'

      let text = this.quill.root.innerText
      let textHTML = this.quill.root.innerHTML

      this.saveNotice = this.$languages[this.getAppLanguage].editor.footer.saved

      this.quill.on('text-change', (delta, source) => {
        text = this.quill.root.innerText
        textHTML = this.quill.root.innerHTML

        this.undoActive = this.quill.history.stack.undo.length > 0
        this.redoActive = this.quill.history.stack.redo.length > 0

        if (this.getIsUserSubscribed)
          return this.editorInputEventTriggered(textHTML, text)
      })

      this.setEditor(true)
    },

    /**
     *
     */
    setEditor(clearHistory = false) {
      const delta = this.quill.clipboard.convert(this.getCurrentDocument.text)
      this.quill.setContents(delta)

      if (clearHistory) this.quill.history.clear()

      // Used instead of setContents because this approach provides less data corruption
      // this.quill.container.children[0].innerHTML = this.getCurrentDocument.text

      if (!this.getCurrentDocument.translationSourceLanguageManualMode) {
        // Detect Language
        this.detectedLanguage = this.$languageDetector(this.currentText)
        this.setDetectedLanguage(this.detectedLanguage)
      } else
        this.setDetectedLanguage(
          this.getCurrentDocument.translationSourceLanguage.language.toLowerCase()
        )

      this.undoActive = this.quill.history.stack.undo.length > 0
      this.redoActive = this.quill.history.stack.redo.length > 0
    },

    /**
     *
     */
    fixScrollOnPaste() {
      const findEditorScrollContainer = quill => {
        let el = quill.root
        while (el) {
          if (el.scrollTop > 0) break
          el = el.parentElement
        }
        return el
      }

      return this.quill.on('editor-change', (type, delta, oldDelta, source) => {
        this.quill.scrollingContainer =
          findEditorScrollContainer(this.quill) || document.documentElement // Solve the problem of scrolling to the top when pasting
      })
    },

    /**
     *
     */
    editorInputEventTriggered(currentHTML, currentText) {
      this.userIsTyping = true

      this.resetNotification()

      this.currentText = currentText // Asigning current text on user input
      this.currentHTML = currentHTML // Asigning current HTML on user input

      if (!this.getCurrentDocument.translationSourceLanguageManualMode) {
        // Detect Language
        this.detectedLanguage = this.$languageDetector(this.currentText)
        this.setDetectedLanguage(this.detectedLanguage)
      } else
        this.setDetectedLanguage(
          this.getCurrentDocument.translationSourceLanguage.language.toLowerCase()
        )

      // Save text only if it's different than the current stored in backend
      // if (
      //   // This compares the stored HTML string (without spaces) to the current added by user
      //   htmlCleaner(this.currentHTML)
      //     .replace(/\s/g, '')
      //     .replace(/&nbsp;/g, ' ') !==
      //   htmlCleaner(this.getCurrentDocument.text)
      //     .replace(/\s/g, '')
      //     .replace(/&nbsp;/g, ' ')
      // ) {
      //   return this.save()
      // }

      // Save text
      return this.save()
    },

    /**
     *
     */
    getTextAndSetAsCurrentText() {
      const document = this.getCurrentDocument
      const textWithoutSpaces = this.currentHTML.replace(/&nbsp;/g, ' ') // This is to avoid &nbsp; as it counts for many bytes

      if (this.parseLanguageForDetectedTranslationLanguage())
        document.translationSourceLanguage = this.parseLanguageForDetectedTranslationLanguage()

      document.text = textWithoutSpaces

      // This is what re activates the language detection when document text is empty
      // if (this.isEditorEmpty())
      //   document.translationSourceLanguageManualMode = false

      // if (!document.expanded && document.activeService === 'translator') {
      //   this.$eventBus.$emit('document-translation-with-loader-requested')
      //   this.translateTextAction({
      //     text: document.text,
      //     sourceLanguageCode:
      //       document.translationSourceLanguage.code ||
      //       this.$detectBrowserLanguage().code,
      //     targetLanguageCode:
      //       document.translationLanguage.code ||
      //       this.$detectBrowserLanguage().code,
      //     event: 'document-translation-updated'
      //   })
      // }

      // Update text
      this.setCurrentDocument(document)
    },

    /**
     *
     */
    copyTextToClipboard(text) {
      // text = htmlCleaner(text) // @ TODO - add if necessary

      const textarea = document.createElement('textarea')
      const timeToHideMessage = 1200

      this.copyText = !this.copyText

      setTimeout(() => {
        this.copyText = !this.copyText
      }, timeToHideMessage)

      textarea.value = text
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    },

    /**
     *
     */
    parseLanguageForDetectedTranslationLanguage() {
      for (const language of translationLanguages) {
        if (language.language.toLowerCase() === this.getDetectedLanguage) {
          return language
        }
      }
    },

    /**
     *
     */
    focusEditor() {
      this.quill.focus()
    },

    /**
     *
     */
    collapseFullScreenEditorClicked() {
      this.$emit('collapse-full-screen-editor-clicked')
    },

    /**
     *
     */
    isEditorEmpty() {
      return (
        !this.currentText ||
        !this.currentHTML ||
        this.currentHTML === '' ||
        this.currentHTML === '<br>' ||
        this.currentHTML === '<p><br></p>' ||
        this.currentHTML === '<p><br></p><p><br></p>' ||
        this.currentHTML === '<h1><br></h1>' ||
        this.currentHTML === '<h2><br></h2>' ||
        this.currentHTML === '<h3><br></h3>' ||
        this.currentHTML === '<h1><br></h1><p><br></p>' ||
        this.currentHTML === '<h2><br></h2><p><br></p>' ||
        this.currentHTML === '<h3><br></h3><p><br></p>' ||
        this.currentHTML.includes('ql-blank') || // Check for blank editor
        this.currentHTML.includes('undefined')
      )
    },

    /**
     *
     */
    toggleSerifSansserif() {
      this.isSerif = !this.getCurrentDocument.serif

      this.$eventBus.$emit('editor-is-serif', this.isSerif)

      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(
            `switched serif ${this.getCurrentDocument.serif ? 'on' : 'off'}`
          )
    },

    /**
     *
     */
    // validateURL (url) {
    //   const pattern = new RegExp('^(\\/\\/)?' + // protocol
    // '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    // '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    // '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    // '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    // '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    //   return !!pattern.test(url.replace(/(^\w+:|^)\/\//, '')) // Removing http and https
    // },

    /**
     *
     */
    save: debounce(function() {
      this.saveNotice = this.$languages[
        this.getAppLanguage
      ].editor.footer.saving
      this.saveNoticeState = true
      this.saveNoticeColor = ''
      this.getTextAndSetAsCurrentText()
      // this.identifyRTLSupport()

      setTimeout(() => {
        this.userIsTyping = false
        this.saveNotice = this.$languages[
          this.getAppLanguage
        ].editor.footer.saved
        this.saveNoticeState = false
        this.saveNoticeColor = '#363636'
      }, 1000 /* Timer to show saved message */)
    }, 500 /* Typing timer */),

    /**
     *
     */
    saving() {
      this.saveNotice = this.$languages[
        this.getAppLanguage
      ].editor.footer.saving
      this.saveNoticeState = true
      this.saveNoticeColor = ''
      // this.identifyRTLSupport()

      setTimeout(() => {
        this.userIsTyping = false
        this.saveNotice = this.$languages[
          this.getAppLanguage
        ].editor.footer.saved
        this.saveNoticeState = false
        this.saveNoticeColor = '#363636'
      }, 1000 /* Timer to show saved message */)
    },

    /**
     *
     */
    reloadPage() {
      location.reload(true)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@/scss/editor';
</style>
