var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"document-loader"},[_c('div',{class:[
      'document-loader__section',
      { 'document-loader__section--single': _vm.single }
    ]},[_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"40%","lineHeight":"40px","lineAnimationDuration":".8s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"80%","lineHeight":"32px","lineAnimationDuration":"1.6s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"30%","lineHeight":"32px","lineAnimationDuration":".6s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"90%","lineAnimationDuration":"1.8s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineAnimationDuration":"2s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"85%","lineAnimationDuration":"1.7s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"90%","lineAnimationDuration":"1.8s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineAnimationDuration":"2s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"20%","lineAnimationDuration":".4s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"90%","lineAnimationDuration":"1.8s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineAnimationDuration":"2s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"85%","lineAnimationDuration":"1.7s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"90%","lineAnimationDuration":"1.8s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineAnimationDuration":"2s","inverted":""}}),_c('TextAnimation',{attrs:{"lines":1,"lineWidth":"20%","lineAnimationDuration":".4s","inverted":""}})],1),_c('div',{class:[
      'document-loader__section',
      { 'document-loader__section--single': _vm.single }
    ]},[_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"40%","lineHeight":"40px","lineAnimationDuration":".8s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"80%","lineHeight":"32px","lineAnimationDuration":"1.6s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"30%","lineHeight":"32px","lineAnimationDuration":".6s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"90%","lineAnimationDuration":"1.8s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineAnimationDuration":"2s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"85%","lineAnimationDuration":"1.7s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"90%","lineAnimationDuration":"1.8s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineAnimationDuration":"2s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"20%","lineAnimationDuration":".4s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"90%","lineAnimationDuration":"1.8s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineAnimationDuration":"2s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"85%","lineAnimationDuration":"1.7s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"90%","lineAnimationDuration":"1.8s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineAnimationDuration":"2s"}}),_c('TextAnimation',{attrs:{"white":"","lines":1,"lineWidth":"20%","lineAnimationDuration":".4s"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }