<template>
  <span class="broadcaster">
    <span class="broadcaster__text">{{ text }}</span>
    <span :class="['broadcaster__pulse', { 'broadcaster__pulse--live': live }]">
    </span>
  </span>
</template>

<script>
export default {
  name: 'Broadcaster',

  props: {
    live: {
      type: Boolean,
      required: false
    },

    text: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.broadcaster {
  align-items: center;
  display: flex;
}

.broadcaster__text {
  display: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include screen-small {
    display: inline;
  }
}

.broadcaster__pulse {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: var(--size-unit-4);
  height: var(--size-unit-4);

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    width: var(--size-unit-4);
    height: var(--size-unit-4);
    box-sizing: border-box;
    border-radius: 100%;
    background-color: var(--color-success);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    width: var(--size-unit);
    height: var(--size-unit);
    background-color: rgba(var(--color-success-rbg), var(--opacity-hover));
    border-radius: 100%;
    box-shadow: 0 0 8px rgba(var(--color-dark-rbg), var(--opacity-disabled));
  }

  &--live {
    &:before {
      animation-iteration-count: infinite;
    }

    &:after {
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes pulse-ring {
  0% {
    transform: translate(-50%, -50%) scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}
</style>
