var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideOptions),expression:"hideOptions"}],class:[
    'dropdown',
    {
      'dropdown--active': _vm.isDropdownActive,
      'dropdown--clicked': _vm.isDropdownClicked,
      'dropdown--outlined': _vm.outlined,
      'dropdown--no-border': _vm.noBorder,
      'dropdown--purple': _vm.purple,
      'dropdown--white': _vm.white,
      'dropdown--centered-content': _vm.centeredContent,
      'dropdown--disabled': _vm.disabled,
      'dropdown--no-events': _vm.noEvents,
      'dropdown--has-options': _vm.hasOptions()
    }
  ],style:(`
    border-radius: ${_vm.dropdownRadius};
    margin: ${_vm.dropdownMargin};
    padding: ${_vm.dropdownPadding};
    width: ${_vm.dropdownWidth};
    height: ${_vm.dropdownHeight};
    color: ${_vm.dropdownTitleColor};
  `),on:{"click":_vm.clicked}},[(_vm.label)?_c('div',{staticClass:"dropdown__label"},[_c('span',[_vm._v(_vm._s(_vm.label))])]):_vm._e(),(_vm.hasBeforeSlot)?_c('div',{class:[
      'dropdown__slot',
      { 'dropdown__slot--before-with-title': _vm.title }
    ]},[_vm._t("before")],2):_vm._e(),(_vm.ghostTitle)?_c('div',{staticClass:"dropdown__ghost-title",style:(`color: ${_vm.ghostTitle.color || ''}`)},[_vm._v(" "+_vm._s(_vm.ghostTitle.text)+" ")]):_vm._e(),(_vm.title || _vm.searchText || _vm.searchedTitlePrefix)?_c('div',{staticClass:"dropdown__toggle-title-prefix-wrapper"},[_c('span',{staticClass:"dropdown__title-prefix-wrapper"},[(_vm.searchText && _vm.searchedTitlePrefix)?_c('span',{staticClass:"dropdown__title-prefix"},[_vm._v(_vm._s(_vm.searchedTitlePrefix))]):(_vm.titlePrefix)?_c('span',{staticClass:"dropdown__title-prefix"},[_vm._v(_vm._s(_vm.titlePrefix))]):_c('span'),(_vm.searchText && _vm.serachedTitle)?_c('span',{staticClass:"dropdown__title"},[_vm._v(_vm._s(_vm.serachedTitle))]):(_vm.title)?_c('span',{staticClass:"dropdown__title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_c('span')]),(_vm.toggle)?_c('Toggle',{staticClass:"dropdown__toggle",on:{"toggle-clicked":_vm.toggleClicked}}):_vm._e()],1):_vm._e(),(_vm.hasAfterSlot)?_c('div',{class:['dropdown__slot', { 'dropdown__slot--after-with-title': _vm.title }]},[_vm._t("after")],2):_vm._e(),(_vm.showCaret)?_c('div',{staticClass:"dropdown__caret-wrapper"},[_c('span',{staticClass:"dropdown__divider"}),_c('i',{class:[
        'dropdown__caret icon-caret-down',
        { 'dropdown__caret--active': _vm.isDropdownActive }
      ]})]):_vm._e(),(_vm.options && _vm.isDropdownActive && _vm.showCaret)?_c('div',{class:[
      'dropdown__options-wrapper',
      { 'dropdown__options-wrapper--right': !_vm.dropdownOptionsLeft },
      { 'dropdown__options-wrapper--left': _vm.dropdownOptionsLeft }
    ],style:(`width: ${_vm.dropdownOptionsWidth};`)},[_c('div',{ref:"search",class:[
        'dropdown__options',
        { 'dropdown__options--with-search': _vm.options.length > 0 }
      ]},[(_vm.options.length > 0)?_c('div',{staticClass:"dropdown__search-input-wrapper"},[_c('i',{staticClass:"dropdown__search-icon icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],ref:"searchInput",staticClass:"dropdown__search-input",attrs:{"placeholder":_vm.$languages[_vm.getAppLanguage].dropdown.searchPlaceholder,"aria-label":_vm.$languages[_vm.getAppLanguage].dropdown.searchPlaceholder,"autocomplete":"true"},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}})]):_vm._e(),(_vm.searchedOptions.length > 0)?_c('div',_vm._l((_vm.searchedOptions),function(option,optionIndex){return _c('div',{key:optionIndex,staticClass:"dropdown__option",on:{"click":function($event){return _vm.emitDropdownSearchedOptionClicked(option)}}},[_vm._v(" "+_vm._s(option)+" ")])}),0):_c('div',{on:{"click":_vm.hideOptions}},[_vm._t("dropdown-options")],2)])]):_vm._e(),(_vm.comingSoon)?_c('div',{staticClass:"dropdown__options-wrapper dropdown__options-wrapper--coming-soon"},[_vm._m(0)]):_vm._e(),(_vm.hasOptions() && _vm.showCaret)?_c('div',{staticClass:"dropdown__clicker",on:{"click":_vm.toggleOptions}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown__options"},[_c('span',{staticClass:"dropdown__option"},[_c('small',[_vm._v("⚠️ Coming soon...")])])])
}]

export { render, staticRenderFns }