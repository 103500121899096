<template>
  <div
    :class="[
      'editor translator',
      {
        ['editor--expanded']: isExpanded,
        ['editor--full-screen']: isFullScreen,
        ['editor--warning']: getIsUserEditingTranslation
      }
    ]"
  >
    <!-- Translator -->
    <section class="editor__wrapper">
      <section class="editor__header-container">
        <header class="editor__header">
          <!-- @TODO - Activate when ready -->
          <!-- <Dropdown dropdownWidth="34px" :title="`<svg width='13' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.03 5.112L10 5H9l-.03.112L6 15h1.07l.749-2.5h3.362l.749 2.5H13l-2.97-9.887zM8.193 11.25L9.5 6.888l1.307 4.362H8.193zM7 2.5h2V1.25H5V0H4v1.25H0V2.5h6c0 2.006-.908 3.904-2 5.47-.855-1.229-1.608-2.672-1.885-4.22H1.101c.288 1.925 1.201 3.679 2.219 5.129C1.831 10.736.27 11.943.24 11.966l.519 1.069c.128-.098 1.712-1.315 3.252-3.234a21.184 21.184 0 001.68 1.864l.616-.984A20.235 20.235 0 014.69 8.893C5.937 7.123 7 4.915 7 2.5z' fill='#242424'/></svg>`" white :showCaret="false" /> -->
          <!-- <Dropdown dropdownWidth="176px" title="© View document source" white :showCaret="false" comingSoon disabled /> -->

          <Button
            @button-clicked="executeRefreshTranslation"
            buttonMargin="0 4px 0 0"
            buttonWidth="102px"
            buttonHeight="32px"
            :confirming="refreshTranslation"
            class="editor__header-translate-button"
          >
            <span
              v-if="refreshTranslation || getIsLoadingTranslation"
              class="editor__header-translate-button-inner"
            >
              <span class="editor__header-translate-button-inner-text">
                {{ $languages[getAppLanguage].translator.translate }}
              </span>
              <Spinner spinnerSize="16px" />
            </span>
            <span v-else class="editor__header-translate-button-inner">
              <span class="editor__header-translate-button-inner-text">
                {{ $languages[getAppLanguage].translator.translate }}
              </span>
              <i class="icon-refresh"> </i>
            </span>
          </Button>

          <Button
            @button-clicked="createNewDocumentFromTranslation"
            :type="getIsUserEditingTranslation ? 'primary' : 'quaternary'"
            buttonMargin="0 4px"
            buttonPadding="0 8px"
            buttonWidth="auto"
            buttonHeight="32px"
            icon="document"
          >
            {{ $languages[getAppLanguage].translator.create }}
          </Button>

          <Button
            @button-clicked="copyTextToClipboard($refs.translator.innerText)"
            type="quaternary"
            buttonMargin="0 4px"
            buttonWidth="32px"
            buttonHeight="32px"
            :confirming="copyTranslation"
          >
            <i class="icon-copy"></i>
          </Button>
        </header>
      </section>

      <section class="editor__editor-container">
        <main v-if="refreshTranslation" class="editor__editor" ref="translator">
          <Spinner spinnerSize="24px" />
        </main>

        <main
          v-else
          class="editor__editor"
          :class="[
            {
              'editor__editor--placeholder': !getCurrentDocument.text,
              'editor__editor--serif': getCurrentDocument.serif,
              'editor__editor--rtl': isRTL
            }
          ]"
          ref="translator"
          v-html="getCurrentDocument.translation"
          contenteditable="true"
          @blur="userIsOutOfEditingMode()"
        ></main>
      </section>

      <footer class="editor__footer editor__footer--secondary">
        <span class="editor__footer-data-small">
          {{
            $wordCounter(
              getCurrentDocument.text && getCurrentDocument.translation
                ? getCurrentDocument.translation
                : ''
            ).bytes
          }}
          translation size
        </span>

        <span class="editor__footer-data-large">
          {{
            $wordCounter(
              getCurrentDocument.text && getCurrentDocument.translation
                ? getCurrentDocument.translation
                : ''
            ).bytes
          }}
          translation size,
          {{
            $wordCounter(
              getCurrentDocument.text && getCurrentDocument.translation
                ? getCurrentDocument.translation
                : ''
            ).characters
          }}
          characters,
          {{
            $wordCounter(
              getCurrentDocument.text && getCurrentDocument.translation
                ? getCurrentDocument.translation
                : ''
            ).words
          }}
          words,
          {{
            $readingTimeCalculator(
              getCurrentDocument.text && getCurrentDocument.translation
                ? getCurrentDocument.translation
                : ''
            ).totalHumanizedDuration
          }}
        </span>

        <div class="editor__footer-settings">
          <span
            v-if="!getCurrentDocument.expanded"
            @click="setAppLanguageState"
            class="editor__footer-question"
          >
            <span class="editor__footer-settings--upper">
              ({{ $languages[getAppLanguage].languageCode }})
            </span>
            {{ $languages[getAppLanguage].language }}
            <i class="icon-caret-down"></i>
          </span>

          <LanguageSelector
            v-show="getAppLanguageState"
            @close="setAppLanguageState"
          />

          <i
            v-if="!getCurrentDocument.expanded"
            @click="setHelpNotificationState"
            class="editor__footer-question icon-question"
          ></i>

          <HelpNotification
            v-show="getHelpNotificationState"
            @close="setHelpNotificationState"
          />
        </div>
      </footer>
    </section>
  </div>
</template>

<script>
// Store data
import { mapGetters, mapActions } from 'vuex'

// Helpers
import isLanguageDirectionRTL from '@/helpers/isLanguageDirectionRTL'

// Components
import LanguageSelector from '@/components/LanguageSelector'
import HelpNotification from '@/components/HelpNotification'
import Button from '@/components/Button'
import Spinner from '@/components/Spinner'

export default {
  name: 'Translator',

  props: {
    isFullScreen: {
      type: Boolean,
      default: false
    },

    isExpanded: {
      type: Boolean,
      default: false
    }
  },

  components: {
    LanguageSelector,
    HelpNotification,
    Button,
    Spinner
  },

  data: () => ({
    editor: null,
    isRTL: false,
    showSaveButton: false,
    refreshTranslation: false,
    copyTranslation: false,
    isEditorScrolling: false
  }),

  computed: {
    ...mapGetters([
      'getCurrentDocument',
      'getIsLoadingTranslation',
      'getHelpNotificationState',
      'getAppLanguage',
      'getAppLanguageState',
      'getIsUserEditingTranslation'
    ])
  },

  watch: {
    getCurrentDocument() {
      // Detecting RTL to align text accordingly
      this.isRTL = isLanguageDirectionRTL(
        this.getCurrentDocument.translationLanguage.code
      )

      return this.getCurrentDocument
    },

    getAppLanguage() {
      this.executeRefreshTranslation()
      return this.getAppLanguage
    }
  },

  mounted() {
    this.initTranslatorEditor()
  },

  methods: {
    ...mapActions([
      'addDocumentAction',
      'translateTitleAction',
      'translateTextAction',
      'setHelpNotificationState',
      'setAppLanguageState',
      'setNotification',
      'resetNotification',
      'setCurrentDocument',
      'setIsUserEditingTranslation',
      'setIsLoadingTranslation'
    ]),

    /**
     *
     */
    initTranslatorEditor() {
      const translator = this.$refs.translator

      // Detecting RTL to align text accordingly
      this.isRTL = isLanguageDirectionRTL(
        this.getCurrentDocument.translationLanguage.code
      )

      // This is the functionality to modify the title on scroll
      translator.addEventListener('scroll', () => {
        if (translator.scrollTop > 50) this.isEditorScrolling = true
        else this.isEditorScrolling = false
      })

      translator.addEventListener('focus', () => {
        const warning = this.$languages[this.getAppLanguage].global
          .notifications.watchOut
        this.setIsUserEditingTranslation(true)
        this.setNotification({
          state: true,
          type: 'warning',
          message: warning
        })
      })

      translator.addEventListener('input', () => {
        this.resetNotification()
      })
    },

    /**
     *
     */
    userIsOutOfEditingMode() {
      const document = this.getCurrentDocument
      const currentTranslation = this.$refs.translator.innerHTML

      document.translation = currentTranslation

      this.setCurrentDocument(document)
      this.resetNotification()

      this.$eventBus.$emit('document-saving')

      return this.setIsUserEditingTranslation(false)
    },

    /**
     *
     */
    copyTextToClipboard(text) {
      const textarea = document.createElement('textarea')
      const timeToHideMessage = 600

      this.copyTranslation = !this.copyTranslation

      setTimeout(() => {
        this.copyTranslation = !this.copyTranslation
      }, timeToHideMessage)

      textarea.value = text
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    },

    /**
     *
     */
    collapseFullScreenEditorClicked() {
      this.$emit('collapse-full-screen-editor-clicked')
    },

    /**
     *
     */
    executeRefreshTranslation() {
      const timeToHideMessage = 600

      this.refreshTranslation = !this.refreshTranslation

      setTimeout(() => {
        this.refreshTranslation = !this.refreshTranslation
      }, timeToHideMessage)

      this.setIsLoadingTranslation(true)

      if (
        !this.getCurrentDocument.expanded &&
        this.getCurrentDocument.activeService === 'translator'
      ) {
        this.translateTitleAction({
          title: this.getCurrentDocument.title,
          sourceLanguageCode:
            this.getCurrentDocument.translationSourceLanguage.code ||
            this.$detectBrowserLanguage().code,
          targetLanguageCode:
            this.getCurrentDocument.translationLanguage.code ||
            this.$detectBrowserLanguage().code
        })

        this.translateTextAction({
          text: this.getCurrentDocument.text,
          sourceLanguageCode:
            this.getCurrentDocument.translationSourceLanguage.code ||
            this.$detectBrowserLanguage().code,
          targetLanguageCode:
            this.getCurrentDocument.translationLanguage.code ||
            this.$detectBrowserLanguage().code,
          event: 'document-translation-updated'
        })
      }

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`refreshed the translation`)
    },

    /**
     *
     */
    createNewDocumentFromTranslation() {
      const document = {
        project: '',
        title: '',
        translatedTitle: '',
        source: {},
        text: '',
        translation: '',
        translationSourceLanguageManualMode: false,
        translationSourceLanguage: {
          code: '',
          prefix: '',
          language: ''
        },
        translationLanguage: this.$detectBrowserLanguage(),
        expanded: true,
        activeService: '',
        serif: false,
        public: false,
        publicUid: '',
        open: true
      }

      const translator = this.$refs.translator

      document.source = this.getCurrentDocument
      document.translationSourceLanguage = this.getCurrentDocument.translationLanguage
      document.translationSourceLanguageManualMode = true
      document.title = this.getCurrentDocument.translatedTitle
      document.text = translator.innerHTML

      this.addDocumentAction(document)

      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(`clicked 'Create as new document'`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/editor';

.editor {
  &--warning {
    .editor__wrapper {
      border: 2px solid var(--color-warning);
    }

    @include screen-large {
      .editor__wrapper {
        border-top: none;
        border-bottom: none;
      }

      .editor__footer {
        border: 2px solid var(--color-warning);
        border-top: none;
      }
    }
  }
}

// To give some space to the create as button on smaller screens
.editor__editor {
  overflow: scroll;
  padding-top: var(--size-unit-4);
  padding-bottom: var(--size-unit-4);

  &--rtl {
    @include rtl;
  }

  @include screen-medium {
    padding-bottom: var(--size-unit-11);
    height: calc(45vh - 100px);
  }

  @include screen-large {
    padding-top: var(--size-unit-2);
    height: calc(100vh - 216px);
  }
}

// To give some space to the create as button on smaller screens
.editor__header {
  align-items: center;
}

.editor__header-translate-button {
  &-inner {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &-inner-text {
    margin-right: 6px;
  }
}

.editor__header-container {
  &::before,
  &::after {
    display: none;
  }
}

.editor__footer {
  left: initial;
  right: 0;
  background-color: var(--color-theme-lighter);
}

.editor__footer-settings {
  display: flex;
  text-transform: capitalize;

  &--upper {
    text-transform: uppercase;
  }
}
</style>
