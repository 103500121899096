<template>
  <div class="header">
    <section @click="openDocumentOverview" class="header__section">
      <div class="header__logo">
        <img src="../assets/images/logo-monogram.svg" alt="Logo" />
      </div>
      <div v-if="getIsUserSubscribed" class="header__burger">
        <i class="icon-burger"></i>
      </div>
    </section>

    <section class="header__section">
      <div
        @click="toggleDarkMode"
        :class="[
          'header__dark-mode-button',
          { 'header__dark-mode-button--active': getDarkMode }
        ]"
      >
        <i class="icon-dark-mode"></i>
      </div>

      <div
        @click="toggleMobileMenu"
        :class="[
          'header__mobile-menu-button',
          { 'header__mobile-menu-button--active': getMobileMenu }
        ]"
      >
        <i :class="getMobileMenu ? 'icon-close' : 'icon-translate-main'"></i>
      </div>

      <div v-click-outside:pointerdown="hideUserDropdown" class="header__user">
        <span @click="toggleUserDropdown" class="header__user-avatar">
          {{ $user.displayLetters.substring(0, 2) }}
        </span>

        <div v-if="showUserDropdown" class="header__user-dropdown">
          <div class="header__user-dropdown-name">
            <span>{{ $user.displayName }}</span>
          </div>
          <div
            @click="showUserAccount = true"
            class="header__user-dropdown-item"
          >
            {{ $languages[getAppLanguage].document.tabs.user.myAccount }}
          </div>
          <div @click="logout" class="header__user-dropdown-item">
            {{ $languages[getAppLanguage].document.tabs.user.logOut }}
          </div>
        </div>
      </div>

      <!-- The DocumentTabs component handles most of the logic of documents -->
      <DocumentTabs :tabs="tabs" />
    </section>

    <UserAccount
      v-show="showUserAccount"
      @close="closeDocumentOverview"
      :tabActive="getIsUserSubscribed ? 'account' : 'subscription'"
    />
    <!-- <UserAccount tabActive="account" /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// Components
import DocumentTabs from '@/components/document/DocumentTabs'
import UserAccount from '@/components/UserAccount'

export default {
  name: 'Header',

  props: {
    tabs: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    showUserDropdown: false,
    showUserAccount: false,
    showUserAccountInterval: null
  }),

  components: {
    DocumentTabs,
    UserAccount
  },

  computed: {
    ...mapGetters([
      'getDarkMode',
      'getMobileMenu',
      'getIsUserSubscribed',
      'getAppLanguage'
    ])
  },

  watch: {
    getIsUserSubscribed() {
      this.setUserAccountState()
      return this.getIsUserSubscribed
    }
  },

  mounted() {
    clearInterval(this.showUserAccountInterval)
    this.checkSubscriptionModalState()
  },

  methods: {
    ...mapActions([
      'logoutAction',
      'setDarkMode',
      'setMobileMenu',
      'setDocumentTree'
    ]),

    /**
     *
     */
    setUserAccountState() {
      if (!this.getIsUserSubscribed) this.showUserAccount = true
      else this.showUserAccount = false
    },

    /**
     *
     */
    checkSubscriptionModalState() {
      const timeToCheckForSubscriptionChanges = 300000 // 5 min.
      this.setUserAccountState()

      this.showUserAccountInterval = setInterval(() => {
        this.setUserAccountState()
      }, timeToCheckForSubscriptionChanges)
    },

    /**
     *
     */
    toggleUserDropdown() {
      this.showUserDropdown = !this.showUserDropdown
    },

    /**
     *
     */
    hideUserDropdown() {
      this.showUserDropdown = false
    },

    /**
     *
     */
    toggleDarkMode() {
      this.$setDarkMode(!this.$getDarkMode())

      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(`switched dark-mode ${this.$getDarkMode() ? 'on' : 'off'}`)

      return this.setDarkMode(!this.getDarkMode)
    },

    /**
     *
     */
    openDocumentOverview() {
      this.setDocumentTree(true)

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`opened document overview`)
    },

    /**
     *
     */
    closeDocumentOverview() {
      this.showUserAccount = false

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`closed document overview`)
    },

    /**
     *
     */
    toggleMobileMenu() {
      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`toggled mobile menu`)

      return this.setMobileMenu(!this.getMobileMenu)
    },

    /**
     *
     */
    logout() {
      this.logoutAction()
      this.hideUserDropdown()

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`logged out`)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed; // To guarantee header fixability
  top: 0;
  width: 100vw;
  height: $header-height;
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
  z-index: 999999;
}

.header__section {
  display: flex;
  align-items: center;
  height: inherit;
}

.header__logo,
.header__burger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-unit-5);
  height: inherit;
  border-right: 1px solid
    rgba(var(--color-brand-lightest-rgb), var(--opacity-disabled));
  z-index: 99999;
  cursor: pointer;
}

.header__mobile-menu-button,
.header__dark-mode-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-unit-5);
  height: inherit;
  color: var(--color-brand-lightest);
  transition: color var(--transition-default);
  cursor: pointer;
  z-index: 99999;

  &:hover,
  &--active {
    color: var(--color-main);
  }

  &--active {
    color: var(--color-main);

    &:hover {
      color: var(--color-main-dark);
    }
  }
}

.header__mobile-menu-button {
  @include screen-medium {
    display: none;
  }
}

.header__user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-unit-5);
  height: inherit;
  background-color: var(--color-brand-darkest);
  z-index: 99999;
}

.header__user-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $header-avatar-size;
  height: $header-avatar-size;
  font-size: var(--font-size-xs);
  border-radius: 100%;
  background-color: var(--color-main);
  color: var(--color-light);

  &:hover {
    cursor: pointer;
  }
}

.header__user-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(#{$header-height} + var(--size-unit));
  right: var(--size-unit);
  width: calc(var(--size-unit-2) * 10);
  border-radius: var(--size-unit-half);
  padding: var(--size-unit) 0;
  font-size: var(--font-size-xs);
  background-color: var(--color-brand-darkest);
}

.header__user-dropdown-name,
.header__user-dropdown-item {
  display: flex;
  align-items: center;
  height: calc(var(--size-unit-2) * 2);
  padding: 0 var(--size-unit-2);
}

.header__user-dropdown-name {
  text-transform: uppercase;
  color: var(--color-brand-lightest);

  span {
    // Ellipsis
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.header__user-dropdown-item {
  font-size: var(--font-size-s);
  cursor: pointer;

  &:hover {
    background-color: var(--color-main);
    color: var(--color-light);
  }
}
</style>
