var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getDarkMode)?_c('img',{staticClass:"spinner",style:(`
    width: ${_vm.spinnerSize};
    height: ${_vm.spinnerSize};
  `),attrs:{"src":require("../assets/icons/spinner-dark-mode.svg"),"alt":"Spinner"}}):_c('img',{staticClass:"spinner",style:(`
    width: ${_vm.spinnerSize};
    height: ${_vm.spinnerSize};
  `),attrs:{"src":require("../assets/icons/spinner.svg"),"alt":"Spinner"}})
}
var staticRenderFns = []

export { render, staticRenderFns }