<template>
  <transition name="user-account">
    <div class="user-account__mask">
      <div :class="['user-account', { rtl: isRTL() }]">
        <div class="user-account__brand-line"></div>
        <div class="user-account__container">
          <i
            v-if="!isModalBlocked"
            @click="$emit('close')"
            class="user-account__close-button icon-close"
          >
          </i>
          <section
            v-if="!getIsUserSubscribed"
            :class="[
              'user-account__tabs',
              { 'user-account__tabs--no-close': isModalBlocked }
            ]"
          >
            <button
              :class="[
                'user-account__tab',
                { 'user-account__tab--active': activeTab === 'account' }
              ]"
              @click="toggleTab('account')"
              ref="tab"
            >
              Account
            </button>
            <button
              :class="[
                'user-account__tab',
                { 'user-account__tab--active': activeTab === 'subscription' }
              ]"
              @click="toggleTab('subscription')"
              ref="tab"
            >
              Subscription
            </button>
          </section>

          <section
            v-if="activeTab === 'account'"
            :class="[
              'user-account__tabcontent',
              { 'user-account__tabcontent--active': activeTab === 'account' }
            ]"
          >
            <h1>{{ $languages[getAppLanguage].account.title }}</h1>
            <br />
            <br />
            <div class="user-account__tabcontent-item">
              <span>{{ $languages[getAppLanguage].account.userId }}</span>
              <span>
                <strong v-if="copyText">{{ $user.uid }}</strong>
                <p v-else>{{ $user.uid }}</p>
              </span>

              <span>
                <Button
                  @button-clicked="copyTextToClipboard($user.uid)"
                  type="custom"
                  buttonPadding="0 6px"
                  buttonHeight="32px"
                  class="editor__copy icon-copy"
                  :confirming="copyText"
                />
              </span>
            </div>
            <hr />
            <h2>{{ $languages[getAppLanguage].account.name }}</h2>
            <br />
            <form class="user-account__tabcontent-form">
              <label for="firstName">
                <span>{{ $languages[getAppLanguage].account.firstName }}</span>
                <input
                  type="text"
                  v-model="user.firstName"
                  id="firstName"
                  placeholder="First name"
                />
              </label>

              <label for="lastName">
                <span>{{ $languages[getAppLanguage].account.lastName }}</span>
                <input
                  type="text"
                  v-model="user.lastName"
                  id="lastName"
                  placeholder="Last name"
                />
              </label>
            </form>

            <Button
              @button-clicked="updateUserName()"
              buttonWidth="100%"
              :disabled="
                user.firstName === $user.firstName &&
                  user.lastName === $user.lastName
              "
            >
              {{ $languages[getAppLanguage].account.updateMyName }}
            </Button>

            <div>
              <br />
              <Button @button-clicked="logout" buttonWidth="100%">
                {{ $languages[getAppLanguage].account.logOut }}
              </Button>
            </div>

            <hr />

            <h2>{{ $languages[getAppLanguage].account.email }}</h2>
            <br />
            <div
              v-if="!showEmailUpdateFlow"
              class="user-account__tabcontent-item"
            >
              <span>{{ $languages[getAppLanguage].account.currentEmail }}</span>
              <span>{{ $user.email }}</span>
              <span class="user-account__link" @click="toggleEmailUpdateFlow">
                {{ $languages[getAppLanguage].account.update }}
              </span>
            </div>

            <form v-else class="user-account__tabcontent-form">
              <label for="email">
                <span>{{ $languages[getAppLanguage].account.newEmail }}*</span>
                <span class="user-account__tabcontent-error">{{
                  error.newEmail
                }}</span>
                <input
                  type="email"
                  v-model="user.newEmail"
                  id="email"
                  placeholder="New email address"
                />
              </label>

              <label for="passwrod">
                <span>
                  {{ $languages[getAppLanguage].account.yourPassword }}*
                </span>
                <span class="user-account__tabcontent-error">{{
                  error.password
                }}</span>
                <input
                  type="password"
                  v-model="user.password"
                  id="passwrod"
                  placeholder="************"
                  autocomplete="true"
                />
              </label>
            </form>

            <div
              v-if="showEmailUpdateFlow"
              class="user-account__tabcontent-item"
            >
              <span>
                <Button
                  @button-clicked="updateUserEmail()"
                  buttonWidth="100%"
                  :disabled="
                    !user.newEmail ||
                      !user.newEmail.includes('@') ||
                      !user.newEmail.includes('.') ||
                      !user.password
                  "
                >
                  {{ $languages[getAppLanguage].account.updateEmail }}
                </Button>
              </span>
              <span class="user-account__link" @click="toggleEmailUpdateFlow">
                {{ $languages[getAppLanguage].account.keepEmail }}
              </span>
            </div>

            <hr />

            <h2>{{ $languages[getAppLanguage].account.password }}</h2>
            <br />
            <div
              v-if="!showPasswordUpdateFlow"
              class="user-account__tabcontent-item"
            >
              <span>{{ $languages[getAppLanguage].account.yourPassword }}</span>
              <span>************</span>
              <span
                class="user-account__link"
                @click="togglePasswordUpdateFlow"
              >
                {{ $languages[getAppLanguage].account.update }}
              </span>
            </div>

            <form v-else class="user-account__tabcontent-form">
              <label for="passwrod">
                <span>
                  {{ $languages[getAppLanguage].account.currentPassword }}*
                </span>
                <span class="user-account__tabcontent-error">{{
                  error.password
                }}</span>
                <input
                  type="password"
                  v-model="user.password"
                  id="passwrod"
                  placeholder="************"
                  autocomplete="true"
                />
              </label>

              <label for="new-passwrod">
                <span>
                  {{ $languages[getAppLanguage].account.newPassword }}*
                </span>
                <span>
                  {{ $languages[getAppLanguage].account.passwordWarning }}
                </span>
                <span class="user-account__tabcontent-error">{{
                  error.newPassword
                }}</span>
                <input
                  type="password"
                  v-model="user.newPassword"
                  id="new-passwrod"
                  placeholder="************"
                  autocomplete="true"
                />
              </label>

              <label for="new-passwrod-repeat">
                <span>
                  {{ $languages[getAppLanguage].account.newPasswordRepeat }}*
                </span>
                <span class="user-account__tabcontent-error">{{
                  error.newPasswordRepeat
                }}</span>
                <input
                  type="password"
                  v-model="user.newPasswordRepeat"
                  id="new-passwrod-repeat"
                  placeholder="************"
                  autocomplete="true"
                />
              </label>
            </form>

            <div
              v-if="showPasswordUpdateFlow"
              class="user-account__tabcontent-item"
            >
              <span
                ><Button
                  @button-clicked="updateUserPassword()"
                  buttonWidth="100%"
                  :disabled="
                    !user.password ||
                      !user.newPassword ||
                      !user.newPasswordRepeat ||
                      user.password === user.newPassword ||
                      user.newPassword !== user.newPasswordRepeat ||
                      /.{8,}/.test(user.newPassword) !== true
                  "
                >
                  {{ $languages[getAppLanguage].account.updatePassword }}
                </Button>
              </span>
              <span
                class="user-account__link"
                @click="togglePasswordUpdateFlow"
              >
                {{ $languages[getAppLanguage].account.keepPassword }}
              </span>
            </div>

            <div>
              <hr />
              <h2>
                {{ $languages[getAppLanguage].account.subscription }}
                <Spinner
                  spinnerSize="20px"
                  v-if="getLoadingUserSubscriptionURL"
                />
              </h2>
              <div v-if="!this.user.cancelsAt && this.user.renewsAt">
                <h4>
                  {{
                    $languages[getAppLanguage].account
                      .scriptionScheduledForRenewal
                  }}
                </h4>
                <p>
                  <small>
                    {{ $languages[getAppLanguage].account.renewsOn }}
                    {{ this.user.renewsAt }}</small
                  >
                </p>
                <br />
              </div>
              <div v-if="this.user.cancelsAt">
                <h4 class="user-account__error">
                  {{
                    $languages[getAppLanguage].account.subscriptionEndingSoon
                  }}
                </h4>
                <p>
                  <small>
                    {{ $languages[getAppLanguage].account.endsOn }}
                    {{ this.user.cancelsAt }}
                  </small>
                </p>
                <br />
              </div>
              <br />

              <div v-if="getIsUserSubscribed">
                <Button
                  @button-clicked="openUserSubscriptionURL"
                  buttonWidth="100%"
                  :disabled="getLoadingUserSubscriptionURL"
                >
                  {{
                    getLoadingUserSubscriptionURL
                      ? $languages[getAppLanguage].account
                          .paymentsTextLoaderShort
                      : $languages[getAppLanguage].account.manageSubscription
                  }}
                </Button>
                <br />
              </div>

              <div v-else>
                <h4 class="user-account__error">
                  {{ $languages[getAppLanguage].account.noSubscription }}
                </h4>
                <span
                  class="user-account__link"
                  @click="toggleTab('subscription')"
                >
                  {{ $languages[getAppLanguage].account.selectSubscription }}
                </span>
              </div>
            </div>

            <hr />
            <h2>{{ $languages[getAppLanguage].account.dangerZone }}</h2>
            <br />

            <div
              v-if="!showAccountDeletionFlow"
              class="user-account__tabcontent-item"
            >
              <span>
                {{
                  $languages[getAppLanguage].account
                    .deleteMyFluentlyAccountPermanently
                }}
              </span>

              <span
                :class="[
                  'user-account__link',
                  {
                    'user-account__link--disabled':
                      getIsUserSubscribed && !getIsUserSubscribed.cancel_at
                  }
                ]"
                @click="toggleAccountDeletionFlow"
              >
                {{ $languages[getAppLanguage].account.deleteMyAccount }}
              </span>
            </div>

            <form v-else class="user-account__tabcontent-form">
              <label for="email">
                <span>
                  {{ $languages[getAppLanguage].account.currentEmail }}*
                </span>
                <span class="user-account__tabcontent-error">
                  {{ error.email }}
                </span>
                <input
                  type="email"
                  v-model="user.email"
                  id="email"
                  placeholder="Email address"
                />
              </label>

              <label for="passwrod">
                <span>
                  {{ $languages[getAppLanguage].account.yourPassword }}*
                </span>
                <span class="user-account__tabcontent-error">
                  {{ error.password }}
                </span>
                <input
                  type="password"
                  v-model="user.password"
                  id="passwrod"
                  placeholder="************"
                  autocomplete="true"
                />
              </label>
              <div>
                <br />
                <h3>
                  {{ $languages[getAppLanguage].account.sorryToSeeYouGo }}
                </h3>
                <br />
                <p>
                  <strong>
                    {{ $languages[getAppLanguage].account.areYouSure }}
                  </strong>
                </p>
                <br />
                <p
                  v-html="
                    $languages[getAppLanguage].account
                      .accountDeletionFinalWarning
                  "
                ></p>
                <br />
              </div>

              <label for="delete-account">
                <span>
                  {{ $languages[getAppLanguage].account.deleteConfirm }}
                </span>
                <input
                  onpaste="return false;"
                  ondrop="return false;"
                  autocomplete="off"
                  type="text"
                  v-model="user.deleteAccount"
                  id="delete-account"
                  :placeholder="
                    $languages[getAppLanguage].account.deleteConfirmPlaceholder
                  "
                />
              </label>
            </form>

            <small v-if="!showAccountDeletionFlow && getIsUserSubscribed">
              <br />
              <span
                v-html="$languages[getAppLanguage].account.deleteAccountWarning"
              ></span>
            </small>

            <br />

            <div
              v-if="showAccountDeletionFlow"
              class="user-account__tabcontent-item"
            >
              <span>
                <Button
                  @button-clicked="deleteUserAccount()"
                  buttonWidth="100%"
                  type="warning"
                  :disabled="
                    (getIsUserSubscribed && !getIsUserSubscribed.cancel_at) ||
                      !user.email ||
                      !user.email.includes('@') ||
                      !user.email.includes('.') ||
                      !user.password ||
                      !user.deleteAccount ||
                      user.deleteAccount !==
                        $languages[getAppLanguage].account
                          .deleteConfirmPlaceholder
                  "
                >
                  {{ $languages[getAppLanguage].account.deleteMyAccount }}
                </Button>
              </span>
              <span
                class="user-account__link"
                @click="toggleAccountDeletionFlow"
              >
                {{ $languages[getAppLanguage].account.keepAccount }}
              </span>
            </div>
          </section>

          <section
            v-if="activeTab === 'subscription'"
            :class="[
              'user-account__tabcontent',
              {
                'user-account__tabcontent--active': activeTab === 'subscription'
              }
            ]"
          >
            <h1>{{ $languages[getAppLanguage].account.subscription }}</h1>
            <br />
            <p>
              {{ $languages[getAppLanguage].account.noActiveSubscription }}
            </p>
            <br />
            <br />

            <section
              v-if="getLoadingCustomerSubscriptionDashboard"
              class="user-account__membership-selection user-account__membership-selection--loading"
            >
              <Spinner spinnerSize="60px" />
              <p>
                {{ $languages[getAppLanguage].account.paymentsTextLoader }}
              </p>
            </section>

            <section v-else class="user-account__membership-selections">
              <div
                v-for="(price, priceIndex) in prices"
                :key="price.id"
                class="user-account__membership-selection user-account__membership-selection--price"
              >
                <div
                  @click="selectMembership(price)"
                  :class="[
                    'user-account__membership-selection-box user-account__membership-selection-box--short',
                    {
                      'user-account__membership-selection-box--active':
                        user.priceId === price.id,
                      'user-account__membership-selection-box--first':
                        priceIndex === 0,
                      'user-account__membership-selection-box--last':
                        priceIndex === prices.length - 1
                    }
                  ]"
                >
                  <span
                    class="user-account__membership-selection-box-label"
                    v-if="price.data.metadata.deal"
                  >
                    {{ price.data.metadata.deal }}
                  </span>

                  <span>
                    <small>
                      {{ $languages[getAppLanguage].account.billed }}
                    </small>
                    <h4>
                      {{ price.data.description }}
                    </h4>
                    <br />
                    <h1>€{{ price.data.unit_amount / 100 }}</h1>
                  </span>
                  <br />
                  <span>
                    <strong> €{{ price.data.metadata.monthly_price }} </strong>
                    / {{ $languages[getAppLanguage].account.month }}
                  </span>
                </div>
              </div>
            </section>

            <br />
            <Button
              @button-clicked="openCustomerSubscriptionDashboard"
              buttonWidth="100%"
              :disabled="getLoadingCustomerSubscriptionDashboard"
            >
              {{
                getLoadingCustomerSubscriptionDashboard
                  ? $languages[getAppLanguage].account.paymentsTextLoaderShort
                  : $languages[getAppLanguage].account.differentInterval
              }}
            </Button>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Core
import { mapActions, mapGetters } from 'vuex'

// Components
import Button from '@/components/Button'
import Spinner from '@/components/Spinner'

export default {
  name: 'UserAccount',

  props: {
    tabActive: {
      type: String,
      required: true,
      default: 'account'
    }
  },

  data: () => ({
    activeTab: '',
    copyText: false,

    prices: [],
    isModalBlocked: false,

    showEmailUpdateFlow: false,
    showBillingEmailUpdateFlow: false,
    showPasswordUpdateFlow: false,
    showAccountDeletionFlow: false,

    checkCustomerStateInterval: null,

    user: {
      firstName: null,
      lastName: null,
      currentEmail: null,
      newEmail: null,
      password: null,
      newPassword: null,
      newPasswordRepeat: null,
      cancelSubscription: null,
      deleteAccount: null,
      priceId: null,
      membership: 'yearly',
      renewsAt: false,
      cancelsAt: false
    },

    error: { password: null }
  }),

  components: {
    Button,
    Spinner
  },

  computed: {
    ...mapGetters([
      'getAppLanguage',
      'getStripePrices',
      'getIsUserSubscribed',
      'getLoadingUserSubscriptionURL',
      'getLoadingCustomerSubscriptionDashboard'
    ])
  },

  watch: {
    tabActive() {
      this.activeTab = this.tabActive
      return this.tabActive
    },

    activeTab() {
      return this.activeTab
    },

    getIsUserSubscribed() {
      this.setModalLockedState()
      return this.getIsUserSubscribed
    },

    getStripePrices() {
      this.setPrices()
      return this.getStripePrices
    }
  },

  mounted() {
    return this.initializeUserAccount()
  },

  methods: {
    ...mapActions([
      'updateUserNameAction',
      'updateUserEmailAction',
      'updateUserPasswordAction',
      'deleteUserAccountAction',
      'resetNotification',
      'setNotification',
      'setIsUserSubscribed',
      'setLoadingUserSubscriptionURL',
      'setUserSubscriptionURL',
      'setStripePrices',
      'setLoadingCustomerSubscriptionDashboard',
      'setCustomerSubscriptionDashboard',
      'logoutAction'
    ]),

    /**
     *
     */
    initializeUserAccount() {
      this.setIsUserSubscribed(this.$user.uid)
      this.activeTab = this.tabActive
      this.prices = this.getStripePrices
      this.setPrices()
      this.setUser()
      this.setModalLockedState()
    },

    /**
     *
     */
    setModalLockedState() {
      if (!this.getIsUserSubscribed) this.isModalBlocked = true
      else this.isModalBlocked = false
    },

    /**
     *
     */
    setPrices() {
      this.prices = this.getStripePrices // Init prices
      this.checkForSubscriptionStatus()

      // Requierd for when user does not click on price
      return this.prices.map(price =>
        price.data.description === 'Yearly'
          ? (this.user.priceId = price.id)
          : (this.user.priceId = null)
      )
    },

    /**
     *
     */
    checkForSubscriptionStatus() {
      if (this.getIsUserSubscribed) {
        if (this.getIsUserSubscribed.current_period_end) {
          const date = new Date(1970, 0, 1) // Epoch
          const renewsAt =
            this.getIsUserSubscribed.current_period_end.seconds || null
          date.setSeconds(renewsAt)

          this.user.renewsAt = date
        }

        if (this.getIsUserSubscribed.cancel_at) {
          const date = new Date(1970, 0, 1) // Epoch
          const cancelsAt = this.getIsUserSubscribed.cancel_at.seconds || null
          date.setSeconds(cancelsAt)

          this.user.cancelsAt = date
        }

        return (this.user.priceId = this.getIsUserSubscribed.price.id)
      }
    },

    /**
     *
     */
    openUserSubscriptionURL() {
      this.setLoadingUserSubscriptionURL(true)
      return this.setUserSubscriptionURL()
    },

    /**
     *
     */
    openCustomerSubscriptionDashboard() {
      this.setLoadingCustomerSubscriptionDashboard(true)
      this.user.cancelRoute = this.$route.fullPath
      return this.setCustomerSubscriptionDashboard(this.user)
    },

    /**
     *
     */
    copyTextToClipboard(text) {
      const textarea = document.createElement('textarea')
      const timeToHideMessage = 1200

      this.copyText = !this.copyText

      setTimeout(() => {
        this.copyText = !this.copyText
      }, timeToHideMessage)

      textarea.value = text
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    },

    /**
     *
     */
    selectMembership(price) {
      this.user.priceId = price.id
      this.user.membership = price.data.description
    },

    /**
     *
     */
    notifyUser(message, autoHide = true, type = 'success') {
      this.resetNotification()
      this.setNotification({
        autoHide,
        type,
        state: true,
        message: message
      })

      if (type === 'success') this.isModalBlocked = false

      return this.setUser()
    },

    /**
     *
     */
    setUser() {
      clearInterval(this.checkCustomerStateInterval)
      this.user.uid = this.$user.uid
      this.user.firstName = this.$user.firstName
      this.user.lastName = this.$user.lastName
      this.user.currentEmail = this.$user.email
    },

    /**
     *
     */
    updateUserName() {
      this.$user.firstName = this.user.firstName
      this.$user.lastName = this.user.lastName

      this.user = this.$user

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`MA updated user name`)

      return this.updateUserNameAction(this.user)
    },

    /**
     *
     */
    updateUserEmail() {
      if (this.user.newEmail && this.user.password) {
        this.user.user = this.$user

        this.updateUserEmailAction(this.user)
        this.showEmailUpdateFlow = false
      } else {
        if (!this.user.password)
          this.error.password =
            'Your password is required to update your account information.'

        if (!this.user.newEmail)
          this.error.newEmail =
            'Your new email is required to update your account information.'
      }

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`MA updated email`)
    },

    /**
     *
     */
    updateUserPassword() {
      if (
        this.user.password &&
        this.user.newPassword &&
        this.user.newPasswordRepeat
      ) {
        this.updateUserPasswordAction(this.user)
        this.showPasswordUpdateFlow = false
      } else {
        if (!this.user.password)
          this.error.password =
            'Your password is required to update your account information.'

        if (!this.user.newPassword)
          this.error.newPassword =
            'Your new password is required to update your account information.'

        if (!this.user.newPasswordRepeat)
          this.error.newPasswordRepeat =
            'Your new password verification is required to update your account information.'
      }

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`MA updated password`)
    },

    /**
     *
     */
    deleteUserAccount() {
      if (this.user.email && this.user.password) {
        this.deleteUserAccountAction(this.user)
        this.showAccountDeletionFlow = false
      }

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`MA deleted account`)
    },

    /**
     *
     */
    toggleEmailUpdateFlow() {
      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(
            `MA toggled update email ${this.showEmailUpdateFlow ? 'on' : 'off'}`
          )

      return (this.showEmailUpdateFlow = !this.showEmailUpdateFlow)
    },

    /**
     *
     */
    toggleBillingEmailUpdateFlow() {
      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(
            `MA toggled update billing email ${
              this.showBillingEmailUpdateFlow ? 'on' : 'off'
            }`
          )

      return (this.showBillingEmailUpdateFlow = !this
        .showBillingEmailUpdateFlow)
    },

    /**
     *
     */
    togglePasswordUpdateFlow() {
      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(
            `MA toggled change password ${
              this.showPasswordUpdateFlow ? 'on' : 'off'
            }`
          )

      return (this.showPasswordUpdateFlow = !this.showPasswordUpdateFlow)
    },

    /**
     *
     */
    toggleAccountDeletionFlow() {
      if (this.$amplitudeOn)
        this.$amplitude
          .getInstance()
          .logEvent(
            `MA toggled account deletion flow ${
              this.showAccountDeletionFlow ? 'on' : 'off'
            }`
          )

      return (this.showAccountDeletionFlow = !this.showAccountDeletionFlow)
    },

    /**
     *
     */
    toggleTab(tabName) {
      this.activeTab = tabName
      this.setStripePrices() // Ensure that prices are available on each tab change

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`MA clicked on ${tabName} tab`)
    },

    /**
     *
     */
    logout() {
      this.logoutAction()

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`logged out`)
    },

    /**
     *
     */
    isRTL() {
      return this.getAppLanguage === 'ar'
    }
  }
}
</script>

<style lang="scss" scoped>
.user-account__mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    var(--color-brand-darkest-rgb),
    var(--opacity-overlay)
  );
  transition: opacity var(--transition-default);
  z-index: 999999;
}

.user-account {
  display: flex;
  height: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform var(--transition-default);
}

.user-account__success {
  color: var(--color-success);
}

.user-account__error {
  color: var(--color-error);
}

.user-account__brand-line {
  width: calc(100% - var(--size-unit-4));
  max-width: 620px;
  height: var(--size-unit-half);
  background: linear-gradient(
    90deg,
    var(--color-main-dark) 0%,
    var(--color-main-light) 100%
  );
}

.user-account__container {
  overflow: scroll;
  position: relative;
  width: calc(100% - var(--size-unit-4));
  max-width: 620px;
  min-height: 420px;
  background-color: var(--color-light);
  color: var(--color-dark);
  padding-bottom: var(--size-unit-4);

  @include screen-medium {
    overflow: hidden;
    height: 620px;
  }

  @include screen-large {
    overflow: hidden;
    height: 680px;
  }
}

.user-account__tabs {
  display: flex;
  overflow: hidden;
  width: calc(100% - var(--size-unit-5));

  &--no-close {
    width: 100%;
  }
}

.user-account__tab {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: var(--size-unit-5);
  background-color: var(--color-brand-darker);
  color: var(--color-brand-light);
  border-right: 1px solid var(--color-brand-dark);

  &:last-of-type {
    border-right: none;
  }

  &:hover {
    opacity: var(--opacity-hover);
    background-color: var(--color-brand-darkest);
  }

  &--active {
    background-color: var(--color-light);
    color: var(--color-dark);

    &:hover {
      background-color: var(--color-light);
      opacity: 1;
    }
  }
}

.user-account__tabcontent {
  display: none;
  padding: var(--size-unit-2);
  border-top: none;
  overflow: scroll;
  height: calc(100% - var(--size-unit));

  &--active {
    display: block;
  }

  @include screen-small {
    padding: var(--size-unit-4);
  }
}

.user-account__tabcontent-error {
  color: var(--color-error);
}

.user-account__item-capitalized {
  &::first-letter {
    text-transform: capitalize;
  }
}

.user-account__tabcontent-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    margin: var(--size-unit-half) 0;
  }

  @include screen-medium {
    flex-direction: row;
    align-items: center;

    span {
      margin: 0;

      &:nth-of-type(2) {
        text-align: center;
        min-width: 240px;
      }

      &:last-of-type {
        text-align: right;
      }

      &:first-of-type {
        text-align: left;
      }
    }
  }
}

.user-account__subscription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 420px;
}

.user-account__link {
  text-decoration: underline;
  color: var(--color-main);
  cursor: pointer;

  &--fixed {
    position: absolute;
    right: 0;
    top: 0;
  }

  &--disabled {
    pointer-events: none;
    opacity: var(--opacity-disabled);
  }
}

.user-account__tabcontent-form {
  position: relative;
  margin-bottom: var(--size-unit-2);

  span {
    &::first-letter {
      text-transform: capitalize;
    }
  }

  label {
    display: inline-flex;
    flex-direction: column;
    margin: var(--size-unit) 0;
    width: 100%;

    span {
      margin-bottom: var(--size-unit);
    }
  }

  label[for='firstName'],
  label[for='lastName'] {
    @include screen-medium {
      width: calc(50% - var(--size-unit));
    }
  }

  label[for='firstName'] {
    margin-right: var(--size-unit-2);
  }

  input {
    outline: none;
    border-radius: var(--size-unit-half);
    border: 1px solid var(--color-theme-lighter);
    outline: none;
    border-radius: var(--size-unit-half);
    height: var(--size-unit-5);
    padding: 0 var(--size-unit-2);
    background-color: var(--color-light);
    color: var(--color-dark);
    transition: border-color var(--transition-default);

    &:focus {
      border-color: var(--color-main);
    }

    &::placeholder {
      color: rgba(var(--color-brand-darkest-rgb), var(--opacity-disabled));
    }
  }

  input[type='email'],
  input[type='password'] {
    display: flex;
    width: 100%;
  }
}

.user-account__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--size-unit-5);
  height: var(--size-unit-5);
  cursor: pointer;
  transition: color var(--transition-default);
  background-color: var(--color-main);
  color: var(--color-light);
  transition: opacity var(--transition-default);
  z-index: 999;

  &:hover {
    opacity: var(--opacity-hover);
  }
}

.user-account__cancel-subscription {
  color: var(--color-error);
  text-decoration: underline;
  cursor: pointer;
}

.user-account__membership-selections {
  @include selections;
}

.user-account__membership-selection {
  @include selection;

  &--loading {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      margin: 0 0 40px 40px;
    }
  }
}

.user-account__membership-selection-box {
  @include selection-box;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.user-account-enter {
  opacity: 0;
}

.user-account-leave-active {
  opacity: 0;
}

.user-account-enter .user-account__wrapper,
.user-account-leave-active .user-account__wrapper {
  transform: scale(1.1);
}
</style>
