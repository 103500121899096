<template>
  <div :class="['service', { [`service--${serviceType}`]: serviceType }]">
    <section v-if="serviceType === 'micro-translator'">
      <main class="service__main">
        <MicroTranslator />
      </main>

      <footer
        class="service__footer service__footer--secondary service__footer--micro-translator"
      ></footer>
    </section>

    <!-- Dictionaries -->
    <section v-else-if="serviceType === 'dictionaries'">
      <header class="service__header"></header>

      <main class="service__main service__main--dictionaries">
        <!-- @ TODO - add when ready -->
        <!-- <Dictionaries /> -->
      </main>

      <footer class="service__footer service__footer--secondary"></footer>
    </section>

    <!-- Compare -->
    <section v-else-if="serviceType === 'compare'">
      <header class="service__header"></header>

      <main class="service__main">
        The Compare feature coming soon...
      </main>

      <footer class="service__footer service__footer--secondary"></footer>
    </section>

    <!-- Notes -->
    <section v-else-if="serviceType === 'notes'">
      <header class="service__header"></header>

      <main class="service__main">
        The Notes feature coming soon...
      </main>

      <footer class="service__footer service__footer--secondary"></footer>
    </section>
  </div>
</template>

<script>
// Components
import MicroTranslator from '@/components/MicroTranslator'

// @ TODO - add when ready
// import Dictionaries from '@/components/Dictionaries'

export default {
  name: 'Service',

  props: {
    serviceType: {
      type: String,
      default: 'main'
    }
  },

  components: {
    MicroTranslator

    // @ TODO - add when ready
    // Dictionaries
  }
}
</script>

<style lang="scss" scoped>
.service {
  width: 100%;
  background-color: var(--color-theme-lighter);
  caret-color: var(--color-main);
}

.service__header {
  display: flex;
  align-items: flex-start;
  padding: 0 var(--size-unit);
  height: $editor-header-height;
}

.service__main {
  background-color: inherit;
  outline: none;

  // @ TODO - Remove when adding actual dictionaries element
  &--dictionaries {
    margin: 0 auto;
    width: 580px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('../../assets/dictionary.jpg');
  }

  &--micro-translator {
    display: flex;
    justify-content: center;
    align-items: center;

    @include screen-medium {
      display: initial;
    }
  }
}

.service__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $editor-footer-height;
  padding: 0 var(--size-unit-3);
  background-color: var(--color-theme-lighter);
  color: var(--color-dark);
  font-size: var(--font-size-s);
  z-index: 9;

  @include screen-large {
    position: fixed;
    bottom: 0;
    width: 50%;
  }

  &--micro-translator {
    display: none;

    @include screen-medium {
      display: flex;
    }
  }
}
</style>
