var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-animation"},_vm._l((_vm.lines),function(line,lineIndex){return _c('div',{key:`${Math.random()}${lineIndex}`,class:[
      'text-animation-line',
      { 'text-animation-line--inverted': _vm.inverted }
    ],style:(`
        border-radius: ${_vm.lineRadius};
        margin: ${_vm.lineMargin};
        width: ${_vm.lineWidth};
        height: ${_vm.lineHeight};
        animation-duration: ${_vm.lineAnimationDuration}
      `)})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }