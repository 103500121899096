<template>
  <div>
    <div
      v-if="getMobileMenu"
      @click="setMobileMenu(false)"
      class="document-header-mobile-closer"
    ></div>
    <header
      :class="[
        'document-header-mobile',
        'document-header-mobile--dark-mode',
        { 'document-header-mobile--open': getMobileMenu }
      ]"
    >
      <div class="document-header-mobile__apps-buttons">
        <Button
          @button-clicked="closeExpandedView"
          :type="getCurrentDocument.expanded ? 'app-active' : 'app-secondary'"
          buttonWidth="40px"
          buttonHeight="40px"
          buttonMargin="0"
        >
          <i class="icon-document"></i>
        </Button>

        <Button
          :type="
            !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'translator'
              ? 'app-active'
              : 'app-secondary'
          "
          @button-clicked="
            !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'translator'
              ? closeExpandedView()
              : showTranslationsSrvice()
          "
          buttonWidth="40px"
          buttonHeight="40px"
          buttonMargin="0"
        >
          <i class="icon-translate-main"></i>
        </Button>

        <Button
          :type="
            !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'micro-translator'
              ? 'app-active'
              : 'app-secondary'
          "
          @button-clicked="
            !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'micro-translator'
              ? closeExpandedView()
              : showMicroTranslationsSrvice()
          "
          buttonWidth="40px"
          buttonHeight="40px"
          buttonMargin="0"
        >
          <i class="icon-translate-add"></i>
        </Button>

        <Button
          :type="$getGrammar() ? 'app-active' : 'app-secondary'"
          @button-clicked="toggleGrammar()"
          buttonWidth="40px"
          buttonHeight="40px"
          buttonMargin="0"
          buttonPadding="0"
        >
          <i class="icon-grammar"></i>
        </Button>
      </div>

      <div class="document-header-mobile__app-title">
        <p>
          {{
            !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'translator'
              ? $languages[getAppLanguage].document.header.documentTranslator
              : !getCurrentDocument.expanded &&
                getCurrentDocument.activeService === 'micro-translator'
              ? $languages[getAppLanguage].document.header.paragraphTranslator
              : getCurrentDocument.expanded
              ? $languages[getAppLanguage].document.header.fullDocument
              : ''
          }}
        </p>

        <p>
          {{
            $getGrammar()
              ? `(${$languages[getAppLanguage].document.header.grammarStatus.on})`
              : `(${$languages[getAppLanguage].document.header.grammarStatus.off})`
          }}
        </p>

        <Button
          v-if="!getCurrentDocument.publicUid"
          @button-clicked="togglePublishingDocument(true)"
          buttonWidth="100%"
          buttonMargin="16px 0 0 0"
          type="tertiary"
        >
          Publish
        </Button>

        <div
          v-else
          v-click-outside:pointerdown="hidePublishDropdown"
          class="document-header-mobile__publish-dropdown-wrapper"
        >
          <Button
            @button-clicked="togglePublishDropdown"
            type="success"
            buttonWidth="100%"
            buttonMargin="16px 0 0 0"
            icon="caret-down"
          >
            Published
          </Button>

          <div
            v-if="showPublishDropdown"
            class="document-header-mobile__publish-dropdown"
          >
            <span
              @click="
                copyTextToClipboard(getPublicUrl(getCurrentDocument.publicUid))
              "
              class="document-header-mobile__publish-dropdown-item"
            >
              {{
                showCopiedPublicUrlNotice
                  ? $languages[getAppLanguage].document.header.copied
                  : $languages[getAppLanguage].document.header.copyLiveLink
              }}
            </span>
            <a
              :href="`/live/${getCurrentDocument.publicUid}`"
              target="_blank"
              rel="noopener noreferrer"
              class="document-header-mobile__publish-dropdown-item"
            >
              {{ $languages[getAppLanguage].document.header.goToLiveLink }}
            </a>
            <span
              @click="togglePublishingDocument(false)"
              class="document-header-mobile__publish-dropdown-item document-header-mobile__publish-dropdown-item--warning"
            >
              {{ $languages[getAppLanguage].document.header.unpublish }}
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="
          !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'translator'
        "
        class="document-header-mobile__divider"
      ></div>

      <Dropdown
        v-if="
          !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'translator'
        "
        @dropdown-searched-option-clicked="
          parseAndEmitTranslationSourceLanguageUpdatedOnSearch
        "
        :label="labelForSourceTranslationDropdown()"
        :titlePrefix="
          getCurrentDocument.translationSourceLanguageManualMode
            ? getCurrentDocument.translationSourceLanguage.prefix
            : getLanguageFlagFromDetectedLanguage()
        "
        :title="
          getCurrentDocument.translationSourceLanguageManualMode
            ? `${getCurrentDocument.translationSourceLanguage.language} ${
                getCurrentDocument.translationSourceLanguage.variation
                  ? getCurrentDocument.translationSourceLanguage.variation
                  : ''
              }`
            : getLanguageTitleFromDetectedLanguage()
        "
        dropdownMargin="16px 0 0 0"
        dropdownWidth="100%"
        outlined
        :options="extractedLanguagesFromTranslationLanguages()"
        :showCaret="this.getCurrentDocument.text ? true : false"
      >
        <div
          slot="dropdown-options"
          class="dropdown__option"
          v-for="language in translationLanguages"
          :key="language.name"
          @click="getTranslationSourceLanguageCode(language)"
        >
          {{ language.prefix }} {{ language.language }}
          {{ language.variation ? `(${language.variation})` : '' }}
          <i
            v-if="translationSourceLanguage.code === language.code"
            class="icon-check"
          ></i>
        </div>
      </Dropdown>

      <Button
        v-if="
          !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'translator'
        "
        type="secondary"
        @button-clicked="swapLanguage"
        buttonWidth="40px"
        buttonHeight="40px"
        buttonMargin="8px 0 0 0"
        buttonPadding="0"
        :disabled="
          getCurrentDocument.translationSourceLanguage.code === '' ||
            getCurrentDocument.translationSourceLanguage.code ===
              getCurrentDocument.translationLanguage.code
        "
      >
        <i class="icon-swap"></i>
      </Button>

      <Dropdown
        v-if="
          !getCurrentDocument.expanded &&
            getCurrentDocument.activeService === 'translator'
        "
        @dropdown-searched-option-clicked="emitTranslationTragetLanguageUpdated"
        :label="$languages[getAppLanguage].document.header.translateInto"
        :titlePrefix="
          getCurrentDocument.translationLanguage
            ? getCurrentDocument.translationLanguage.prefix
            : translationTargetLanguage.prefix
        "
        :title="
          `${
            getCurrentDocument.translationLanguage
              ? getCurrentDocument.translationLanguage.language
              : translationTargetLanguage.language
          } ${
            getCurrentDocument.translationLanguage
              ? getCurrentDocument.translationLanguage.variation
              : translationTargetLanguage.variation
              ? translationTargetLanguage.variation
              : ''
          }`
        "
        dropdownMargin="8px 0 0 0"
        dropdownWidth="100%"
        :options="extractedLanguagesFromTranslationLanguages()"
        :outlined="getCurrentDocument.activeService === 'translator'"
      >
        <div
          slot="dropdown-options"
          class="dropdown__option"
          v-for="language in translationLanguages"
          :key="language.name"
          @click="getTranslationTargetLanguageCode(language)"
        >
          {{ language.prefix }} {{ language.language }}
          {{ language.variation ? `(${language.variation})` : '' }}
          <i
            v-if="translationTargetLanguage.code === language.code"
            class="icon-check"
          ></i>
        </div>
      </Dropdown>
    </header>
  </div>
</template>

<script>
// Store data
import { mapGetters } from 'vuex'
import documentHeader from '@/mixins/documentHeader'

export default {
  name: 'DocumentHeaderMobile',

  mixins: [documentHeader],

  computed: {
    ...mapGetters(['getAppLanguage'])
  }
}
</script>

<style lang="scss" scoped>
.document-header-mobile {
  display: none;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: var(--size-unit-6);
  right: var(--size-unit-5);
  border-radius: var(--radius-default);
  width: 228px;
  padding: var(--size-unit-2);
  background-color: var(--color-brand-darkest);
  z-index: 999999;

  &--open {
    display: flex;
  }

  .icon-swap {
    transform: rotate(90deg);
  }

  @include screen-medium {
    display: none;
  }
}

.document-header-mobile__section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--full-width {
    width: 100%;
  }
}

.document-header-mobile-closer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
}

.document-header-mobile__apps-buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.document-header-mobile__app-title {
  width: 100%;
  align-self: flex-start;
  padding: var(--size-unit-2) 0 0;
  color: var(--color-brand-lightest);
}

.document-header-mobile__close-button {
  background-color: var(--color-theme-lighter);
  color: var(--color-dark);

  @include screen-medium {
    margin-top: 112px;
  }

  @include screen-large {
    background-color: var(--color-theme-lightest);
  }
}

.document-header-mobile__divider {
  width: 100%;
  padding-bottom: var(--size-unit-2);
  border-bottom: 1px solid var(--color-theme-lightest);
}

.document-header-mobile__publish-dropdown-wrapper {
  width: 100%;
  position: relative;
}

.document-header-mobile__publish-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(#{$header-height} + var(--size-unit));
  right: 0;
  width: 100%;
  border-radius: var(--size-unit-half);
  padding: var(--size-unit) 0;
  font-size: var(--font-size-xs);
  background-color: var(--color-brand-darker);
  z-index: 9999;
}

.document-header-mobile__publish-dropdown-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: calc(var(--size-unit-2) * 2);
  padding: 0 var(--size-unit-2);
  font-size: var(--font-size-s);
  color: var(--color-brand-lightest);
  cursor: pointer;

  &:hover {
    background-color: var(--color-main);
    color: var(--color-light);
  }

  &--warning {
    &:hover {
      background-color: var(--color-warning);
      color: var(--color-brand-darkest);
    }
  }
}
</style>
