var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeTabDropdown),expression:"closeTabDropdown"}],staticClass:"document-tabs"},[(_vm.$user.uid && _vm.getIsLoadingDocuments)?_c('DocumentLoader',{attrs:{"single":_vm.isDocumentLoaderSingle}}):_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$user),expression:"$user"}],ref:"tabs",staticClass:"document-tabs-tabs"},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('div',{key:tabIndex,ref:`tab-${tab.uid}`,refInFor:true,staticClass:"document-tabs__tab",attrs:{"id":tab.uid},on:{"click":function($event){$event.stopPropagation();return _vm.activateTab($event, tab)}}},[(tab.public)?_c('i',{staticClass:"icon-dot document-tabs__tab-public-dot"}):_vm._e(),_c('i',{staticClass:"icon-document"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(tab.title),expression:"tab.title"}],ref:`title-${tab.uid}`,refInFor:true,staticClass:"document-tabs__tab-title",attrs:{"type":"text","placeholder":tab.title ||
              _vm.$languages[_vm.getAppLanguage].document.tabs.untitledDocument},domProps:{"value":(tab.title)},on:{"click":_vm.triggerTitleUpdate,"blur":function($event){return _vm.documentTitleUpdated(tab.title)},"keydown":function($event){return _vm.saveTitleOnEnter($event, tab.title)},"input":function($event){if($event.target.composing)return;_vm.$set(tab, "title", $event.target.value)}}}),_c('span',{staticClass:"document-tabs__tab-dropdown-trigger",on:{"click":function($event){return _vm.toggleTabDropdown($event, tab)}}},[_c('i',{staticClass:"icon-caret-down"})])])}),0),(_vm.$user && !_vm.getIsLoadingDocuments && _vm.getIsUserSubscribed)?_c('div',{ref:"addDocumentButton",staticClass:"document-tabs__add",attrs:{"id":"click-add-document-button"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleTabDropdown($event)}}},[_c('i',{staticClass:"icon-add"})]):_vm._e()],1),(_vm.isAddButton)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeTabDropdown),expression:"closeTabDropdown"}],ref:"dropdown",class:[
      'document-tabs__tab-dropdown-options',
      'document-tabs__tab-dropdown-options-add',
      {
        'document-tabs__tab-dropdown-options-add--overflowing': _vm.areTabsOverflowing,
        'document-tabs__tab-dropdown-options-add--first': !_vm.tabs.length
      }
    ]},[_c('span',{on:{"click":_vm.addDocument}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.tabs.add.new)+" ")]),_c('span',{on:{"click":_vm.openDocumentOverview}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.tabs.add.open)+" ")])]):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeTabDropdown),expression:"closeTabDropdown"}],ref:"dropdown",staticClass:"document-tabs__tab-dropdown-options"},[_c('span',{on:{"click":_vm.updateTitle}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.tabs.settings.rename)+" ")]),_c('span',{on:{"click":_vm.copyDocument}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.tabs.settings.copy)+" ")]),_c('span',{on:{"click":_vm.closeDocument}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.tabs.settings.close)+" ")]),_c('span',{staticClass:"document-tabs__tab-dropdown-option document-tabs__tab-dropdown-option--delete",on:{"click":_vm.deleteDocument}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.tabs.settings.delete)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }