<template>

  <div class="document-layout">

    <Header
      v-if="getCurrentDocument"
      :tabs="getOpenDocuments.filter(tab => tab.open)"
    />

    <DocumentHeaderMobile v-if="getCurrentDocument" />

    <Document v-if="getCurrentDocument" />

    <section
      v-if="!getOpenDocuments.length"
      :class="[
        'document-layout__first-document-wrapper',
        {
          'document-layout__first-document-wrapper--rtl':
            getAppLanguage === 'ar'
        }
      ]"
    >

      <div class="document-layout__first-document">

        <h2>
           {{ $languages[getAppLanguage].document.empty.hi }} {{ $user.firstName
          }},
        </h2>

        <br />

        <div v-if="getIsUserSubscribed">

          <p> {{ $languages[getAppLanguage].document.empty.noDocuments }} </p>

          <Button buttonMargin="40px 0" @button-clicked="addNewDocument()">
             {{ $languages[getAppLanguage].document.empty.createFirstDocument }}

          </Button>

        </div>

        <div v-else>

          <h4> {{ $languages[getAppLanguage].account.noSubscription }} </h4>

        </div>

        <hr />

        <p v-html="$languages[getAppLanguage].document.empty.feedback"></p>

      </div>

    </section>

    <!-- Global App updates -->

    <Notification
      v-show="getAppVersion.notification.state"
      :message="getAppVersion.notification.message"
      :hideCloseButton="true"
      @action="setAppVersionLocally"
      type="success"
    />

  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// Components
import Header from '@/components/Header'
import DocumentHeaderMobile from '@/components/document/DocumentHeaderMobile'
import Document from '@/components/document/Document'
import Notification from '@/components/Notification'
import Button from '@/components/Button'

// Helperslogin
import findDocument from '@/helpers/findDocument'
import detectBrowserLanguage from '@/helpers/detectBrowserLanguage'

export default {
  name: 'DocumentLayout',
  components: {
    Header,
    DocumentHeaderMobile,
    Document,
    Notification,
    Button
  },

  computed: {
    ...mapGetters([
      'getAppVersion',
      'getAppLanguage',
      'getCurrentDocument',
      'getOpenDocuments',
      'getDetectedLanguage',
      'getIsUserSubscribed'
    ]),

    currentDocumentUid() {
      return this.$route.params.id
    },

    currentDocument() {
      return findDocument(this.getOpenDocuments, this.currentDocumentUid)
    }
  },

  mounted() {
    if (this.$user.uid) this.setAppVersionWatcher()

    /**
     *
     */
    this.$eventBus.$on(
      'document-translation-source-language-manual-mode-updated',
      translationSourceLanguageManualMode => {
        this.currentDocument.translationSourceLanguageManualMode = translationSourceLanguageManualMode
        this.saveDocument()
      }
    )

    /**
     *
     */
    this.$eventBus.$on('document-publishing-clicked', publish => {
      this.currentDocument.public = publish

      if (this.currentDocument.public)
        this.addPublicDocumentAction(this.currentDocument)
      // Adding cuttent document to public documents
      else {
        this.deletePublicDocumentAction(this.currentDocument) // Removeing public document
        this.currentDocument.publicUid = '' // Removing publicUid from private document
      }

      this.saveDocument()
    })

    /**
     *
     */
    this.$eventBus.$on('public-document-id-ready', uid => {
      this.currentDocument.publicUid = uid
      this.saveDocument()
    })

    /**
     *
     */
    this.$eventBus.$on(
      'document-translation-source-language-updated',
      translationSourceLanguage => {
        this.currentDocument.translationSourceLanguage = translationSourceLanguage
        this.saveDocument()
      }
    )

    /**
     *
     */
    this.$eventBus.$on(
      'document-translation-target-language-updated',
      translationLanguage => {
        this.currentDocument.translationLanguage = translationLanguage

        // Title
        this.translateTitleAction({
          title: this.currentDocument.title,
          sourceLanguageCode:
            this.currentDocument.translationSourceLanguage.code ||
            detectBrowserLanguage().code,
          targetLanguageCode:
            this.currentDocument.translationLanguage.code ||
            detectBrowserLanguage().code
        })

        // Text
        this.translateTextAction({
          text: this.currentDocument.text,
          sourceLanguageCode:
            this.currentDocument.translationSourceLanguage.code ||
            detectBrowserLanguage().code,
          targetLanguageCode:
            this.currentDocument.translationLanguage.code ||
            detectBrowserLanguage().code,
          event: 'document-translation-updated'
        })

        this.saveDocument()
      }
    )

    /**
     *
     */
    this.$eventBus.$on('document-expand-action-clicked', valueAssigned => {
      this.currentDocument.expanded = valueAssigned
      this.saveDocument()
    })

    /**
     *
     */
    this.$eventBus.$on('micro-translations-clicked', () => {
      this.currentDocument.activeService = 'micro-translator'
      this.saveDocument()
    })

    /**
     *
     */
    this.$eventBus.$on('document-translated-title-updated', translatedTitle => {
      this.currentDocument.translatedTitle = translatedTitle
      this.saveDocument()
    })

    /**
     *
     */
    this.$eventBus.$on('document-translation-updated', translation => {
      this.currentDocument.translation = translation
      this.saveDocument()
    })

    /**
     *
     */
    this.$eventBus.$on('document-translation-with-loader-requested', () => {
      this.setIsLoadingTranslation(true)
    })

    /**
     *
     */
    this.$eventBus.$on('editor-is-serif', serif => {
      this.currentDocument.serif = serif

      this.saveDocument()
    })
  },

  methods: {
    ...mapActions([
      'setAppVersionWatcher',
      'setAppVersionLocally',

      'setCurrentDocument',

      'setIsLoadingTranslation',
      'translateTitleAction',
      'translateTextAction',

      // Public documents
      'addPublicDocumentAction',
      'deletePublicDocumentAction',

      // Private documents
      'addDocumentAction'
    ]),

    /**
     *
     */
    addNewDocument() {
      this.addDocumentAction({
        project: '',
        title: '',
        translatedTitle: '',
        source: {},
        text: '',
        translation: '',
        translationSourceLanguageManualMode: false,
        translationSourceLanguage: {
          code: '',
          prefix: '',
          language: ''
        },
        translationLanguage: this.$detectBrowserLanguage(),
        expanded: true,
        activeService: '',
        serif: false,
        open: true,
        public: false,
        publicUid: ''
      })

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`created first document`)
    },

    /**
     *
     */
    saveDocument() {
      if (this.$user.uid && this.currentDocument) {
        return this.setCurrentDocument(this.currentDocument)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// To guarantee header fixability and the reste scrollable
// Be careful, this affects the entire layout
.document-layout {
  position: absolute;
  top: $header-height;
  right: 0;
  bottom: 0;
  left: 0;
}

.document-layout__first-document-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-theme-lightest);
  padding: var(--size-unit-5) var(--size-unit-2) var(--size-unit-10);

  &--rtl {
    @include rtl;
  }
}

.document-layout__first-document {
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-dark);
}
</style>

