<template>
  <transition name="notification-modal">
    <div
      @click="$emit('close')"
      :class="[
        'notification-modal__mask',
        { 'notification-modal__mask--light': light }
      ]"
    >
      <div class="notification-modal__wrapper">
        <div class="notification-modal__brand-line"></div>
        <div class="notification-modal__container">
          <i
            v-if="!hideCloseButton"
            @click="$emit('close')"
            class="notification-modal__close-button icon-close"
          ></i>

          <div class="notification-modal__content">
            <header class="notification-modal__header">
              <h2 class="notification-modal__heading">{{ heading }}</h2>
            </header>

            <div class="notification-modal__body">
              <p v-html="body"></p>

              <Button
                v-if="cta"
                @button-clicked="buttonClicked"
                buttonMargin="40px 0 0 0"
                >{{ cta }}</Button
              >
            </div>
          </div>
        </div>

        <footer class="notification-modal__footer">
          <img
            v-if="footerImagePath"
            :src="findFooterImage(footerImagePath)"
            class="notification-modal__footer-image"
            :alt="`Close notifications footer image`"
          />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
// Components
import Button from '@/components/Button'

export default {
  name: 'NotificationModal',

  props: {
    heading: {
      type: String,
      required: true
    },

    body: {
      type: String,
      required: true
    },

    cta: {
      type: String,
      required: false
    },

    footerImagePath: {
      type: String,
      required: false
    },

    hideCloseButton: {
      type: Boolean,
      required: false
    },

    light: {
      type: Boolean,
      required: false
    }
  },

  components: {
    Button
  },

  methods: {
    /**
     *
     */
    findFooterImage(image) {
      return require(`../assets/${image}`)
    },

    /**
     *
     */
    buttonClicked() {
      this.$emit('button-clicked')
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-modal__mask {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    var(--color-brand-darkest-rgb),
    var(--opacity-overlay)
  );
  transition: opacity var(--transition-default);

  &--light {
    background-color: rgba(
      var(--color-brand-darkest-rgb),
      var(--opacity-overlay-light)
    );
  }
}

.notification-modal__wrapper {
  display: flex;
  height: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform var(--transition-default);
}

.notification-modal__brand-line {
  width: calc(100% - var(--size-unit-4));
  max-width: 620px;
  height: var(--size-unit-half);
  background: linear-gradient(
    90deg,
    var(--color-main-dark) 0%,
    var(--color-main-light) 100%
  );
}

.notification-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(100% - var(--size-unit-4));
  max-width: 620px;
  padding: var(--size-unit-4);
  background-color: var(--color-light);
  color: var(--color-dark);
}

.notification-modal__close-button {
  position: absolute;
  top: var(--size-unit-3);
  right: var(--size-unit-3);
  cursor: pointer;
  transition: color var(--transition-default);

  &:hover {
    color: var(--color-main);
  }
}

.notification-modal__content {
  width: 100%;
}

.notification-modal__heading {
  margin-top: 0;
}

.notification-modal__body {
  margin: var(--size-unit-3) 0 0;
  font-size: var(--font-size-m);

  p {
    margin: 0;
  }
}

.notification-modal__footer {
  width: calc(100% - var(--size-unit-4));
  max-width: 620px;
}

.notification-modal__footer-image {
  width: 100%;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.notification-modal-enter {
  opacity: 0;
}

.notification-modal-leave-active {
  opacity: 0;
}

.notification-modal-enter .notification-modal__wrapper,
.notification-modal-leave-active .notification-modal__wrapper {
  transform: scale(1.1);
}
</style>
