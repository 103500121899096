<template>
  <div class="text-animation">
    <div
      v-for="(line, lineIndex) in lines"
      :key="`${Math.random()}${lineIndex}`"
      :class="[
        'text-animation-line',
        { 'text-animation-line--inverted': inverted }
      ]"
      :style="
        `
          border-radius: ${lineRadius};
          margin: ${lineMargin};
          width: ${lineWidth};
          height: ${lineHeight};
          animation-duration: ${lineAnimationDuration}
        `
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: 'TextAnimation',

  props: {
    lines: {
      type: Number,
      required: false,
      default: 7
    },

    lineMargin: {
      type: String,
      default: '4px',
      required: false
    },

    lineRadius: {
      type: String,
      default: '0',
      required: false
    },

    lineWidth: {
      type: String,
      default: '100%',
      required: false
    },

    lineHeight: {
      type: String,
      default: '12px',
      required: false
    },

    lineAnimationDuration: {
      type: String,
      default: '1s',
      required: false
    },

    inverted: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.text-animation {
  width: 100%;
  margin-bottom: var(--size-unit-2);
}

.text-animation-line {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    var(--color-theme-lightest) 8%,
    var(--color-theme-lighter) 18%,
    var(--color-theme-lightest) 33%
  );
  background-size: 50% 10px;
  position: relative;

  &--inverted {
    background: linear-gradient(
      to right,
      var(--color-theme-lighter) 8%,
      var(--color-theme-lightest) 18%,
      var(--color-theme-lighter) 33%
    );
    background-size: 50% 10px;
  }
}

// Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
</style>
