<template>
  <div class="toggle">
    <label class="toggle__toggle">
      <input @click="clicked" type="checkbox" :checked="checked" />
      <span class="toggle__slider"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Toggle',

  props: {
    checked: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    /**
     *
     */
    clicked(value) {
      this.$emit('toggle-clicked', value.currentTarget.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
$toggle-size: 18px;

.toggle {
  width: auto;
}

.toggle__toggle {
  vertical-align: middle;
  position: relative;
  display: inline-block;
  width: calc(#{$toggle-size} * 2);
  height: $toggle-size;
}

.toggle__toggle input {
  display: none;
}

.toggle__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--size-unit-4);
  background-color: var(--color-theme-light);
  transition: var(--transition-default);

  &::before {
    position: absolute;
    content: '';
    height: $toggle-size;
    width: $toggle-size;
    left: 0;
    bottom: 0;
    border-radius: 100%;
    background-color: var(--color-theme-lightest);
    transition: var(--transition-default);
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.16),
      0px 3px 8px rgba(0, 0, 0, 0.15);
  }
}

input:checked + .toggle__slider {
  background-color: var(--color-success);
}

input:focus + .toggle__slider {
  box-shadow: 0 0 1px var(--color-success);
}

input:checked + .toggle__slider:before {
  transform: translateX(#{$toggle-size});
}
</style>
