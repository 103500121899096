var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('section',{staticClass:"header__section",on:{"click":_vm.openDocumentOverview}},[_vm._m(0),(_vm.getIsUserSubscribed)?_c('div',{staticClass:"header__burger"},[_c('i',{staticClass:"icon-burger"})]):_vm._e()]),_c('section',{staticClass:"header__section"},[_c('div',{class:[
        'header__dark-mode-button',
        { 'header__dark-mode-button--active': _vm.getDarkMode }
      ],on:{"click":_vm.toggleDarkMode}},[_c('i',{staticClass:"icon-dark-mode"})]),_c('div',{class:[
        'header__mobile-menu-button',
        { 'header__mobile-menu-button--active': _vm.getMobileMenu }
      ],on:{"click":_vm.toggleMobileMenu}},[_c('i',{class:_vm.getMobileMenu ? 'icon-close' : 'icon-translate-main'})]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside:pointerdown",value:(_vm.hideUserDropdown),expression:"hideUserDropdown",arg:"pointerdown"}],staticClass:"header__user"},[_c('span',{staticClass:"header__user-avatar",on:{"click":_vm.toggleUserDropdown}},[_vm._v(" "+_vm._s(_vm.$user.displayLetters.substring(0, 2))+" ")]),(_vm.showUserDropdown)?_c('div',{staticClass:"header__user-dropdown"},[_c('div',{staticClass:"header__user-dropdown-name"},[_c('span',[_vm._v(_vm._s(_vm.$user.displayName))])]),_c('div',{staticClass:"header__user-dropdown-item",on:{"click":function($event){_vm.showUserAccount = true}}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.tabs.user.myAccount)+" ")]),_c('div',{staticClass:"header__user-dropdown-item",on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.tabs.user.logOut)+" ")])]):_vm._e()]),_c('DocumentTabs',{attrs:{"tabs":_vm.tabs}})],1),_c('UserAccount',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUserAccount),expression:"showUserAccount"}],attrs:{"tabActive":_vm.getIsUserSubscribed ? 'account' : 'subscription'},on:{"close":_vm.closeDocumentOverview}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__logo"},[_c('img',{attrs:{"src":require("../assets/images/logo-monogram.svg"),"alt":"Logo"}})])
}]

export { render, staticRenderFns }