<template>
  <transition name="document-tree">
    <div class="document-tree__mask">
      <div
        v-if="this.getOpenDocuments.filter(doc => doc.open).length"
        @click="$emit('close')"
        class="document-tree__closer"
      ></div>
      <div class="document-tree__wrapper">
        <div class="document-tree__brand-line"></div>
        <div class="document-tree__container">
          <i
            v-if="this.getOpenDocuments.filter(doc => doc.open).length"
            @click="$emit('close')"
            class="document-tree__close-button icon-close"
          ></i>

          <section class="document-tree__header">
            <div class="document-tree__search-bar">
              <i class="document-tree__search-bar-icon icon-search"></i>
              <input
                class="document-tree__search-input"
                :placeholder="
                  $languages[getAppLanguage].fileManager.searchPlaceholder
                "
                type="text"
                v-model="searchText"
              />
            </div>

            <h1 class="document-tree__title">
              {{ $languages[getAppLanguage].fileManager.heading }}
              <!-- <span class="label">beta</span> -->
            </h1>
          </section>

          <section v-if="searchText" class="document-tree__documents">
            <section>
              <h3>
                {{ $languages[getAppLanguage].fileManager.searched }}
              </h3>
              <br />
              <div
                v-if="searchedDocuments.length"
                class="document-tree__documents-cards"
              >
                <div v-for="document in searchedDocuments" :key="document.uid">
                  <div
                    @click="openDocument(document)"
                    class="document-tree__documents-card"
                  >
                    <header class="document-tree__documents-card-header">
                      <div
                        v-if="document.public"
                        class="document-tree__documents-card-header-broadcaster"
                      >
                        <Broadcaster :live="true" />
                        <small>
                          {{ $languages[getAppLanguage].fileManager.live }}
                        </small>
                      </div>
                    </header>
                    <small
                      class="document-tree__documents-card-text"
                      v-html="document.text"
                    ></small>
                  </div>
                </div>
              </div>

              <div v-else>
                <strong>{{ searchText }}</strong>
                {{ $languages[getAppLanguage].fileManager.documentNotFound }}
              </div>
            </section>
          </section>

          <section v-else class="document-tree__documents">
            <section>
              <h3>
                {{ $languages[getAppLanguage].fileManager.opened }}
              </h3>
              <br />
              <div class="document-tree__documents-cards">
                <div
                  @click="addDocument"
                  class="document-tree__documents-card document-tree__documents-card--add"
                >
                  <i class="icon-add"></i>
                </div>
                <div
                  v-for="document in getOpenDocuments.filter(doc => doc.open)"
                  :key="document.uid"
                >
                  <div
                    @click="openDocument(document)"
                    class="document-tree__documents-card document-tree__documents-card--open"
                  >
                    <header class="document-tree__documents-card-header">
                      <div
                        v-if="document.public"
                        class="document-tree__documents-card-header-broadcaster"
                      >
                        <Broadcaster :live="true" />
                        <small>
                          {{ $languages[getAppLanguage].fileManager.live }}
                        </small>
                      </div>
                    </header>
                    <small
                      class="document-tree__documents-card-text"
                      v-html="document.text"
                    ></small>
                  </div>
                </div>
              </div>
            </section>

            <hr />

            <section>
              <h3>
                {{ $languages[getAppLanguage].fileManager.closed }}
              </h3>
              <br />
              <div class="document-tree__documents-cards">
                <div
                  v-for="document in getOpenDocuments.filter(doc => !doc.open)"
                  :key="document.uid"
                >
                  <div
                    @click="openDocument(document)"
                    class="document-tree__documents-card"
                  >
                    <header class="document-tree__documents-card-header">
                      <div
                        v-if="document.public"
                        class="document-tree__documents-card-header-broadcaster"
                      >
                        <Broadcaster :live="true" />
                        <small>
                          {{ $languages[getAppLanguage].fileManager.live }}
                        </small>
                      </div>
                    </header>
                    <small
                      class="document-tree__documents-card-text"
                      v-html="document.text"
                    ></small>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Store data
import { mapActions, mapGetters } from 'vuex'

// Components
import Broadcaster from '@/components/Broadcaster'

export default {
  name: 'DocumentTree',

  components: {
    Broadcaster
  },

  data: () => ({
    searchText: null,
    searchedDocuments: []
  }),

  computed: {
    ...mapGetters(['getOpenDocuments', 'getAppLanguage'])
  },

  watch: {
    getOpenDocuments() {
      if (!this.getOpenDocuments.filter(doc => doc.open).length)
        this.setDocumentTree(true)
      if (!this.getOpenDocuments.length) this.setDocumentTree(false)
      return this.getOpenDocuments
    },

    searchText() {
      this.searchedDocuments = new Set()
      if (this.searchText) {
        for (const document of this.getOpenDocuments) {
          if (
            document.text.toLowerCase().includes(this.searchText.toLowerCase())
          )
            this.searchedDocuments.add(document)
        }
      } else this.searchedDocuments = []

      this.searchedDocuments = [...this.searchedDocuments]
    }
  },

  methods: {
    ...mapActions([
      'saveDocumentAction',
      'setDocumentTree',
      'addDocumentAction'
    ]),

    /**
     *
     */
    openDocument(document) {
      document.open = true
      this.saveDocumentAction(document)
      this.$emit('close')
      this.searchText = null

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`DO opened a document`)

      return this.$router.replace(`/document/${document.uid}`, () => {})
    },

    /**
     *
     */
    addDocument() {
      this.addDocumentAction({
        project: '',
        title: '',
        translatedTitle: '',
        source: {},
        text: '',
        translation: '',
        translationSourceLanguageManualMode: false,
        translationSourceLanguage: {
          code: '',
          prefix: '',
          language: ''
        },
        translationLanguage: this.$detectBrowserLanguage(),
        expanded: true,
        activeService: '',
        serif: false,
        open: true,
        public: false,
        publicUid: ''
      })

      this.$emit('close')
      this.searchText = null

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`DO created a new document`)
    }
  }
}
</script>

<style lang="scss" scoped>
// @Todo move to own component
.label {
  display: inline-flex;
  font-size: var(--font-size-s);
  text-transform: uppercase;
  background-color: var(--color-main);
  margin: 0 var(--size-unit);
  padding: var(--size-unit-half) var(--size-unit);
  border-radius: var(--size-unit-5);
  color: var(--color-light);
}

.document-tree__mask {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    var(--color-brand-darkest-rgb),
    var(--opacity-overlay)
  );
  transition: opacity var(--transition-default);
}

.document-tree__closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.document-tree__wrapper {
  display: flex;
  height: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform var(--transition-default);
}

.document-tree__brand-line {
  width: calc(100% - var(--size-unit-4));
  height: var(--size-unit-half);
  max-width: 1024px;
  background: linear-gradient(
    90deg,
    var(--color-main-dark) 0%,
    var(--color-main-light) 100%
  );

  @include screen-medium {
    width: calc(100% - var(--size-unit-8));
  }

  @include screen-large {
    width: calc(100% - var(--size-unit-12));
  }
}

.document-tree__container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - var(--size-unit-4));
  height: calc(100% - var(--size-unit-12));
  max-width: 1024px;
  padding: var(--size-unit-4) var(--size-unit-4) 0 var(--size-unit-4);
  background-color: var(--color-light);
  color: var(--color-dark);

  @include screen-medium {
    width: calc(100% - var(--size-unit-8));
  }

  @include screen-large {
    width: calc(100% - var(--size-unit-12));
  }
}

.document-tree__header {
  width: 100%;
}

.document-tree__title {
  display: flex;
  align-items: center;
  margin: var(--size-unit-4) 0;
}

// Search section
.document-tree__search {
  &-bar {
    position: relative;
    width: calc(100% - var(--size-unit-5));
    height: var(--size-unit-6);
    border-radius: var(--radius-default);
    padding: 0 var(--size-unit-2);
    border: 1px solid var(--color-main);
    background-color: var(--color-theme-lightest);

    &-icon {
      height: inherit;
      color: var(--color-theme-darkest);
    }
  }

  &-input {
    position: absolute;
    height: calc(100% - 2px);
    width: calc(100% - var(--size-unit-5));
    border-radius: var(--radius-default);
    padding: 0 var(--size-unit-2);
    border: none;
    outline: none;
    background-color: var(--color-theme-lightest);

    border-radius: var(--size-unit);
    color: var(--color-theme-darkest);

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: var(--color-theme-darkest);
      opacity: var(--opacity-disabled);
    }
  }
}

// Documents section
.document-tree__documents {
  width: 100%;
  overflow: scroll;

  &-cards {
    overflow: scroll;
    display: flex;
    width: 100%;

    &:last-of-type {
      margin-bottom: var(--size-unit-2);
    }

    @include screen-medium {
      flex-wrap: wrap;
    }
  }

  &-card {
    overflow: scroll;
    position: relative;
    margin: 0 var(--size-unit) var(--size-unit) 0;
    width: 96px;
    height: 120px;
    border-radius: var(--radius-default);
    background-color: var(--color-dark);
    color: var(--color-light);
    cursor: pointer;

    @include screen-medium {
      margin: 0 var(--size-unit-2) var(--size-unit-2) 0;
      width: 128px;
      height: 160px;
    }

    &-header {
      position: relative;
      height: var(--size-unit-4);

      &-broadcaster {
        display: flex;
        align-items: center;
        position: absolute;
        font-size: var(--font-size-s);
        top: var(--size-unit-half);
        left: var(--size-unit-half);
      }
    }

    &-text {
      display: block;
      pointer-events: none;
      word-break: break-word;
      transform: scale(0.7);
      transform-origin: 0 0;
      width: 142.857143%;
      padding: var(--size-unit);
    }

    &--add {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 96px;
      background-color: var(--color-accent);
      border-color: var(--color-accent);
      color: var(--color-brand-darkest);

      .icon-add {
        &::before {
          font-size: var(--font-size-m);
        }
      }

      @include screen-medium {
        min-width: 128px;
      }
    }

    &--open {
      background-color: var(--color-accent);
      border-color: var(--color-accent);
      color: var(--color-brand-darkest);
    }

    &--public {
      background-color: var(--color-accent-success);
      border-color: var(--color-accent-success);
      color: var(--color-brand-darkest);
    }
  }
}

.document-tree__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--size-unit-5);
  height: var(--size-unit-5);
  cursor: pointer;
  transition: color var(--transition-default);
  background-color: var(--color-main);
  color: var(--color-light);
  transition: opacity var(--transition-default);

  &:hover {
    opacity: var(--opacity-hover);
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.document-tree-enter {
  opacity: 0;
}

.document-tree-leave-active {
  opacity: 0;
}

.document-tree-enter .document-tree__wrapper,
.document-tree-leave-active .document-tree__wrapper {
  transform: scale(1.1);
}
</style>
