const supportedDownloadFileTypes = [
  '.doc',
  '.docx',
  '.rtf',
  '.pages',
  '.odt',
  '.pdf',
  '.txt'
]

const supportedFileTypeProviders = [
  {
    name: 'Word',
    extensions: ['.doc', '.docx', '.rtf']
  },
  {
    name: 'Google Docs',
    extensions: ['.doc']
  },
  {
    name: 'Apple Pages',
    extensions: ['.pages']
  },
  {
    name: 'Open Office',
    extensions: ['.odt']
  },
  {
    name: 'Other',
    extensions: ['.pdf', '.txt']
  }
]

export { supportedDownloadFileTypes, supportedFileTypeProviders }
