<template>
  <main v-if="$user.uid && !$user.firstDocumentCreated">
    <canvas ref="confetti"></canvas>
    <NotificationModal
      light
      hideCloseButton
      :heading="`Hi ${$user.firstName},`"
      body="It's great to have you on board! <br /> <br />
    Fluently is a brand new product, which means you might encounter something
    that doesn’t work perfectly. We appreciate your support and
    <a href='mailto:support@fluently.so'>feedback</a>. <br /> <br />
    Our help section is always available at the bottom right of any document."
      cta="Create your first document"
      @button-clicked="addFirstDocument"
      :footerImagePath="
        `images/${getDarkMode ? 'dark-' : ''}illustration-main-short.jpg`
      "
    />
  </main>

  <main v-else class="document">
    <DocumentHeader @full-screen-editor-clicked="fullScreenPrimaryEditor" />
    <DocumentTree v-show="getDocumentTree" @close="setDocumentTree(false)" />
    <div v-if="getOpenDocuments.length" class="document__editors">
      <section
        :class="[
          'document__primary',
          { 'document__primary--full-screen': isPrimaryEditorFullScreen }
        ]"
      >
        <TextEditor
          :isFullScreen="isPrimaryEditorFullScreen"
          :isExpanded="getCurrentDocument.expanded"
          @collapse-full-screen-editor-clicked="fullScreenPrimaryEditor"
        />
      </section>
      <section v-if="!getCurrentDocument.expanded" class="document__secondary">
        <Translator
          v-if="getCurrentDocument.activeService === 'translator'"
          isGrey
        />
        <Service
          v-else
          :serviceType="getCurrentDocument.activeService"
          isGrey
        />
      </section>
    </div>
  </main>
</template>

<script>
// Third party libraries
// https://agezao.github.io/confetti-js/
import ConfettiGenerator from 'confetti-js'

// Store data
import { mapGetters, mapActions } from 'vuex'

// Components
import DocumentHeader from '@/components/document/DocumentHeader'
import DocumentTree from '@/components/document/DocumentTree'
import NotificationModal from '@/components/NotificationModal'
import TextEditor from '@/components/modules/TextEditor'
import Translator from '@/components/modules/Translator'
import Service from '@/components/modules/Service'

export default {
  name: 'Document',

  data: () => ({
    detectedLanguage: 'en',
    isPrimaryEditorFullScreen: false
  }),

  components: {
    NotificationModal,
    DocumentHeader,
    DocumentTree,
    TextEditor,
    Translator,
    Service
  },

  computed: {
    ...mapGetters([
      'getDarkMode',
      'getOpenDocuments',
      'getCurrentDocument',
      'getDetectedLanguage',
      'getDocumentTree'
    ])
  },

  watch: {
    getOpenDocuments() {
      return this.getOpenDocuments
    },

    getCurrentDocument() {
      return this.getCurrentDocument
    }
  },

  mounted() {
    if (this.$user.uid) {
      if (!this.$user.firstDocumentCreated) this.renderConfetti()
      this.fetchDocumentsAction()
    }
  },

  methods: {
    ...mapActions([
      'fetchDocumentsAction',
      'fetchPublicDocumentsAction',
      'setIsLoadingDocuments',
      'addDocumentAction',
      // 'saveUserDataAction',
      'setDocumentTree'
    ]),

    /**
     *
     */
    renderConfetti() {
      const confettiSettings = {
        target: this.$refs.confetti,
        colors: [
          ['134, 73, 212'], // Main
          ['184, 139, 228'], // Main dark mode
          ['0, 170, 108'], // Success
          ['255, 145, 71'], // Warning
          ['255, 11, 55'] // Error
        ]
      }
      const confetti = new ConfettiGenerator(confettiSettings)
      confetti.render()
    },

    /**
     *
     */
    addFirstDocument() {
      // const userData = this.$user

      this.addDocumentAction({
        project: '',
        title: '',
        translatedTitle: '',
        source: {},
        text: '',
        translation: '',
        translationSourceLanguageManualMode: false,
        translationSourceLanguage: {
          code: '',
          prefix: '',
          language: ''
        },
        translationLanguage: this.$detectBrowserLanguage(),
        expanded: true,
        activeService: '',
        serif: false,
        open: true,
        public: false,
        publicUid: ''
      })

      // userData.firstDocumentCreated = true

      // if (!userData.membershipCode) delete userData.membershipCode // Removing userData.discountCode from backend
      // if (!userData.discountCode) delete userData.discountCode // Removing userData.discountCode from backend
      // delete userData.promoCodeIsValid // Removing promoCodeIsValid from backend
      // delete userData.initialPaymentIntentId // Removing initialPaymentIntentId from backend
      // delete userData.price // Avoid price to be stored in user data object
      // delete userData.priceId // Avoid priceId to be stored in user data object

      // this.saveUserDataAction(userData)

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`created first document ever`)
    },

    /**
     *
     */
    fullScreenPrimaryEditor() {
      this.isPrimaryEditorFullScreen = !this.isPrimaryEditorFullScreen
    }
  }
}
</script>

<style lang="scss" scoped>
.document {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.document__editors {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.document__primary,
.document__secondary {
  flex: 1;
  background-color: var(--color-theme-lightest);

  @include screen-large {
    width: 50%;
  }
}

.document__primary {
  &--full-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999;
  }
}

.document__secondary {
  background-color: var(--color-theme-lighter);
}

// Large screens
@include screen-large {
  .document__editors {
    flex-direction: row;
  }
}
</style>
