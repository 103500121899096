var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"micro-translator"},[_c('section',{staticClass:"micro-translator__section"},[_c('div',[_c('Dropdown',{attrs:{"label":_vm.$languages[_vm.getAppLanguage].document.header.translateFrom,"titlePrefix":_vm.translationSourceLanguage.prefix,"title":`${_vm.translationSourceLanguage.language} ${
            _vm.translationSourceLanguage.variation
              ? _vm.translationSourceLanguage.variation
              : ''
          }`,"dropdownMargin":"0 16px 0 0","dropdownWidth":"220px","options":_vm.extractedLanguagesFromTranslationLanguages(),"outlined":true},on:{"dropdown-searched-option-clicked":_vm.getSearchedSourceLanguage}},_vm._l((_vm.translationLanguages),function(language){return _c('div',{key:language.name,staticClass:"dropdown__option",attrs:{"slot":"dropdown-options"},on:{"click":function($event){return _vm.getTranslationSourceLanguageCode(language)}},slot:"dropdown-options"},[_vm._v(" "+_vm._s(language.prefix)+" "+_vm._s(language.language)+" "+_vm._s(language.variation ? `(${language.variation})` : '')+" ")])}),0)],1),_c('div',{staticClass:"micro-translator__textarea-wrapper"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],ref:"textarea",class:[
          'micro-translator__textarea',
          { 'micro-translator__textarea--rtl': _vm.isTextRTL }
        ],attrs:{"placeholder":_vm.$languages[_vm.getAppLanguage].microTranslator.placeholder,"maxlength":"500"},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}})]),_c('span',{staticClass:"micro-translator__textarea-counter"},[_vm._v(_vm._s(_vm.text.length)+" / 500")])]),_c('div',{staticClass:"micro-translator__swap-wrapper"},[_c('Button',{attrs:{"type":"tertiary","buttonWidth":"40px","buttonHeight":"40px","buttonPadding":"0","disabled":_vm.translationSourceLanguage.code === _vm.translationTargetLanguage.code},on:{"button-clicked":_vm.swapLanguage}},[_c('i',{staticClass:"icon-swap"})])],1),_c('section',{staticClass:"micro-translator__section"},[_c('div',[_c('Dropdown',{attrs:{"label":_vm.$languages[_vm.getAppLanguage].document.header.translateInto,"titlePrefix":_vm.translationTargetLanguage.prefix,"title":`${_vm.translationTargetLanguage.language} ${
            _vm.translationTargetLanguage.variation
              ? _vm.translationTargetLanguage.variation
              : ''
          }`,"dropdownMargin":"0 16px 0 0","dropdownWidth":"220px","options":_vm.extractedLanguagesFromTranslationLanguages(),"outlined":true},on:{"dropdown-searched-option-clicked":_vm.getSearchedTargetLanguage}},_vm._l((_vm.translationLanguages),function(language){return _c('div',{key:language.name,staticClass:"dropdown__option",attrs:{"slot":"dropdown-options"},on:{"click":function($event){return _vm.getTranslationTargetLanguageCode(language)}},slot:"dropdown-options"},[_vm._v(" "+_vm._s(language.prefix)+" "+_vm._s(language.language)+" "+_vm._s(language.variation ? `(${language.variation})` : '')+" ")])}),0)],1),_c('div',{staticClass:"micro-translator__translation-wrapper"},[_c('div',{class:[
          'micro-translator__translation',
          { 'micro-translator__translation--rtl': _vm.isTranslationRTL }
        ],domProps:{"innerHTML":_vm._s(_vm.translation)}})]),_c('Button',{staticClass:"micro-translator__translation-copy-button",attrs:{"type":"secondary","buttonMargin":"16px","buttonWidth":"32px","buttonHeight":"32px","confirming":_vm.copyTranslation},on:{"button-clicked":function($event){return _vm.copyTextToClipboard(_vm.translation)}}},[_c('i',{staticClass:"icon-copy"})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }