<template>
  <button
    @click="clicked"
    v-click-outside="hideOptions"
    :class="[
      'dropdown',
      {
        'dropdown--active': isDropdownActive,
        'dropdown--clicked': isDropdownClicked,
        'dropdown--outlined': outlined,
        'dropdown--no-border': noBorder,
        'dropdown--purple': purple,
        'dropdown--white': white,
        'dropdown--centered-content': centeredContent,
        'dropdown--disabled': disabled,
        'dropdown--no-events': noEvents,
        'dropdown--has-options': hasOptions()
      }
    ]"
    :style="
      `
      border-radius: ${dropdownRadius};
      margin: ${dropdownMargin};
      padding: ${dropdownPadding};
      width: ${dropdownWidth};
      height: ${dropdownHeight};
      color: ${dropdownTitleColor};
    `
    "
  >
    <div v-if="label" class="dropdown__label">
      <!-- @ TODO - Remove if not longer needed -->
      <!-- <img src="../assets/icons/info.svg" alt="Label info"> -->
      <span>{{ label }}</span>
    </div>

    <!-- Slot on the left of the title -->
    <div
      v-if="hasBeforeSlot"
      :class="[
        'dropdown__slot',
        { 'dropdown__slot--before-with-title': title }
      ]"
    >
      <slot name="before"></slot>
    </div>

    <!-- Ghost Title -->
    <div
      v-if="ghostTitle"
      class="dropdown__ghost-title"
      :style="`color: ${ghostTitle.color || ''}`"
    >
      {{ ghostTitle.text }}
    </div>

    <!-- Toggle and title -->
    <div
      v-if="title || searchText || searchedTitlePrefix"
      class="dropdown__toggle-title-prefix-wrapper"
    >
      <span class="dropdown__title-prefix-wrapper">
        <span
          v-if="searchText && searchedTitlePrefix"
          class="dropdown__title-prefix"
          >{{ searchedTitlePrefix }}</span
        >
        <span v-else-if="titlePrefix" class="dropdown__title-prefix">{{
          titlePrefix
        }}</span>
        <span v-else></span>
        <span v-if="searchText && serachedTitle" class="dropdown__title">{{
          serachedTitle
        }}</span>
        <span v-else-if="title" class="dropdown__title" v-html="title"></span>
        <span v-else></span>
      </span>

      <!-- Toggle -->
      <Toggle
        v-if="toggle"
        @toggle-clicked="toggleClicked"
        class="dropdown__toggle"
      />
    </div>

    <!-- Slot on the right of the title -->
    <div
      v-if="hasAfterSlot"
      :class="['dropdown__slot', { 'dropdown__slot--after-with-title': title }]"
    >
      <slot name="after"></slot>
    </div>

    <!-- Caret Symbol -->
    <div v-if="showCaret" class="dropdown__caret-wrapper">
      <span class="dropdown__divider"></span>

      <i
        :class="[
          'dropdown__caret icon-caret-down',
          { 'dropdown__caret--active': isDropdownActive }
        ]"
      ></i>
    </div>

    <!-- The dropdown options -->
    <div
      v-if="options && isDropdownActive && showCaret"
      :class="[
        'dropdown__options-wrapper',
        { 'dropdown__options-wrapper--right': !dropdownOptionsLeft },
        { 'dropdown__options-wrapper--left': dropdownOptionsLeft }
      ]"
      :style="`width: ${dropdownOptionsWidth};`"
    >
      <div
        :class="[
          'dropdown__options',
          { 'dropdown__options--with-search': options.length > 0 }
        ]"
        ref="search"
      >
        <div v-if="options.length > 0" class="dropdown__search-input-wrapper">
          <!-- Dropdown search -->
          <i class="dropdown__search-icon icon-search"></i>
          <input
            class="dropdown__search-input"
            :placeholder="$languages[getAppLanguage].dropdown.searchPlaceholder"
            :aria-label="$languages[getAppLanguage].dropdown.searchPlaceholder"
            v-model="searchText"
            ref="searchInput"
            autocomplete="true"
          />
        </div>

        <!-- Dropdown Search options -->
        <div v-if="searchedOptions.length > 0">
          <div
            v-for="(option, optionIndex) in searchedOptions"
            :key="optionIndex"
            @click="emitDropdownSearchedOptionClicked(option)"
            class="dropdown__option"
          >
            {{ option }}
          </div>
        </div>

        <!-- Dropdown options -->
        <div v-else @click="hideOptions">
          <slot name="dropdown-options"></slot>
        </div>
      </div>
    </div>

    <!-- Coming soon block -->
    <div
      v-if="comingSoon"
      class="dropdown__options-wrapper dropdown__options-wrapper--coming-soon"
    >
      <div class="dropdown__options">
        <span class="dropdown__option">
          <small>⚠️ Coming soon...</small>
        </span>
      </div>
    </div>

    <!-- Dropdown clickable element, set here to avoid conflicts with toggle -->
    <div
      v-if="hasOptions() && showCaret"
      @click="toggleOptions"
      class="dropdown__clicker"
    ></div>
  </button>
</template>

<script>
// Store data
import { mapGetters } from 'vuex'

import Toggle from '@/components/Toggle'

export default {
  name: 'Dropdown',

  components: {
    Toggle
  },

  props: {
    options: {
      type: Array,
      default: () => [],
      required: false
    },

    outlined: {
      type: Boolean,
      default: false,
      required: false
    },

    noBorder: {
      type: Boolean,
      default: false,
      required: false
    },

    purple: {
      type: Boolean,
      default: false,
      required: false
    },

    white: {
      type: Boolean,
      default: false,
      required: false
    },

    disabled: {
      type: Boolean,
      default: false,
      required: false
    },

    noEvents: {
      type: Boolean,
      default: false,
      required: false
    },

    label: {
      type: String,
      default: '',
      required: false
    },

    toggle: {
      type: Boolean,
      default: false,
      required: false
    },

    showCaret: {
      type: Boolean,
      default: true,
      required: false
    },

    centeredContent: {
      type: Boolean,
      default: false,
      required: false
    },

    title: {
      type: String,
      required: false
    },

    ghostTitle: {
      type: Object,
      default: () => ({
        text: '',
        color: 'inherit'
      }),
      required: false
    },

    titlePrefix: {
      type: String,
      default: '',
      required: false
    },

    dropdownMargin: {
      type: String,
      default: '8px 8px 8px 0',
      required: false
    },

    dropdownPadding: {
      type: String,
      default: '0',
      required: false
    },

    dropdownRadius: {
      type: String,
      default: '4px',
      required: false
    },

    dropdownWidth: {
      type: String,
      default: '100%',
      required: false
    },

    dropdownHeight: {
      type: String,
      default: '40px',
      required: false
    },

    dropdownTitleColor: {
      type: String,
      required: false
    },

    dropdownOptionsWidth: {
      type: String,
      required: false
    },

    dropdownOptionsLeft: {
      type: Boolean,
      required: false,
      default: false
    },

    comingSoon: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    isDropdownActive: false,
    isDropdownClicked: false,
    searchText: '',
    searchedOptions: [],
    serachedTitle: null,
    searchedTitlePrefix: null
  }),

  watch: {
    searchText() {
      this.searchedOptions = new Set()
      if (this.searchText) {
        for (const option of this.options) {
          if (option.toLowerCase().includes(this.searchText.toLowerCase())) {
            this.searchedOptions.add(option)
          }
        }
      } else this.searchedOptions = []

      this.searchedOptions = [...this.searchedOptions]
    }
  },

  computed: {
    ...mapGetters(['getAppLanguage']),

    hasBeforeSlot() {
      return !!this.$slots.before
    },

    hasAfterSlot() {
      return !!this.$slots.after
    }
  },

  mounted() {
    this.hasOptions()
  },

  methods: {
    /**
     *
     */
    clicked() {
      this.$emit('dropdown-clicked')
    },

    /**
     *
     */
    emitDropdownSearchedOptionClicked(option) {
      this.$emit('dropdown-searched-option-clicked', option)

      this.hideOptions()
    },

    /**
     *
     */
    showOptions() {
      this.isDropdownClicked = true

      setTimeout(() => {
        // Remove active class after 400 ms
        this.isDropdownClicked = false
      }, 600)

      if (this.options.length > 0) {
        this.isDropdownActive = true

        setTimeout(() => {
          // Focus search
          this.searchText = null // Reset serach query, this could be used in the future for recently serached
          this.$refs.searchInput.focus()
        })
      }
    },

    /**
     *
     */
    toggleOptions() {
      if (this.isDropdownActive) this.hideOptions()
      else this.showOptions()
    },

    /**
     *
     */
    hasOptions() {
      return this.$slots['dropdown-options']
    },

    /**
     *
     */
    hideOptions() {
      this.isDropdownActive = false
      this.isDropdownClicked = false
    },

    /**
     *
     */
    toggleClicked(value) {
      // console.log(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid var(--color-dark);
  font-size: var(--font-size-s);
  background-color: var(--color-theme-lightest);
  color: var(--color-dark);
  transition: opacity var(--transition-default),
    border-color var(--transition-default);
  cursor: pointer;

  &:hover {
    .dropdown__toggle,
    .dropdown__title-prefix-wrapper,
    .dropdown__caret-wrapper,
    .dropdown__slot {
      opacity: var(--opacity-hover);
    }

    .dropdown__options-wrapper--coming-soon {
      display: block;
    }
  }

  &:focus {
    outline: 0;
  }

  &:focus,
  &--active,
  &--outlined {
    border: 1px solid var(--color-main);

    .dropdown__divider {
      background-color: var(--color-main);
    }

    &:hover {
      opacity: var(--opacity-active);
    }
  }

  &--centered-content {
    justify-content: center;
  }

  &--purple {
    border: 1px solid var(--color-main);
    background-color: var(--color-main);
  }

  &--white {
    border: 1px solid var(--color-theme-lightest);
    background-color: var(--color-theme-lightest);

    .dropdown__divider {
      background-color: var(--color-theme-lightest);
    }

    &.dropdown--outlined {
      border: 1px solid var(--color-main);

      .dropdown__divider {
        background-color: var(--color-main);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--no-events {
    pointer-events: none;
  }

  &--has-options {
    .dropdown__toggle {
      right: 38px;
    }
  }

  &--no-border {
    &:focus,
    &.dropdown--active,
    &.dropdown--outlined {
      border: none;
    }
  }
}

.dropdown__clicker {
  position: absolute;
  right: 0;
  width: 100%;
  height: 40px;
  z-index: 9;
}

.dropdown__label {
  position: absolute;
  top: -6px;
  left: var(--size-unit-half-2);
  padding: 0 var(--size-unit);
  border-radius: var(--radius-default);
  font-size: var(--font-size-xxs);
  color: var(--color-dark);
  transition: opacity var(--transition-default);
  z-index: 99;

  span {
    z-index: -1;
  }

  &::first-letter {
    text-transform: capitalize;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    width: calc(100% - var(--size-unit-half));
    height: 6px;
    background-color: var(--color-theme-lightest);
    z-index: -1;
  }
}

.dropdown__slot {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--size-unit);
  transition: opacity var(--transition-default);

  &--before-with-title,
  &--after-with-title {
    padding: 0;
  }
}

.dropdown__toggle-title-prefix-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.dropdown__toggle {
  position: absolute;
  right: var(--size-unit);
  padding-left: var(--size-unit);
  transition: opacity var(--transition-default);
  z-index: 99;
}

.dropdown__title-prefix-wrapper {
  display: flex;
  align-items: center;
  padding: 0 var(--size-unit);
  width: 100%;
  transition: opacity var(--transition-default);
}

.dropdown__title {
  overflow-y: hidden;
  overflow-x: scroll;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  min-height: 20px;
  color: var(--color-dark);

  &::-webkit-scrollbar {
    display: none;
  }

  &::first-letter {
    text-transform: capitalize;
  }
}

.dropdown__title-prefix {
  margin-right: var(--size-unit-half);
}

.dropdown__ghost-title {
  position: absolute;
  margin-top: var(--size-unit);
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 200%; // To allow for the text to fit in small dropdowns
}

.dropdown__caret-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity var(--transition-default);
}

.dropdown__caret {
  padding: var(--size-unit-half) var(--size-unit) 0;
  transition: transform var(--transition-default);
  transform-origin: center;
  color: var(--color-main);

  &--active {
    transform: rotate(180deg);
  }
}

.dropdown__divider {
  display: inline-flex;
  height: 39px;
  width: 1px;
  background-color: var(--color-theme-lightest);
}

.dropdown__options-wrapper {
  position: absolute;
  top: calc(100% - var(--size-unit));
  width: calc(100% + 2px);
  z-index: 999;

  &--left {
    left: 0;

    &::before {
      left: var(--size-unit-2);
    }
  }

  &--right {
    right: 0;

    &::before {
      right: 0;
    }
  }

  &--coming-soon {
    display: none;
    width: 128px;

    &::before {
      content: '';
      position: absolute;
      top: var(--size-unit);
      left: var(--size-unit-2);
      transform: rotate(-45deg) translateX(-50%);
      width: var(--size-unit-2);
      height: var(--size-unit-2);
      border-radius: 1px;
      background-color: var(--color-dark);
      z-index: -1;
    }
  }
}

.dropdown__options {
  overflow: scroll;
  margin-top: var(--size-unit-2);
  padding: var(--size-unit) 0;
  width: inherit;
  max-height: 220px;
  border-radius: var(--radius-default);
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);

  @include screen-medium {
    max-height: 320px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &--with-search {
    .dropdown__option {
      &:first-of-type {
        margin-top: 40px;
      }
    }

    .dropdown__option-group {
      .dropdown__option {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}

.dropdown__search-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  position: absolute;
  width: calc(100% + 2px);
  transform: translateY(-8px);
  border-top-left-radius: var(--radius-default);
  border-top-right-radius: var(--radius-default);
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
}

.dropdown__search-icon {
  position: absolute;
  left: var(--size-unit-2);
}

.dropdown__search-input {
  width: calc(100% - var(--size-unit-2));
  height: 32px;
  padding: var(--size-unit) var(--size-unit) var(--size-unit) 32px;
  background-color: var(--color-brand-darkest);
  border: 1px solid var(--color-brand-darkest);
  border-radius: var(--size-unit);
  color: var(--color-brand-lightest);

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-brand-lightest);
    opacity: var(--opacity-disabled);
  }
}

.dropdown__option-group-wrapper {
  &:first-of-type {
    margin-top: 40px;
  }
}

.dropdown__option-group {
  border-top: 1px solid var(--color-darker);
  padding: var(--size-unit) 0;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.dropdown__option-group-title {
  margin: 0;
  padding: 0 var(--size-unit-2) var(--size-unit);
  text-align: left;
  color: var(--color-theme-light);
  text-transform: uppercase;
  pointer-events: none;
}

.dropdown__option {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 var(--size-unit-2);
  transition: background-color 0.1s ease-in-out;

  &::first-letter {
    text-transform: capitalize;
  }

  &:hover {
    background-color: var(--color-main);
    color: var(--color-light);
  }

  i {
    margin-left: var(--size-unit);
    font-size: var(--font-size-xs);
  }
}
</style>
