<template>
  <div class="document-loader">
    <div
      :class="[
        'document-loader__section',
        { 'document-loader__section--single': single }
      ]"
    >
      <TextAnimation
        :lines="1"
        lineWidth="40%"
        lineHeight="40px"
        lineAnimationDuration=".8s"
        inverted
      />
      <TextAnimation
        :lines="1"
        lineWidth="80%"
        lineHeight="32px"
        lineAnimationDuration="1.6s"
        inverted
      />

      <TextAnimation
        :lines="1"
        lineWidth="30%"
        lineHeight="32px"
        lineAnimationDuration=".6s"
        inverted
      />
      <TextAnimation
        :lines="1"
        lineWidth="90%"
        lineAnimationDuration="1.8s"
        inverted
      />
      <TextAnimation :lines="1" lineAnimationDuration="2s" inverted />
      <TextAnimation
        :lines="1"
        lineWidth="85%"
        lineAnimationDuration="1.7s"
        inverted
      />
      <TextAnimation
        :lines="1"
        lineWidth="90%"
        lineAnimationDuration="1.8s"
        inverted
      />
      <TextAnimation :lines="1" lineAnimationDuration="2s" inverted />
      <TextAnimation
        :lines="1"
        lineWidth="20%"
        lineAnimationDuration=".4s"
        inverted
      />

      <TextAnimation
        :lines="1"
        lineWidth="90%"
        lineAnimationDuration="1.8s"
        inverted
      />
      <TextAnimation :lines="1" lineAnimationDuration="2s" inverted />
      <TextAnimation
        :lines="1"
        lineWidth="85%"
        lineAnimationDuration="1.7s"
        inverted
      />
      <TextAnimation
        :lines="1"
        lineWidth="90%"
        lineAnimationDuration="1.8s"
        inverted
      />
      <TextAnimation :lines="1" lineAnimationDuration="2s" inverted />
      <TextAnimation
        :lines="1"
        lineWidth="20%"
        lineAnimationDuration=".4s"
        inverted
      />
    </div>

    <div
      :class="[
        'document-loader__section',
        { 'document-loader__section--single': single }
      ]"
    >
      <TextAnimation
        white
        :lines="1"
        lineWidth="40%"
        lineHeight="40px"
        lineAnimationDuration=".8s"
      />
      <TextAnimation
        white
        :lines="1"
        lineWidth="80%"
        lineHeight="32px"
        lineAnimationDuration="1.6s"
      />

      <TextAnimation
        white
        :lines="1"
        lineWidth="30%"
        lineHeight="32px"
        lineAnimationDuration=".6s"
      />
      <TextAnimation
        white
        :lines="1"
        lineWidth="90%"
        lineAnimationDuration="1.8s"
      />
      <TextAnimation white :lines="1" lineAnimationDuration="2s" />
      <TextAnimation
        white
        :lines="1"
        lineWidth="85%"
        lineAnimationDuration="1.7s"
      />
      <TextAnimation
        white
        :lines="1"
        lineWidth="90%"
        lineAnimationDuration="1.8s"
      />
      <TextAnimation white :lines="1" lineAnimationDuration="2s" />
      <TextAnimation
        white
        :lines="1"
        lineWidth="20%"
        lineAnimationDuration=".4s"
      />

      <TextAnimation
        white
        :lines="1"
        lineWidth="90%"
        lineAnimationDuration="1.8s"
      />
      <TextAnimation white :lines="1" lineAnimationDuration="2s" />
      <TextAnimation
        white
        :lines="1"
        lineWidth="85%"
        lineAnimationDuration="1.7s"
      />
      <TextAnimation
        white
        :lines="1"
        lineWidth="90%"
        lineAnimationDuration="1.8s"
      />
      <TextAnimation white :lines="1" lineAnimationDuration="2s" />
      <TextAnimation
        white
        :lines="1"
        lineWidth="20%"
        lineAnimationDuration=".4s"
      />
    </div>
  </div>
</template>

<script>
// Components
import TextAnimation from '@/components/TextAnimation'

export default {
  name: 'DocumentLoader',

  props: {
    single: {
      type: Boolean,
      required: false
    }
  },

  components: {
    TextAnimation
  },

  watch: {
    single() {
      return this.single
    }
  }
}
</script>

<style lang="scss" scoped>
.document-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--color-theme-darkest);
  z-index: 99999;

  @include screen-large {
    flex-direction: row;
  }
}

.document-loader__section {
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: $header-height;
  width: 100%;
  height: calc(50vh - #{$header-height / 2});
  padding: var(--size-unit-3);
  z-index: 9999;

  &:first-of-type {
    top: 0;
    background-color: var(--color-theme-lightest);
  }

  &:last-of-type {
    bottom: 0;
    background-color: var(--color-theme-lighter);
  }

  &--single {
    &:first-of-type {
      height: calc(100vh - #{$header-height});
    }

    &:last-of-type {
      display: none;
    }
  }

  @include screen-large {
    position: relative;
    height: 100%;
  }
}
</style>
