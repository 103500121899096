var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"document-layout"},[(_vm.getCurrentDocument)?_c('Header',{attrs:{"tabs":_vm.getOpenDocuments.filter(tab => tab.open)}}):_vm._e(),(_vm.getCurrentDocument)?_c('DocumentHeaderMobile'):_vm._e(),(_vm.getCurrentDocument)?_c('Document'):_vm._e(),(!_vm.getOpenDocuments.length)?_c('section',{class:[
      'document-layout__first-document-wrapper',
      {
        'document-layout__first-document-wrapper--rtl':
          _vm.getAppLanguage === 'ar'
      }
    ]},[_c('div',{staticClass:"document-layout__first-document"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.empty.hi)+" "+_vm._s(_vm.$user.firstName)+", ")]),_c('br'),(_vm.getIsUserSubscribed)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.empty.noDocuments)+" ")]),_c('Button',{attrs:{"buttonMargin":"40px 0"},on:{"button-clicked":function($event){return _vm.addNewDocument()}}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].document.empty.createFirstDocument)+" ")])],1):_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].account.noSubscription)+" ")])]),_c('hr'),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$languages[_vm.getAppLanguage].document.empty.feedback)}})])]):_vm._e(),_c('Notification',{directives:[{name:"show",rawName:"v-show",value:(_vm.getAppVersion.notification.state),expression:"getAppVersion.notification.state"}],attrs:{"message":_vm.getAppVersion.notification.message,"hideCloseButton":true,"type":"success"},on:{"action":_vm.setAppVersionLocally}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }