<template>
  <div>
    <div v-click-outside="closeTabDropdown" class="document-tabs">
      <DocumentLoader
        :single="isDocumentLoaderSingle"
        v-if="$user.uid && getIsLoadingDocuments"
      />

      <div v-else v-show="$user" ref="tabs" class="document-tabs-tabs">
        <div
          v-for="(tab, tabIndex) in tabs"
          :key="tabIndex"
          @click.stop="activateTab($event, tab)"
          :ref="`tab-${tab.uid}`"
          class="document-tabs__tab"
          :id="tab.uid"
        >
          <i
            v-if="tab.public"
            class="icon-dot document-tabs__tab-public-dot"
          ></i>
          <i class="icon-document"></i>
          <input
            @click="triggerTitleUpdate"
            @blur="documentTitleUpdated(tab.title)"
            @keydown="saveTitleOnEnter($event, tab.title)"
            :ref="`title-${tab.uid}`"
            type="text"
            v-model="tab.title"
            :placeholder="
              tab.title ||
                $languages[getAppLanguage].document.tabs.untitledDocument
            "
            class="document-tabs__tab-title"
          />
          <span
            @click="toggleTabDropdown($event, tab)"
            class="document-tabs__tab-dropdown-trigger"
          >
            <i class="icon-caret-down"></i>
          </span>
        </div>
      </div>

      <!-- Add new document -->
      <div
        v-if="$user && !getIsLoadingDocuments && getIsUserSubscribed"
        @click.stop="toggleTabDropdown($event)"
        id="click-add-document-button"
        class="document-tabs__add"
        ref="addDocumentButton"
      >
        <i class="icon-add"></i>
      </div>
    </div>

    <!-- ⚠️ The dropdown has to be put outside and be modified with JS because The computed values of ‘overflow-x’ and ‘overflow-y’ are the same as their specified values, except that some combinations with ‘visible’ are not possible: if one is specified as ‘visible’ and the other is ‘scroll’ or ‘auto’, then ‘visible’ is set to ‘auto’. The computed value of ‘overflow’ is equal to the computed value of ‘overflow-x’ if ‘overflow-y’ is the same; otherwise it is the pair of computed values of ‘overflow-x’ and ‘overflow-y’. -->

    <!-- Dropdown options -->
    <div
      v-if="isAddButton"
      v-click-outside="closeTabDropdown"
      ref="dropdown"
      :class="[
        'document-tabs__tab-dropdown-options',
        'document-tabs__tab-dropdown-options-add',
        {
          'document-tabs__tab-dropdown-options-add--overflowing': areTabsOverflowing,
          'document-tabs__tab-dropdown-options-add--first': !tabs.length
        }
      ]"
    >
      <span @click="addDocument">
        {{ $languages[getAppLanguage].document.tabs.add.new }}
      </span>
      <span @click="openDocumentOverview">
        {{ $languages[getAppLanguage].document.tabs.add.open }}
      </span>
    </div>

    <div
      v-else
      v-click-outside="closeTabDropdown"
      ref="dropdown"
      class="document-tabs__tab-dropdown-options"
    >
      <span @click="updateTitle">
        {{ $languages[getAppLanguage].document.tabs.settings.rename }}
      </span>
      <span @click="copyDocument">
        {{ $languages[getAppLanguage].document.tabs.settings.copy }}
      </span>
      <span @click="closeDocument">
        {{ $languages[getAppLanguage].document.tabs.settings.close }}
      </span>
      <span
        class="document-tabs__tab-dropdown-option document-tabs__tab-dropdown-option--delete"
        @click="deleteDocument"
      >
        {{ $languages[getAppLanguage].document.tabs.settings.delete }}
      </span>
    </div>
  </div>
</template>

<script>
// Components
import DocumentLoader from '@/components/document/DocumentLoader'

// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DocumentTabs',

  props: {
    tabs: {
      type: Array,
      required: true
    }
  },

  components: {
    DocumentLoader
  },

  data: () => ({
    openTabs: [],
    isDropdownOpen: false,
    isAddButton: false,
    isDocumentLoaderSingle: true,
    areTabsOverflowing: false,
    currentTextFromWatcher: null
  }),

  computed: {
    ...mapGetters([
      'getIsLoadingDocuments',
      'getCurrentDocument',
      'getAppLanguage',
      'getIsUserSubscribed'
    ])
  },

  watch: {
    getIsLoadingDocuments() {
      this.activateCurrentTabWhenReady()
      return this.getIsLoadingDocuments
    },

    getCurrentDocument() {
      this.isDocumentLoaderSingle = this.getCurrentDocument.expanded
      return this.getCurrentDocument
    },

    // Used for document tree actions
    $route(to, from) {
      this.activateCurrentTabWhenReady()
    }
  },

  mounted() {
    this.activateCurrentTabWhenReady()
  },

  methods: {
    ...mapActions([
      'setCurrentDocument',
      'saveDocumentAction',
      'setIsLoadingDocuments',
      'setNotification',
      'setDocumentTree',
      'addDocumentAction',
      'translateTitleAction',
      'deleteDocumentAction',
      'deletePublicDocumentAction'
    ]),

    /**
     *
     */
    activateCurrentTabWhenReady() {
      const checkForTabs = () => {
        const tabs = this.$refs.tabs

        if (tabs) {
          if (tabs.children) {
            this.activateCurrentTab()
            return stopCheckingForTabs()
          }
        }
      }

      const checkForTabsInterval = setInterval(checkForTabs)

      const stopCheckingForTabs = () => {
        return clearInterval(checkForTabsInterval)
      }
    },

    /**
     *
     */
    saveTitleOnEnter(event, title) {
      if (event.key === 'Enter') return this.documentTitleUpdated(title)
    },

    /**
     *
     */
    openTabDropdown(dropdown, dropdownTrigger) {
      // Positioning dropdown
      dropdownTrigger = dropdownTrigger.parentElement
      const dropdownTriggerOffsetLeft = dropdownTrigger.offsetLeft // Hardcoded value to center dropdown
      const dropdownTriggerWidth = dropdownTrigger.clientWidth // Hardcoded value to center dropdown
      let tabsScrollLeftPosition = this.$refs.tabs.scrollLeft

      dropdown.style.display = 'flex'
      if (!this.isAddButton) {
        dropdown.style.left = `${dropdownTriggerOffsetLeft +
          dropdownTriggerWidth -
          tabsScrollLeftPosition -
          42}px` // Hardcoded value to center dropdown

        // Update the dropdown position on scroll of tabs
        this.$refs.tabs.addEventListener('scroll', () => {
          tabsScrollLeftPosition = this.$refs.tabs.scrollLeft
          dropdown.style.left = `${dropdownTriggerOffsetLeft +
            dropdownTriggerWidth -
            tabsScrollLeftPosition -
            42}px` // Hardcoded value to center dropdown
        })
      } else {
        const triggerPosition = this.$refs.addDocumentButton.getBoundingClientRect()

        // 74 px is the half of the dropdown options width, and 22 is the half of the + button - 2px to compensate for image
        dropdown.style.left = `${triggerPosition.left - 74 + 22}px` // Hardcoded value to center dropdown

        if (this.areTabsOverflowing) {
          dropdown.style.left = `${triggerPosition.left - 148 + 46}px` // Hardcoded value to center dropdown
        }
      }

      this.isDropdownOpen = true
    },

    /**
     *
     */
    closeTabDropdown() {
      const dropdown = this.$refs.dropdown
      dropdown.style.display = 'none'

      this.isDropdownOpen = false
    },

    /**
     *
     */
    toggleTabDropdown(event) {
      const dropdown = this.$refs.dropdown
      const dropdownTrigger = event.currentTarget

      if (dropdownTrigger.className === 'document-tabs__add')
        this.isAddButton = true
      else this.isAddButton = false

      if (this.isDropdownOpen) this.closeTabDropdown()
      else this.openTabDropdown(dropdown, dropdownTrigger)
    },

    /**
     *
     */
    documentTitleUpdated(title) {
      const document = this.getCurrentDocument
      document.title = title

      if (!document.expanded) {
        this.translateTitleAction({
          title: document.title,
          sourceLanguageCode:
            document.translationSourceLanguage.code ||
            this.$detectBrowserLanguage().code,
          targetLanguageCode:
            document.translationLanguage.code ||
            this.$detectBrowserLanguage().code
        })
      }

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`updated a document title`)

      return this.setCurrentDocument(document)
    },

    /**
     *
     */
    activateTab(event, tab) {
      const tabs = this.$refs.tabs

      const clickedTab = event.currentTarget

      this.areTabsOverflowing = this.checkOverflowX(tabs)

      for (const tab of tabs.children) {
        tab.classList.remove('document-tabs__tab--active')
      }

      clickedTab.classList.add('document-tabs__tab--active')

      if (this.$route.params.id !== clickedTab.id) {
        return this.$router.replace(`/document/${clickedTab.id}`, () => {
          return clickedTab.click()
        })
      }

      this.setCurrentDocument(tab) // Setting the entire current document

      return this.$eventBus.$emit('document-tab-clicked')
    },

    /**
     *
     */
    activateCurrentTab() {
      const tabs = this.$refs.tabs

      if (tabs.children.length < 1) {
        return false
      } else {
        const lastTab = Object.keys(tabs.children).length - 1
        const lastTabLeftPosition = tabs.children[lastTab].offsetLeft

        this.areTabsOverflowing = this.checkOverflowX(tabs)

        if (
          tabs.children.length === 1 ||
          this.$route.path === '/document/loading...'
        ) {
          // This is what activates the last tab when landing on the '/' route or when the tab before the last is deleted.
          tabs.children[lastTab].click()
          tabs.scrollLeft = lastTabLeftPosition
        } else {
          // Activate current tab
          Object.keys(tabs.children).map(tab => {
            if (tabs.children[tab].id === this.$route.params.id) {
              const tabLeftPosition = tabs.children[tab].offsetLeft
              tabs.scrollLeft = tabLeftPosition
              tabs.children[tab].click()
            }
          })
        }
      }
    },

    /**
     *
     */
    addButtonClicked() {
      this.isAddButton = true
    },

    /**
     *
     */
    addDocument() {
      this.addDocumentAction({
        project: '',
        title: '',
        translatedTitle: '',
        source: {},
        text: '',
        translation: '',
        translationSourceLanguageManualMode: false,
        translationSourceLanguage: {
          code: '',
          prefix: '',
          language: ''
        },
        translationLanguage: this.$detectBrowserLanguage(),
        expanded: true,
        activeService: '',
        serif: false,
        open: true,
        public: false,
        publicUid: ''
      })

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`created a new document`)
    },

    /**
     *
     */
    copyDocument() {
      const document = this.getCurrentDocument
      document.title = document.title
        ? `${this.$languages[this.getAppLanguage].document.tabs.copyOf} ${
            document.title
          }`
        : document.title
      document.translatedTitle = document.translatedTitle
        ? `${this.$languages[this.getAppLanguage].document.tabs.copyOf} ${
            document.translatedTitle
          }`
        : document.translatedTitle
      document.public = false
      document.publicUid = null

      this.addDocumentAction(document)

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`copied a document`)
    },

    /**
     *
     */
    closeDocument() {
      const document = this.getCurrentDocument
      document.open = false

      this.setIsLoadingDocuments(true)
      this.saveDocumentAction(document)

      const activeTabs = this.tabs.filter(
        tab => tab.uid !== this.$route.params.id
      )

      let lastActiveTab

      activeTabs.map(tab => {
        lastActiveTab = tab.uid
      })

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`closed a document`)

      return this.$router.replace(`/document/${lastActiveTab}`, () => {
        setTimeout(() => {
          this.activateCurrentTabWhenReady()
          return this.setNotification({
            state: true,
            autoHide: true,
            type: 'success',
            message: 'Your document has been closed successfully!'
          })
        })
      })
    },

    /**
     *
     */
    openDocumentOverview() {
      this.setDocumentTree(true)

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`opened document overview`)
    },

    /**
     *
     */
    deleteDocument() {
      if (this.getCurrentDocument.public) {
        this.deletePublicDocumentAction(this.getCurrentDocument) // Removeing public document if exist
        if (this.$amplitudeOn)
          this.$amplitude.getInstance().logEvent(`deleted a public document`)
      }

      this.deleteDocumentAction(this.getCurrentDocument) // Removeing document

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`deleted a document`)
    },

    /**
     *
     */
    updateTitle() {
      this.closeTabDropdown()

      setTimeout(() => {
        const currentTitleInput = this.$refs[
          `title-${this.$route.params.id}`
        ][0]

        currentTitleInput.style.textDecoration = 'none'
        currentTitleInput.focus()
      })

      if (this.$amplitudeOn)
        this.$amplitude.getInstance().logEvent(`clicked on update title`)
    },

    /**
     *
     */
    triggerTitleUpdate() {
      return setTimeout(() => this.updateTitle())
    },

    /**
     *
     */
    checkOverflowX(element) {
      const currentOverflow = element.style.overflow
      const isOverflowing = element.clientWidth < element.scrollWidth

      if (!currentOverflow || currentOverflow === 'visible')
        element.style.overflow = 'hidden'

      element.style.overflow = currentOverflow

      return isOverflowing
    }
  }
}
</script>

<style lang="scss" scoped>
$header-space-needed-on-the-right-mobile: calc(var(--size-unit-5) * 5);
$header-space-needed-on-the-right: calc(var(--size-unit-5) * 4);
$header-space-needed-on-the-left: var(--size-unit-10);
$add-button-width: var(--size-unit-5);

.document-tabs {
  display: flex;
  align-items: center;
  position: fixed; // To guarantee header fixability
  top: 0;
  left: $header-space-needed-on-the-left;
  width: calc(100% - #{$header-space-needed-on-the-right-mobile});
  height: $header-height;
  font-size: var(--font-size-s);
  color: var(--color-theme-lightest);
  z-index: 9;

  &::-webkit-scrollbar {
    display: none;
  }

  @include screen-medium {
    width: calc(100% - #{$header-space-needed-on-the-right});
  }
}

.document-tabs-tabs {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
}

.document-tabs__tab {
  display: inline-flex;
  align-items: center;
  position: relative;
  min-width: 140px;
  height: $header-height;
  border-right: 1px solid
    rgba(var(--color-brand-lightest-rgb), var(--opacity-disabled));
  padding: 0 var(--size-unit);
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
  cursor: pointer;

  @include screen-small {
    min-width: 180px;
  }

  @include screen-medium {
    min-width: 220px;
    padding: 0 0 0 var(--size-unit-2);
  }

  @include screen-large {
    min-width: 248px;
  }

  &:last-of-type {
    border-right: 0;
  }

  &--active {
    border-color: var(--color-theme-lightest);
    background-color: var(--color-theme-lightest);
    color: var(--color-dark);

    .document-tabs__tab-title {
      color: var(--color-dark);
    }

    .document-tabs__tab-dropdown-trigger {
      &::before {
        background: linear-gradient(
          270deg,
          var(--color-theme-lightest) 35%,
          rgba(var(--color-fade-soft), 0) 100%
        );
      }

      img {
        display: inline;
      }
    }
  }
}

.document-tabs__tab-public-dot {
  margin-right: var(--size-unit);
  color: var(--color-success);

  @include screen-medium {
    margin-right: var(--size-unit-2);
  }
}

.document-tabs__tab-title {
  overflow: hidden;
  display: inline-flex;
  width: 100%;
  height: inherit;
  align-items: center;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  background: transparent;
  color: var(--color-brand-lightest);
  font-size: inherit;
  caret-color: var(--color-main);
  padding: 0;
  margin: 0 var(--size-unit);

  // Ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
    cursor: initial;
  }
}

.document-tabs__tab-dropdown-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 0;
  height: 100%;
  background-color: inherit;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   right: 100%;
  //   width: var(--size-unit);
  //   height: inherit;
  //   background: linear-gradient(
  //     270deg,
  //     var(--color-brand-darkest) 0%,
  //     rgba(18, 18, 18, 0) 100%
  //   );
  //   pointer-events: none;
  // }

  @include screen-medium {
    min-width: var(--size-unit-5);
  }
}

.document-tabs__tab-dropdown-options {
  display: none;
  flex-direction: column;
  position: fixed;
  top: calc(#{$header-height} + var(--size-unit));
  left: 0;
  width: 160px;
  padding: var(--size-unit) 0;
  border-radius: var(--size-unit-half);
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
  z-index: 9999;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--size-unit) var(--size-unit-2);
    font-size: var(--font-size-s);
    cursor: pointer;

    &:hover {
      background-color: var(--color-main);
      color: var(--color-light);

      &.document-tabs__tab-dropdown-option--delete {
        background-color: var(--color-warning);
        color: var(--color-brand-darkest);
      }
    }
  }
}

.document-tabs__tab-dropdown-options-add {
  &--overflowing {
    &::before {
      left: initial;
      right: var(--size-unit);
    }
  }

  &--first {
    left: 0 !important;
    margin-left: var(--size-unit-2);
  }
}

.document-tabs__add {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid
    rgba(var(--color-brand-lightest-rgb), var(--opacity-disabled));
  min-width: $add-button-width;
  width: $add-button-width;
  height: $header-height;
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
  cursor: pointer;
  transition: color var(--transition-default);

  &:hover {
    color: var(--color-main);
  }
}
</style>
