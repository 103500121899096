<template>
  <div class="micro-translator">
    <section class="micro-translator__section">
      <div>
        <Dropdown
          @dropdown-searched-option-clicked="getSearchedSourceLanguage"
          :label="$languages[getAppLanguage].document.header.translateFrom"
          :titlePrefix="translationSourceLanguage.prefix"
          :title="
            `${translationSourceLanguage.language} ${
              translationSourceLanguage.variation
                ? translationSourceLanguage.variation
                : ''
            }`
          "
          dropdownMargin="0 16px 0 0"
          dropdownWidth="220px"
          :options="extractedLanguagesFromTranslationLanguages()"
          :outlined="true"
        >
          <div
            slot="dropdown-options"
            class="dropdown__option"
            v-for="language in translationLanguages"
            :key="language.name"
            @click="getTranslationSourceLanguageCode(language)"
          >
            {{ language.prefix }} {{ language.language }}
            {{ language.variation ? `(${language.variation})` : '' }}
          </div>
        </Dropdown>
      </div>
      <div class="micro-translator__textarea-wrapper">
        <textarea
          v-model="text"
          ref="textarea"
          :class="[
            'micro-translator__textarea',
            { 'micro-translator__textarea--rtl': isTextRTL }
          ]"
          :placeholder="$languages[getAppLanguage].microTranslator.placeholder"
          maxlength="500"
        >
        </textarea>
      </div>
      <span class="micro-translator__textarea-counter"
        >{{ text.length }} / 500</span
      >
    </section>

    <!-- Middle swap button -->
    <div class="micro-translator__swap-wrapper">
      <Button
        type="tertiary"
        @button-clicked="swapLanguage"
        buttonWidth="40px"
        buttonHeight="40px"
        buttonPadding="0"
        :disabled="
          translationSourceLanguage.code === translationTargetLanguage.code
        "
      >
        <i class="icon-swap"></i>
      </Button>
    </div>

    <!-- Translations section -->
    <section class="micro-translator__section">
      <div>
        <Dropdown
          @dropdown-searched-option-clicked="getSearchedTargetLanguage"
          :label="$languages[getAppLanguage].document.header.translateInto"
          :titlePrefix="translationTargetLanguage.prefix"
          :title="
            `${translationTargetLanguage.language} ${
              translationTargetLanguage.variation
                ? translationTargetLanguage.variation
                : ''
            }`
          "
          dropdownMargin="0 16px 0 0"
          dropdownWidth="220px"
          :options="extractedLanguagesFromTranslationLanguages()"
          :outlined="true"
        >
          <div
            slot="dropdown-options"
            class="dropdown__option"
            v-for="language in translationLanguages"
            :key="language.name"
            @click="getTranslationTargetLanguageCode(language)"
          >
            {{ language.prefix }} {{ language.language }}
            {{ language.variation ? `(${language.variation})` : '' }}
          </div>
        </Dropdown>
      </div>

      <div class="micro-translator__translation-wrapper">
        <div
          :class="[
            'micro-translator__translation',
            { 'micro-translator__translation--rtl': isTranslationRTL }
          ]"
          v-html="translation"
        ></div>
      </div>
      <Button
        @button-clicked="copyTextToClipboard(translation)"
        class="micro-translator__translation-copy-button"
        type="secondary"
        buttonMargin="16px"
        buttonWidth="32px"
        buttonHeight="32px"
        :confirming="copyTranslation"
      >
        <i class="icon-copy"></i>
      </Button>
    </section>
  </div>
</template>

<script>
// Store data
import { mapGetters, mapActions } from 'vuex'

// Data files
import { translationLanguages } from '@/data/languages'

// Helpers
import isLanguageDirectionRTL from '@/helpers/isLanguageDirectionRTL'

// Components
import Dropdown from '@/components/Dropdown'
import Button from '@/components/Button'

export default {
  name: 'MicroTranslator',

  components: {
    Dropdown,
    Button
  },

  data: () => ({
    text: '',
    translation: '',

    translationLanguages,

    isTextRTL: false,
    isTranslationRTL: false,

    translationSourceLanguage: {
      language: '',
      code: '',
      prefix: ''
    },

    translationTargetLanguage: {
      language: '',
      code: '',
      prefix: ''
    },

    copyTranslation: false
  }),

  computed: {
    ...mapGetters(['getCurrentDocument', 'getAppLanguage'])
  },

  watch: {
    getCurrentDocument() {
      this.syncLanguages()
      return this.getCurrentDocument
    },

    text() {
      this.text = this.text.replace(/(<([^>]+)>)/gi, '').replace(/\n/g, '') // Remove spaces from text in  textarea
      this.translate()
      this.detectRTL()

      return this.text
    }
  },

  mounted() {
    this.$refs.textarea.focus()
    this.syncLanguages()

    /**
     *
     */
    this.$eventBus.$on('micro-translator-translation-updated', translation => {
      this.translation = translation
    })
  },

  methods: {
    ...mapActions(['translateTextAction']),

    /**
     *
     */
    syncLanguages() {
      this.translationSourceLanguage = this.getCurrentDocument
        .translationSourceLanguage.code
        ? this.getCurrentDocument.translationLanguage
        : {
            language: 'English',
            variation: '',
            code: 'en',
            prefix: '(EN)'
          }

      this.translationTargetLanguage = this.getCurrentDocument
        .translationSourceLanguage.code
        ? this.getCurrentDocument.translationSourceLanguage
        : {
            language: 'English',
            variation: '',
            code: 'en',
            prefix: '(EN)'
          }
      this.detectRTL()
    },

    /**
     *
     */
    detectRTL() {
      this.isTextRTL = isLanguageDirectionRTL(
        this.translationSourceLanguage.code
      )

      this.isTranslationRTL = isLanguageDirectionRTL(
        this.translationTargetLanguage.code
      )
    },

    /**
     *
     */
    copyTextToClipboard(text) {
      const textarea = document.createElement('textarea')
      const timeToHideMessage = 600

      this.copyTranslation = !this.copyTranslation

      setTimeout(() => {
        this.copyTranslation = !this.copyTranslation
      }, timeToHideMessage)

      textarea.value = text
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    },

    /**
     *
     */
    swapLanguage() {
      const currentSourceLanguage = this.translationSourceLanguage
      const currentTargetLanguage = this.translationTargetLanguage
      const currentText = this.text
      const currentTranslation = this.translation

      this.translationSourceLanguage = currentTargetLanguage
      this.translationTargetLanguage = currentSourceLanguage
      this.text = currentTranslation
      this.translation = currentText

      this.detectRTL()
    },

    /**
     *
     */
    translate() {
      this.translateTextAction({
        text: this.text,
        sourceLanguageCode: this.translationSourceLanguage.code,
        targetLanguageCode: this.translationTargetLanguage.code,
        event: 'micro-translator-translation-updated'
      })

      this.detectRTL()
    },

    /**
     * This is used to parse the languages before passing them through the dropdown, to make the dropdown more dumb
     */
    extractedLanguagesFromTranslationLanguages() {
      const languages = []

      for (const language of translationLanguages) {
        languages.push(
          `${language.prefix} ${language.language} ${
            language.variation ? language.variation : ''
          }`
        )
      }

      return languages
    },

    /**
     *
     */
    getTranslationSourceLanguageCode(language) {
      this.translationSourceLanguage = {
        // Used for performing the translation
        prefix: language.prefix,
        language: language.language,
        variation: language.variation,
        code: language.code
      }

      this.detectRTL()
    },

    /**
     *
     */
    getTranslationTargetLanguageCode(language) {
      this.translationTargetLanguage = {
        // Used for performing the translation
        prefix: language.prefix,
        language: language.language,
        variation: language.variation,
        code: language.code
      }

      this.detectRTL()

      return this.translate()
    },

    /**
     *
     */
    getSearchedSourceLanguage(languageString) {
      translationLanguages.map(language => {
        if (
          languageString ===
          `${language.prefix} ${language.language} ${language.variation}`
        )
          this.translationSourceLanguage = language
      })

      this.detectRTL()
    },

    /**
     *
     */
    getSearchedTargetLanguage(languageString) {
      translationLanguages.map(language => {
        if (
          languageString ===
          `${language.prefix} ${language.language} ${language.variation}`
        ) {
          this.translationTargetLanguage = language
          return this.translate()
        }
      })

      this.detectRTL()
    }
  }
}
</script>

<style lang="scss" scoped>
.micro-translator {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  // min-height: calc(var(--vh, 1vh) * 50 - 156px);
  min-height: calc(45vh - 100px);
  margin: 0 auto;
  width: calc(100% - var(--size-unit-4));
  max-width: 580px;
  padding: var(--size-unit-4) var(--size-unit);

  @include screen-medium {
    min-height: calc(45vh - 40px);
    flex-direction: row;
    max-width: 100%;
  }

  @include screen-large {
    flex-direction: column;
    height: initial;
    width: calc(
      100% - 188px
    ); // To compensate for the side bar with buttons to toggle services
    max-width: 720px;
    padding: 0 var(--size-unit) var(--size-unit-4);
  }
}

.micro-translator__section {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 240px;
  padding: var(--size-unit-2);
  border-radius: var(--size-unit-half);
  background-color: var(--color-theme-lightest);

  @include screen-small {
    height: 280px;
  }

  @include screen-medium {
    margin: 0 var(--size-unit);
    height: 320px;
  }

  @include screen-large {
    margin: 0 var(--size-unit);
    min-height: 320px;
    height: calc(50vh - 128px);
  }
}

textarea {
  height: 100%;
  resize: none;

  &:focus {
    outline: 0;
  }
}

.micro-translator__textarea-wrapper,
.micro-translator__translation-wrapper {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: var(--size-unit-2);
  height: inherit;
}

.micro-translator__swap-wrapper {
  display: flex;
  justify-content: center;
  margin: var(--size-unit) 0;

  @include screen-medium {
    margin: var(--size-unit-2) 0;
  }

  i {
    transform: rotate(90deg);

    @include screen-medium {
      transform: none;
    }

    @include screen-large {
      transform: rotate(90deg);
    }
  }
}

.micro-translator__textarea-counter {
  position: absolute;
  line-height: 1;
  right: var(--size-unit-2);
  bottom: var(--size-unit-2);
  font-size: var(--font-size-xs);
  color: var(--color-dark);
}

.micro-translator__textarea,
.micro-translator__translation {
  overflow: scroll;
  border: none;
  border-radius: var(--size-unit-half);
  padding: var(--size-unit) 0 var(--size-unit-2);
  height: 100%;
  background-color: inherit;
  color: var(--color-dark);
  word-break: break-word;

  &--rtl {
    @include rtl;
  }
}

.micro-translator__translation-copy-button {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
